<template>

    <base-modal name="user-create"
                title="Mitarbeiter erstellen"
                :width="800"
                v-on="$listeners"
                v-bind="$attrs">
            <user-form :user="user" type="create"></user-form>
    </base-modal>

</template>

<script>
import UserForm from './UserForm.vue'

export default {
  name: 'UserCreate',
  components: { UserForm },
  data: () => ({
    user: {
      firstName: null,
      lastName: null,
      position: null,
      email: null,
      phone: null,
      mobile: null,
      plainPassword: null,
      teams: [],
      roles: [],
      isActive: true
    }
  })
}
</script>
