<template>

  <base-modal toptitle="Schwerpunkt bearbeiten"
              :title="influencerSubCategory.name"
              name="influencer-sub-category-edit"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <influencer-sub-category-form :influencerSubCategory="influencerSubCategory"
                                  type="edit"/>
  </base-modal>

</template>

<script>
import InfluencerSubCategoryForm from './InfluencerSubCategoryForm.vue'

export default {
  name: 'InfluencerSubCategoryEdit',
  components: { InfluencerSubCategoryForm },
  props: {
    influencerSubCategory: Object
  }
}
</script>
