<template>

  <base-modal name="project-influencer-create"
              title="Influencer hinzufügen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <project-influencer-form :projectInfluencer="projectInfluencer" type="create"/>
  </base-modal>

</template>

<script>
import { mapGetters } from 'vuex'
import ProjectInfluencerForm from './ProjectInfluencerForm.vue'

export default {
  name: 'ProjectInfluencerCreate',
  components: { ProjectInfluencerForm },
  props: {
    projectInfluencer: {
      type: Object,
      default: () => ({
        feature: null
      })
    }
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    ...mapGetters({
      projectById: 'projects/byId',
      projectInfluencerById: 'projectInfluencers/byId'
    })
  }
}
</script>
