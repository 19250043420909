import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'customerContacts',
  urlRoot: '/customer_contacts',
  getters: {
    byCustomer: (state) => (id) => {
      return Object.values(state.entities).filter(customerContact => customerContact.customer.id === parseInt(id))
    }
  }
})
