<template>

  <base-modal name="project-accounting-invoice-edit"
              toptitle="Rechnung bearbeiten"
              :title="costCenterLabel"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <project-accounting-invoice-form :projectAccountingInvoice="projectAccountingInvoice" type="edit"/>
  </base-modal>

</template>

<script>
import ProjectAccountingInvoiceForm from './ProjectAccountingInvoiceForm.vue'

export default {
  name: 'ProjectAccountingInvoiceEdit',
  components: { ProjectAccountingInvoiceForm },
  props: {
    projectAccountingInvoice: Object
  },
  computed: {
    costCenterLabel () {
      switch (this.projectAccountingInvoice.costCenter) {
        case 1:
          return 'PR'
        case 2:
          return 'Social Media'
        case 3:
          return 'Marketing'
        default:
          return ''
      }
    }
  }
}
</script>
