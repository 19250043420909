<template>

    <base-modal name="mediacontact-create"
                title="Medienkontakt erstellen"
                :width="1200"
                v-on="$listeners"
                v-bind="$attrs">
            <mediacontact-form :mediacontact="mediacontact" type="create"/>
    </base-modal>

</template>

<script>
import MediacontactForm from './MediacontactForm.vue'

export default {
  name: 'MediacontactCreate',
  components: { MediacontactForm },
  data: () => ({
    mediacontact: {
      firstName: null,
      lastName: null,
      inquiryCase: false,
      contests: false,
      interviews: false,
      pressPreview: false,
      isActive: true
    }
  })
}
</script>
