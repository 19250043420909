<template>

  <base-modal name="mediacontact-region-edit"
              toptitle="Region bearbeiten"
              :title="mediacontactRegion.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <mediacontact-region-form :mediacontactRegion="mediacontactRegion" type="edit"></mediacontact-region-form>
  </base-modal>

</template>

<script>
import MediacontactRegionForm from './MediacontactRegionForm.vue'

export default {
  name: 'MediacontactRegionEdit',
  components: { MediacontactRegionForm },
  props: {
    mediacontactRegion: Object
  }
}
</script>
