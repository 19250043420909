<template>
    <div class="group">
        <div class="top">
            <div class="subtitle">
                <font-awesome-icon icon="gift" />
                Geburtstage
                <span>{{ birthdays.length }}</span>
            </div>
        </div>

        <div class="wrapper">
            <dashboard-calendar-item v-for="item in birthdays"
                                     :key="item.event.id"
                                     :item="item"/>
            <skeleton-row :show="loading"></skeleton-row>
            <not-found-row entity="Geburtstage" :show="!loading && !birthdays.length"></not-found-row>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DashboardCalendarItem from './DashboardCalendarItem.vue'
import SkeletonRow from '@/components/SkeletonRow.vue'
import NotFoundRow from '@/components/NotFoundRow.vue'
import { orderBy } from 'lodash'

export default {
  name: 'DashboardCalendarBirthdayList',
  components: { DashboardCalendarItem, NotFoundRow, SkeletonRow },
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData: async function () {
      this.calendar.birthdays || await this.loadCalendar()
      this.loading = false
    },
    ...mapActions({
      loadCalendar: 'app/loadCalendar'
    })
  },
  computed: {
    birthdays () {
      const birthdays = []
      if (!this.loading) {
        this.calendar.birthdays.value.map(event => birthdays.push({ category: 'birthday', event }))
      }
      return orderBy(birthdays, 'event.start.dateTime', 'asc')
    },
    ...mapGetters({
      calendar: 'app/calendar',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole'
    })
  }

}
</script>
