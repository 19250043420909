<template>

    <div class="notfound" v-if="show">Keine {{ entity }} gefunden.</div>

</template>

<script>
export default {
  name: 'NotFoundRow',
  props: {
    show: null,
    entity: null
  }
}
</script>

<style lang="scss">

    .notfound {
        padding: 20px;
        font-size: 1rem;
    }

    #app.dark {

        .notfound {
            color: $gray-500;
        }
    }

</style>
