<template>

  <base-modal name="business-contact-company-create"
              title="Unternehmen erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <business-contact-company-form :businessContactCompany="businessContactCompany"
                                   type="create"/>
  </base-modal>

</template>

<script>
import BusinessContactCompanyForm from './BusinessContactCompanyForm.vue'

export default {
  name: 'BusinessContactCompanyCreate',
  components: { BusinessContactCompanyForm },
  data: () => ({
    businessContactCompany: {}
  })
}
</script>
