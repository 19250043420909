<template>

  <base-modal name="influencer-sub-category-create"
              :width="400"
              title="Schwerpunkt erstellen"
              v-on="$listeners"
              v-bind="$attrs">
    <influencer-sub-category-form :influencerSubCategory="influencerSubCategory"
                                  type="create"/>
  </base-modal>

</template>

<script>
import InfluencerSubCategoryForm from './InfluencerSubCategoryForm.vue'

export default {
  name: 'InfluencerSubCategoryCreate',
  components: { InfluencerSubCategoryForm },
  data: () => ({
    influencerSubCategory: {
      name: null
    }
  })
}
</script>
