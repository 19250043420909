<template>

  <base-modal name="customer-edit"
              toptitle="Kunde bearbeiten"
              :title="customer.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <customer-form :customer="customer" type="edit"></customer-form>
  </base-modal>

</template>

<script>
import CustomerForm from './CustomerForm.vue'

export default {
  name: 'CustomerEdit',
  components: { CustomerForm },
  props: {
    customer: {
      type: Object,
      default: () => ({
        name: null
      })
    }
  }
}
</script>
