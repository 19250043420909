<template>

    <section class="table">

        <div class="header">
            <slot name="header"/>
            <div class="background"></div>
        </div>

        <div class="wrapper">
            <div class="main">
                <slot/>
            </div>
        </div>
    </section>

</template>

<script>
export default {
  name: 'BaseTable'
}
</script>

<style lang="scss">

section.table {
    margin: 20px 0;
}

/* Table Header */
section.table .header {
    position: sticky;
    top: 183px;
    z-index: 50;
    margin: 0 auto;
    padding: 0 20px;
}

section.table .header::after {
    content: "";
    position: absolute;
    background-color: $primary-color;
    height: 73px;
    width: 100%;
    top: -43px;
    left: 0;
    right: 0;
    z-index: -1;
}

section.table .header .row {
    height: 50px;
    flex-flow: row nowrap;
    font-weight: 700;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: 1640px;
    margin: 0 auto;
    background-color: $white;
    //border: 1px solid $gray-200;
}

section.table .header .row:hover {
    background-color: $white;
}

section.table .header .row .icon svg {
    font-size: 1.25rem;
    color: $text-color-2;
}

section.table .header .row .content .middle .col {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    cursor: pointer;
    //padding: 5px 10px 5px 0;
    line-height: 1;
}

section.table .header .row .content .middle .col svg {
    margin-right: 5px;
    font-size: 0.875rem;
    //color: $text-color;
}

section.table .header .row .content .middle .col.sort {
    color: $text-color;
}

section.table .header .row .content .middle .col.sort svg {
    color: $text-color;
}

section.table .header .row .content .middle .col .arrows {
    margin-left: 5px;
}

section.table .header .row .content .middle .col .arrows svg {
    font-size: 0.875rem;
}

/* Wrapper */
section.table .wrapper {
    color: $text-color-2;
    font-size: 0.875rem;
    max-width: 1680px;
    margin: 0 auto;
    padding: 0 20px;
}

section.table .wrapper .main {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    //box-shadow: 0 0 3px $text-color-4;
    //border: 1px solid $gray-200;
    overflow: hidden;
    background-color: $white;
    box-shadow: 0 0 5px $text-color-4;
}

/* Row Footer */
section.table .footer .row {
    flex-flow: row nowrap;
    border-top: 1px solid $gray-100;
    font-weight: 700;
    white-space: nowrap;
    background-color: $white;
    font-size: 1rem;
}

section.table .footer .row:hover {
    background-color: $white;
}

section.table .footer .row .content .middle .col {
    color: $text-color;
}

@media (max-width: 1024px) {

    section.table .header {
        display: none;
    }

    section.table .wrapper .main {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    section.table .header .background {
        display: none;
    }

    /*section.list .header .row,
    section.list .footer .row {
        display: none;
    }*/
}

#app.dark {

    section.table .wrapper .main {
        background-color: $black-2;
        border-color: $black-4;
    }

    section.table .header::after {
        background-color: $black-2;
    }

    section.table .header .row,
    section.table .footer .row {
        background-color: $black-3;
    }

    section.table .header .row:hover,
    section.table .footer .row:hover {
        background-color: $black-3;
    }

    section.table .header .row .content .middle .col.sort {
        color: $gray-300;
    }

    section.table .header .row .content .middle .col svg {
        color: $white;
    }

}

</style>
