<template>

  <base-modal name="project-list-create"
              :width="400"
              :toptitle="project.title"
              title="Verteiler erstellen"
              v-on="$listeners"
              v-bind="$attrs">
    <project-list-form :projectList="projectList"
                       type="create"/>
  </base-modal>

</template>

<script>
import ProjectListForm from './ProjectListForm.vue'

export default {
  name: 'ProjectListCreate',
  components: { ProjectListForm },
  props: {
    project: {
      type: Object,
      default: () => ({
        id: null,
        title: null
      })
    }
  },
  data: () => ({
    projectList: {
      name: null,
      project: null
    }
  }),
  created () {
    this.projectList.project = '/projects/' + this.project.id
  }
}
</script>
