<template>

    <div class="item">
        <div class="content">
            <div class="icon" v-if="icon">
                <font-awesome-icon :icon="icon" size="sm" />
            </div>
            <div class="middle">
                <div class="subtitle">{{ toptitle }}</div>
                <div class="title" :style="{ color: titleColor }">
                    {{ title }}
                    <slot name="links"></slot>
                </div>
                <div class="subtitle">{{ subtitle }}</div>
            </div>
        </div>
        <div class="action">
            <slot name="action"/>
        </div>
    </div>

</template>

<script>
export default {
  name: 'BaseInfoBarItem',
  props: {
    icon: String,
    toptitle: String,
    title: String,
    subtitle: String,
    titleColor: String
  }
}
</script>

<style lang="scss">

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-right: 1px solid $gray-200;
        margin: 10px 0;
        padding: 0 20px;
        flex-grow: 1;
    }

    .item:last-child {
        border-right: none;
    }

    .item .content {
        display: flex;
        align-items: center;
    }

    .item .content .icon {
        font-size: 1.5rem;
        padding-right: 10px;
    }

    .item .content .icon svg {
        color: $text-color;
    }

    .item .content .middle .title {
        margin: 5px 0;

        font-weight: 700;
        color: $text-color;
        font-size: 1rem;
        //line-height: 1.5;
    }

    .item .content .middle .subtitle {
        color: $text-color-2;
        font-size: 0.875rem;
    }

    .item .action {
        padding-left: 10px;
    }

    @media (max-width: 768px) {
        .item {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid $gray-200;
            margin: 0 20px;
            padding: 10px 0;
        }

        .item:last-child {
            border-bottom: none;
        }

    }

    #app.dark {
        .item {
            border-right-color: $gray-800;
            border-bottom-color: $gray-800;
        }

        .item .content .icon svg {
            color: $white;
        }

        .item .content .middle .title {
            color: $white;
        }

        .item .content .middle .subtitle {
            color: $gray-500;
        }

    }

</style>
