<template>

  <base-modal title="Einzeldruck"
              subtitle="Änderungen werden nicht gespeichert"
              name="mediacontact-print"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <form v-on:submit.prevent="">
        <BaseInputFloatinglabel v-if="form.company" v-model="form.company.name"
                                icon="building"
                                placeholder="Unternehmen"
                                type="text"/>
        <BaseInputFloatinglabel v-if="form.medium"
                                v-model="form.medium.name"
                                icon="layer-group"
                                placeholder="Medium"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.firstName"
                                placeholder="Vorname"
                                icon="user"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.lastName"
                                placeholder="Nachname"
                                icon="user"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.street"
                                placeholder="Straße"
                                icon="home"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.zip"
                                placeholder="PLZ"
                                icon="map-marker-alt"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.city"
                                placeholder="Stadt"
                                icon="city"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.country"
                                placeholder="Land"
                                icon="flag"
                                type="text"/>
        <div class="action">
          <BaseButton :loading="printing"
                      :disabled="checkForm || printing"
                      icon="print"
                      label="Drucken"
                      :submit="true"
                      @click="printMediacontact"/>
        </div>
      </form>
  </base-modal>

</template>

<script>
import print from 'print-js'
import axios from 'axios'
import { cloneDeep } from 'lodash'

export default {
  name: 'MediacontactPrint',
  props: {
    mediacontact: Object
  },
  data () {
    return {
      form: {},
      printing: false
    }
  },
  created () {
    this.form = cloneDeep(this.mediacontact)
  },
  methods: {
    async printMediacontact () {
      try {
        this.printing = true
        const response = await axios.post(process.env.VUE_APP_API_URL + '/print/mediacontact', { mediacontact: this.form }, { responseType: 'blob' })
        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileUrl = window.URL.createObjectURL(file)
        print(fileUrl)
        this.printing = false
      } catch (error) {
        this.printing = false
      }
    }
  },
  computed: {
    checkForm () {
      return !(this.form.firstName && this.form.lastName)
    }
  }
}
</script>
