<template>

  <base-modal name="project-mediacontact-partner-feature-create"
              :toptitle="project ? project.title : 'Projekt'"
              :width="800"
              title="Beitrag hinzufügen"
              v-on="$listeners"
              v-bind="$attrs">
    <project-mediacontact-partner-feature-form
      :projectMediacontactPartner="projectMediacontactPartner"
      :projectMediacontactPartnerFeature="projectMediacontactPartnerFeature"
      type="create"/>
  </base-modal>

</template>

<script>
import ProjectMediacontactPartnerFeatureForm from './ProjectMediacontactPartnerFeatureForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectMediacontactPartnerFeatureCreate',
  components: { ProjectMediacontactPartnerFeatureForm },
  props: {
    projectMediacontactPartner: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    projectMediacontactPartnerFeature: {
      id: null,
      highlight: false
    }
  }),
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    ...mapGetters({
      projectById: 'projects/byId'
    })
  }
}
</script>
