<template>

  <form v-on:submit.prevent="">

    <BaseInputFloatinglabel v-model="form.name"
                            icon="envelope-open-text"
                            placeholder="Name"
                            type="text"/>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectListForm',
  props: ['projectList', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.projectList)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        const response = await this.createProjectList({ data: this.form })
        this.$vfm.hide('project-list-create')
        this.$toasted.success('Verteiler ' + response.data.name + ' erstellt', { icon: 'fas fa-envelope-open-text' })
        this.creating = false
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateProjectList({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-list-edit')
        this.$toasted.success('Verteiler ' + response.data.name + ' gespeichert', { icon: 'fas fa-envelope-open-text' })
        this.updating = false
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectList({ id: this.form.id })
        this.$vfm.hide('project-list-edit')
        this.$toasted.success('Verteiler gelöscht', { icon: 'fas fa-envelope-open-text' })
        this.removing = false
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      createProjectList: 'projectLists/create',
      updateProjectList: 'projectLists/replace',
      deleteProjectList: 'projectLists/destroy'
    })
  },
  computed: {
    checkForm () {
      return !this.form.name
    },
    isManager () {
      return (this.form.project && this.form.project.managers && this.form.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
