import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectAccountingDeals',
  urlRoot: '/project_accounting_deals',
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectAccountingDeal => projectAccountingDeal.project === '/projects/' + id)
    },
    feeByProject: (state, getters) => id => {
      return getters.byProject(id)
        .filter(projectAccountingDeal => projectAccountingDeal.fee)
        .map(projectAccountingDeal => parseFloat(projectAccountingDeal.fee))
        .reduce((cur, acc) => cur + acc, 0)
    }
  }
})
