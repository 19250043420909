import store from '@/store/index'
import Backend from '@/components/Backend.vue'
import Login from '@/components/Login.vue'
import NotFound from '@/components/NotFound.vue'
import Dashboard from '@/components/dashboard/Dashboard.vue'

import UserList from '@/components/users/UserList.vue'
import Settings from '@/components/settings/Settings.vue'

const Project = () => import(/* webpackChunkName: "projects" */ '@/components/project/Project.vue')
const ProjectList = () => import(/* webpackChunkName: "projects" */ '@/components/project/ProjectList.vue')
const ProjectOverview = () => import(/* webpackChunkName: "projects" */ '@/components/project/projectOverview/ProjectOverview.vue')
const ProjectPublicity = () => import(/* webpackChunkName: "projects" */ '@/components/project/projectPublicity/ProjectPublicity.vue')
const ProjectListMediacontactList = () => import(/* webpackChunkName: "projects" */ '@/components/project/projectPublicity/projectLists/projectListMediacontact/ProjectListMediacontactList.vue')
const ProjectAds = () => import(/* webpackChunkName: "projects" */ '@/components/project/projectAds/ProjectAds.vue')
const ProjectSocialMedia = () => import(/* webpackChunkName: "projects" */ '@/components/project/projectSocialMedia/ProjectSocialMedia.vue')
const ProjectAccounting = () => import(/* webpackChunkName: "projects" */ '@/components/project/projectAccounting/ProjectAccounting.vue')
const CustomerList = () => import(/* webpackChunkName: "customers" */ '@/components/customers/CustomerList.vue')
const CustomerContactList = () => import(/* webpackChunkName: "customers" */ '@/components/customers/customerContact/CustomerContactList.vue')
const MediacontactList = () => import(/* webpackChunkName: "mediacontacts" */ '@/components/mediacontact/MediacontactList.vue')
const MediacontactProductTypeList = () => import(/* webpackChunkName: "mediacontacts" */ '@/components/mediacontact/mediacontactProductType/MediacontactProductTypeList.vue')
const MediacontactPartnerCategoryList = () => import(/* webpackChunkName: "mediacontacts" */ '@/components/mediacontact/mediacontactPartnerCategory/MediacontactPartnerCategoryList.vue')
const MediacontactPartnerList = () => import(/* webpackChunkName: "mediacontacts" */ '@/components/mediacontact/mediacontactPartner/MediacontactPartnerList.vue')
const MediacontactCompanyList = () => import(/* webpackChunkName: "mediacontacts" */ '@/components/mediacontact/mediacontactCompany/MediacontactCompanyList.vue')
const MediacontactRegionList = () => import(/* webpackChunkName: "mediacontacts" */ '@/components/mediacontact/mediacontactRegion/MediacontactRegionList.vue')
const InfluencerList = () => import(/* webpackChunkName: "influencers" */ '@/components/influencer/InfluencerList.vue')
const InfluencerCategoryList = () => import(/* webpackChunkName: "influencers" */ '@/components/influencer/influencerCategory/InfluencerCategoryList.vue')
const InfluencerSubCategoryList = () => import(/* webpackChunkName: "influencers" */ '@/components/influencer/influencerSubCategory/InfluencerSubCategoryList.vue')
const InfluencerAudienceList = () => import(/* webpackChunkName: "influencers" */ '@/components/influencer/influencerAudience/InfluencerAudienceList.vue')
const InfluencerNetworkList = () => import(/* webpackChunkName: "influencers" */ '@/components/influencer/influencerNetwork/InfluencerNetworkList.vue')
const BusinessContactList = () => import(/* webpackChunkName: "businesscontacts" */ '@/components/businessContact/BusinessContactList.vue')
const BusinessContactCompanyList = () => import(/* webpackChunkName: "businesscontacts" */ '@/components/businessContact/businessContactCompany/BusinessContactCompanyList.vue')
const BusinessContactCategoryList = () => import(/* webpackChunkName: "businesscontacts" */ '@/components/businessContact/businessContactCategory/BusinessContactCategoryList.vue')
const BusinessContactTopicList = () => import(/* webpackChunkName: "businesscontacts" */ '@/components/businessContact/businessContactTopic/BusinessContactTopicList.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isLoggedIn']) {
        next({ name: 'dashboard' })
      } else {
        next({ name: 'login' })
      }
    }
  },
  {
    path: '/login',
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isLoggedIn']) {
        next({ name: 'dashboard' })
      } else {
        next()
      }
    },
    component: Login,
    meta: { title: 'Login' },
    name: 'login'
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      store.dispatch('auth/logout')
      next({ name: 'home' })
    },
    meta: { title: 'Logout' }
  },
  {
    path: '/',
    component: Backend,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'dashboard',
        component: Dashboard,
        meta: { title: 'Dashboard' },
        name: 'dashboard'
      },
      {
        path: 'projekte',
        component: ProjectList,
        meta: { title: 'Projekte' },
        name: 'projectList'
      },
      {
        path: 'projekte/:projectId',
        component: Project,
        name: 'project',
        redirect: { name: 'projectOverview' },
        children: [
          {
            path: encodeURI('übersicht'),
            component: ProjectOverview,
            name: 'projectOverview',
            meta: { title: 'Übersicht' }
          },
          {
            path: 'publicity',
            component: ProjectPublicity,
            name: 'projectPublicity',
            meta: { title: 'Publicity' }
          },
          {
            path: 'publicity/verteiler/:projectListId',
            component: ProjectListMediacontactList,
            meta: { title: 'Verteiler' },
            name: 'projectListMediacontactList'
          },
          {
            path: 'marketing',
            component: ProjectAds,
            name: 'projectAds',
            meta: { title: 'Marketing' }
          },
          {
            path: 'socialmedia',
            component: ProjectSocialMedia,
            name: 'projectSocialMedia',
            meta: { title: 'Social Media' }
          },
          {
            path: 'abrechnung',
            component: ProjectAccounting,
            name: 'projectAccounting',
            meta: { title: 'Abrechnung' }
          }
        ]
      },
      {
        path: 'kunden',
        component: CustomerList,
        meta: { title: 'Kunden' },
        name: 'customerList'
      },
      {
        path: 'kunden/:customerId/ansprechpartner',
        component: CustomerContactList,
        meta: { title: 'Ansprechpartner' },
        name: 'customerContactList'
      },
      {
        path: 'medienkontakte',
        component: MediacontactList,
        meta: { title: 'Medienkontakte' },
        name: 'mediacontactList'
      },
      {
        path: 'medienkontakte/partner',
        component: MediacontactPartnerList,
        meta: { title: 'Medienpartner' },
        name: 'mediacontactPartnerList'
      },
      {
        path: 'medienkontakte/unternehmen',
        component: MediacontactCompanyList,
        meta: { title: 'Medienunternehmen' },
        name: 'mediacontactCompanyList'
      },
      {
        path: 'medienkontakte/regionen',
        component: MediacontactRegionList,
        meta: { title: 'Medienregionen' },
        name: 'mediacontactRegionList'
      },
      {
        path: 'medienkontakte/produktarten',
        component: MediacontactProductTypeList,
        meta: { title: 'Produktarten' },
        name: 'mediacontactProductTypeList'
      },
      {
        path: 'medienkontakte/kategorien',
        component: MediacontactPartnerCategoryList,
        meta: { title: 'Kategorien' },
        name: 'mediacontactPartnerCategoryList'
      },
      {
        path: 'influencer',
        component: InfluencerList,
        meta: { title: 'Influencer' },
        name: 'influencerList'
      },
      {
        path: 'influencer/kategorien',
        component: InfluencerCategoryList,
        meta: { title: 'Influencer Kategorien' },
        name: 'influencerCategoryList'
      },
      {
        path: 'influencer/schwerpunkte',
        component: InfluencerSubCategoryList,
        meta: { title: 'Influencer Schwerpunkte' },
        name: 'influencerSubCategoryList'
      },
      {
        path: 'influencer/zielgruppen',
        component: InfluencerAudienceList,
        meta: { title: 'Influencer Zielgruppen' },
        name: 'influencerAudienceList'
      },
      {
        path: 'influencer/netzwerke',
        component: InfluencerNetworkList,
        meta: { title: 'Influencer Netzwerke' },
        name: 'influencerNetworkList'
      },
      {
        path: 'b2b-kontakte',
        component: BusinessContactList,
        meta: { title: 'B2B Kontakte' },
        name: 'businessContactList'
      },
      {
        path: 'b2b-kontakte/unternehmen',
        component: BusinessContactCompanyList,
        meta: { title: 'B2B Kontakt Unternehmen' },
        name: 'businessContactCompanyList'
      },
      {
        path: 'b2b-kontakte/kategorien',
        component: BusinessContactCategoryList,
        meta: { title: 'B2B Kontakt Kategorien' },
        name: 'businessContactCategoryList'
      },
      {
        path: 'b2b-kontakte/schwerpunkte',
        component: BusinessContactTopicList,
        meta: { title: 'B2B Kontakt Schwerpunkte' },
        name: 'businessContactTopicList'
      },
      {
        path: 'mitarbeiter',
        component: UserList,
        meta: { title: 'Mitarbeiter' },
        name: 'userList'
      },
      {
        path: 'einstellungen',
        component: Settings,
        meta: { title: 'Einstellungen' },
        name: 'settings'
      },
      {
        path: '*',
        component: NotFound,
        meta: { title: 'Nicht gefunden' },
        name: 'notFound'
      }
    ]
  }

]

export default routes
