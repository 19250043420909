<template>

  <base-modal name="business-contact-create"
              title="B2B-Kontakt erstellen"
              :width="800"
              v-on="$listeners"
              v-bind="$attrs">
    <business-contact-form :businessContact="businessContact" type="create"></business-contact-form>
  </base-modal>

</template>

<script>
import BusinessContactForm from './BusinessContactForm.vue'

export default {
  name: 'BusinessContactCreate',
  components: { BusinessContactForm },
  data: () => ({
    businessContact: {
      category: null,
      company: null,
      isActive: true
    }
  })
}
</script>
