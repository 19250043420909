<template>

    <main>
        <div class="not-found">
            <font-awesome-icon icon="bug" size="4x" />
            <h1>Seite nicht gefunden</h1>
          </div>
    </main>

</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style lang="scss">

    .not-found {
        font-size: 1.25rem;
        text-align: center;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        height: 80vh;
    }

    .not-found svg {
        color: $text-color-4;
        margin-bottom: 20px;
    }

    #app.dark {
        .not-found svg {
            color: $white;
        }
    }

</style>
