<template>
  <div class="card">
    <div class="card-top">
      <div class="left-icon">
        <div v-if="hasCoverSlot">
          <slot name="cover"/>
        </div>
        <font-awesome-icon v-else :icon="leftIcon"/>
      </div>
      <div class="text">
        <div class="toptitle">{{ toptitle }}</div>
        <router-link v-if="link" :to="link" class="title">{{ title }}</router-link>
        <div v-else class="title">{{ title }}</div>
        <div class="subtitle">{{ subtitle }}</div>
      </div>
      <div class="right-icon" v-if="icon">
        <font-awesome-icon :icon="icon" :style="iconColor"/>
      </div>
      <div v-if="hasTopActionSlot" class="right-action">
        <slot name="top-action"></slot>
      </div>
    </div>
    <div v-if="hasContentSlot" class="card-content">
      <slot name="content"/>
    </div>
    <div v-if="hasBottomSlot" class="card-bottom">
      <slot name="bottom"/>
    </div>
    <div v-if="hasItemsSlot" class="card-items">
      <slot name="items"/>
    </div>
    <div v-if="hasActionSlot" class="card-action">
      <slot name="action"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCardItem',
  props: {
    leftIcon: String,
    toptitle: String,
    title: String,
    subtitle: String,
    icon: String,
    iconColor: Object,
    link: Object
  },
  computed: {
    hasCoverSlot () {
      return !!this.$slots.cover
    },
    hasContentSlot () {
      return !!this.$slots.content
    },
    hasBottomSlot () {
      return !!this.$slots.bottom
    },
    hasItemsSlot () {
      return !!this.$slots.items
    },
    hasActionSlot () {
      return !!this.$slots.action
    },
    hasTopActionSlot () {
      return !!this.$slots['top-action']
    }
  }
}
</script>

<style lang="scss">
.card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 0 10px $text-color-4;
  padding: 10px 15px;
  transition: 0.3s all ease;
}

.card .card-top {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.card .card-top .left-icon {
  margin-right: 10px;
}

.card .card-top .left-icon svg {
  font-size: 1.25rem;
}

.card .card-top .left-icon img {
  width: 20px;
}

.card .card-top .toptitle {
  font-size: 0.875rem;
  color: $text-color-2;
}

.card .card-top .title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3;
}

.card .card-top .subtitle {
  font-size: 0.875rem;
  color: $text-color-2;
}

.card .card-top .right-icon {
  margin-left: auto;
  padding-left: 10px;
}

.card .card-top .right-action {
  margin-left: auto;
}

.card .card-content {
  line-height: 1.3;
  margin-bottom: 10px;
  font-size: 0.875rem;
  padding-top: 10px;
}

.card .card-bottom {
  font-size: 0.875rem;
}

.card .card-bottom svg {
  margin-right: 10px;
}

/* Card Items */
.card .card-items {
  margin: 10px 0;
}

.card .card-items .card-item {
  //margin-bottom: 10px;
}

.card .card-items .card-item:last-child {
  margin-bottom: 0;
}

.card .card-items .card-item .card-item-top {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  //margin-bottom: 5px;
}

.card .card-items .card-item .card-item-toptitle {
  color: $text-color-2;
  font-size: 0.875rem;
}

.card .card-items .card-item .card-item-title {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.3;
}

.card .card-items .card-item .card-item-subtitle {
  color: $text-color-2;
  font-size: 0.875rem;
}

.card .card-items .card-item .card-item-top svg.category {
  margin-right: 10px;
}

.card .card-items .card-item .card-item-top .status {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.card .card-items .card-item .card-item-top .status span {
  font-size: 0.875rem;
  color: $text-color-2;
  margin-right: 5px;
}

.card .card-items .card-item .card-item-top .status .button svg {
  margin-right: 0;
}

.card .card-items .card-item .card-item-content {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3;
  margin-left: 10px;
  padding-left: 16px;
  color: $text-color-2;
  border-left: 1px solid $text-color-4;
}

.card .card-items .card-item svg {
  margin-right: 5px;
  color: $text-color-2;
}

.card .card-action {
  display: flex;
  justify-content: flex-end;
}

#app.dark {

  .card {
    background-color: $black-2;
    border-color: $black-4;
  }

  .card .card-top .toptitle,
  .card .card-top .subtitle {
    color: $gray-500;
  }

  .card .card-top {
    border-color: $gray-800;
  }

  .card .card-items .card-item .card-item-toptitle {
    color: $gray-500;
  }

  .card .card-items .card-item .card-item-content {
    color: $gray-500;
    border-color: $gray-800;
  }

  .card .card-items .card-item svg {
    color: $white;
  }

  .card .card-items .card-item span {
    color: $gray-500;
  }

  .card .card-content {
    border-color: $gray-800;
  }

  .card .card-bottom {
    border-color: $gray-800;
  }

}

</style>
