<template>

  <form v-on:submit.prevent="">

    <div class="middle">
      <div class="group">
        <div class="title">Name</div>
        <BaseInputFloatinglabel v-model="form.firstName"
                                icon="user-tie"
                                placeholder="Vorname"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.lastName"
                                icon="user-tie"
                                placeholder="Nachname"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.position"
                                icon="sitemap"
                                placeholder="Position"
                                type="text"/>

        <div class="title">Kontakt</div>

        <BaseInputFloatinglabel v-model="form.email"
                                icon="envelope"
                                placeholder="E-Mail"
                                type="email"/>

        <BaseInputFloatinglabel v-model="form.phone"
                                icon="phone-alt"
                                placeholder="Telefon"
                                type="tel"/>

        <BaseInputFloatinglabel v-model="form.fax"
                                icon="fax"
                                placeholder="Fax"
                                type="tel"/>

        <BaseInputFloatinglabel v-model="form.mobile"
                                icon="mobile-alt"
                                placeholder="Mobil"
                                type="tel"/>

      </div>
      <div class="group">
        <div class="title">Unternehmen & Kategorien</div>

        <multiselect v-model="form.company"
                     :options="businessContactCompanies"
                     :show-labels="false"
                     label="name"
                     placeholder="Unternehmen"
                     track-by="id">
          <template #placeholder>
            <font-awesome-icon icon="building"/>
            <span>Unternehmen</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="building"/>
            <span>{{ props.option.name }}</span>
          </template>
        </multiselect>

        <multiselect v-model="form.category"
                     :options="businessContactCategories"
                     :show-labels="false"
                     label="name"
                     placeholder="Kategorie"
                     track-by="id">
          <template #placeholder>
            <font-awesome-icon icon="tags"/>
            <span>Kategorie</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="tags"/>
            <span>{{ props.option.name }}</span>
          </template>
        </multiselect>

        <multiselect v-model="form.topics"
                     :multiple="true"
                     :options="businessContactTopics"
                     :show-labels="false"
                     label="name"
                     placeholder="Schwerpunkte"
                     track-by="id">
          <template #placeholder>
            <font-awesome-icon icon="th"/>
            <span>Schwerpunkte</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="th"/>
            <span>{{ props.option.name }}</span>
          </template>
        </multiselect>

        <BaseTextarea v-model="form.notes"
                      icon="sticky-note"
                      placeholder="Notizen"/>

        <BaseCheckbox v-if="type === 'edit'"
                      :id="form.id"
                      field="isActive"
                      v-model="form.isActive"
                      placeholder="Aktiv"/>

      </div>

    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="hasManagerRole && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'BusinessContactForm',
  props: ['businessContact', 'type'],
  data () {
    return {
      form: {},
      loading: true,
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.fetchData()
    this.form = cloneDeep(this.businessContact)
  },
  methods: {
    async fetchData () {
      await Promise.all([
        this.businessContactCategories.length || this.getBusinessContactCategories(),
        this.businessContactTopics.length || this.getBusinessContactTopics(),
        this.businessContactCompanies.length || this.getBusinessContactCompanies()
      ])
      this.loading = false
    },
    async create () {
      try {
        this.creating = true
        const response = await this.createBusinessContact({ data: this.form })
        this.$vfm.hide('business-contact-create')
        this.$toasted.success('B2B Kontakt ' + response.data.firstName + ' ' + response.data.lastName + ' erstellt', { icon: 'fas fa-user-tie' })
        this.creating = false
        await this.getBusinessContacts()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateBusinessContact({ id: this.form.id, data: this.form })
        this.$vfm.hide('business-contact-edit')
        this.$toasted.success('B2B Kontakt ' + response.data.firstName + ' ' + response.data.lastName + ' gespeichert', { icon: 'fas fa-user-tie' })
        this.updating = true
        await this.getBusinessContact({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteBusinessContact({ id: this.form.id })
        this.$vfm.hide('business-contact-edit')
        this.$toasted.success('B2B Kontakt gelöscht', { icon: 'fas fa-user-tie' })
        this.removing = false
        await this.getBusinessContacts()
      } catch (error) {
        this.removing = false
      }
    },
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.form.email)
    },
    ...mapActions({
      createBusinessContact: 'businessContacts/create',
      updateBusinessContact: 'businessContacts/replace',
      deleteBusinessContact: 'businessContacts/destroy',
      getBusinessContact: 'businessContacts/fetchSingle',
      getBusinessContacts: 'businessContacts/fetchList',
      getBusinessContactCategories: 'businessContactCategories/fetchList',
      getBusinessContactTopics: 'businessContactTopics/fetchList',
      getBusinessContactCompanies: 'businessContactCompanies/fetchList'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.firstName && this.form.lastName && this.validEmail())
    },
    ...mapGetters({
      businessContactCategories: 'businessContactCategories/list',
      businessContactTopics: 'businessContactTopics/list',
      businessContactCompanies: 'businessContactCompanies/list',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
