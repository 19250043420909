<template>

  <base-modal name="project-material-create"
              :toptitle="project.title"
              title="Material hinzufügen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <project-material-form :projectMaterial="projectMaterial" type="create"/>
  </base-modal>

</template>

<script>
import ProjectMaterialForm from './ProjectMaterialForm.vue'

export default {
  name: 'ProjectMaterialCreate',
  components: { ProjectMaterialForm },
  props: {
    project: {
      type: Object,
      default: () => ({
        title: null
      })
    }
  },
  data: () => ({
    projectMaterial: {
      project: null,
      material: null,
      count: null
    }
  }),
  created () {
    this.projectMaterial.project = '/projects/' + this.$route.params.projectId
  }
}
</script>
