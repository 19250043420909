import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {
  theme: 'bubble',
  duration: 3000,
  position: 'bottom-center',
  singleton: true,
  iconPack: 'custom-class'
})
