<template>

    <section class="filter">
        <div class="wrapper">
            <div class="filter">
                <slot name="left"></slot>
            </div>
            <div class="filter" v-if="hasRightSlot">
                <slot name="right"></slot>
            </div>
        </div>
    </section>

</template>

<script>
export default {
  name: 'BaseFilterBar',
  computed: {
    hasRightSlot () {
      return !!this.$slots.right
    }
  }
}
</script>

<style lang="scss">

    /* Section Filter */
    section.filter {
        position: sticky;
        top: 110px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        z-index: 60;
    }

    section.filter::after {
        content: "";
        position: absolute;
        background-color: $primary-color;
        height: 30px;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }

    section.filter .wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        width: 1680px;
        margin: 0 auto;
        padding: 0 20px;
    }

    /* Filter */
    section.filter .filter {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        border-radius: 10px;
        //box-shadow: 0 0 3px $text-color-4;
        box-shadow: 0 0 5px $text-color-4;
        background-color: $white;
        padding: 5px 10px;
        position: relative;
        //border: 1px solid $gray-200;
    }

    section.filter .filter .icon svg {
        margin: 0 5px 0 10px;
        color: $text-color-3;
    }

    section.filter .filter .search {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    section.filter .right {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    section.filter .multiselect {
        cursor: pointer;
    }

    section.filter .sort {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 10px;
        border-radius: 30px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        background-color: $white;
        height: 50px;
        margin-bottom: 10px;
    }

    section.filter .sort svg {
        color: $text-color-3;
        margin: 0 5px;
    }

    @media (max-width: 768px) {

        section.filter {
            position: relative;
            top: 0;
        }

        section.filter .filter {
            width: 100%;
        }

        section.filter .filter .search {
            width: 100%;
        }

        section.filter .action {
            margin-left: 10px;
        }

        section.filter .sort {
            flex-flow: row wrap;
            height: auto;
            overflow: hidden;
        }

    }

    #app.dark {

        section.filter::after {
            background-color: $black-2;
        }

        section.filter .filter {
            background-color: $black-3;
            border-color: $black-4;
        }

        section.filter .filter .icon svg {
            color: $gray-500;
        }

        section.filter .sort {
            background-color: $black-2;
        }

        section.filter .sort svg {
            color: $gray-500
        }

        section.filter .multiselect__tags {
            background-color: $black-3;
            border: none;
        }

    }

</style>
