import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import store from '@/store/index'

Sentry.init({
  Vue,
  dsn: 'https://022c284d8d184c41a1c761fd57a64f2d@sentry.flyingovation.com/7',
  integrations: [new Integrations.BrowserTracing()],
  attachProps: true,
  logErrors: true,
  trackComponents: true,
  environment: process.env.VUE_APP_MODE
})

Sentry.setUser({
  id: store.getters['auth/authUser'].id,
  username: store.getters['auth/authUser'].name,
  email: store.getters['auth/authUser'].email
})
