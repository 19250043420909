<template>

  <base-modal name="business-contact-topic-create"
              :width="400"
              title="Schwerpunkt erstellen"
              v-on="$listeners"
              v-bind="$attrs">
    <business-contact-topic-form :businessContactTopic="businessContactTopic"
                                 type="create"/>
  </base-modal>

</template>

<script>
import BusinessContactTopicForm from './BusinessContactTopicForm.vue'

export default {
  name: 'BusinessContactTopicCreate',
  components: { BusinessContactTopicForm },
  data: () => ({
    businessContactTopic: {
      name: null
    }
  })

}
</script>
