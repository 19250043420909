import Vue from 'vue'
import ProjectCreate from '@/components/project/ProjectCreate'
import ProjectEdit from '@/components/project/ProjectEdit'
import ProjectAccountingDealCreate
  from '@/components/project/projectAccounting/projectAccountingDeal/ProjectAccountingDealCreate'
import ProjectAccountingDealEdit
  from '@/components/project/projectAccounting/projectAccountingDeal/ProjectAccountingDealEdit'
import ProjectAccountingInvoiceCreate
  from '@/components/project/projectAccounting/projectAccountingInvoice/ProjectAccountingInvoiceCreate'
import ProjectAccountingInvoiceEdit
  from '@/components/project/projectAccounting/projectAccountingInvoice/ProjectAccountingInvoiceEdit'
import ProjectAccountingItemCreate
  from '@/components/project/projectAccounting/projectAccountingItems/ProjectAccountingItemCreate'
import ProjectAccountingItemEdit
  from '@/components/project/projectAccounting/projectAccountingItems/ProjectAccountingItemEdit'
import ProjectAdBudgetCreate from '@/components/project/projectAds/projectAdBudget/ProjectAdBudgetCreate'
import ProjectAdBudgetEdit from '@/components/project/projectAds/projectAdBudget/ProjectAdBudgetEdit'
import ProjectAdCampaignCreate from '@/components/project/projectAds/projectAdCampaign/ProjectAdCampaignCreate'
import ProjectAdCampaignEdit from '@/components/project/projectAds/projectAdCampaign/ProjectAdCampaignEdit'
import ProjectAdMaterialCreate from '@/components/project/projectAds/projectAdMaterial/ProjectAdMaterialCreate'
import ProjectAdMaterialEdit from '@/components/project/projectAds/projectAdMaterial/ProjectAdMaterialEdit'
import ProjectBriefingCreate from '@/components/project/projectOverview/projectBriefing/ProjectBriefingCreate'
import ProjectBriefingEdit from '@/components/project/projectOverview/projectBriefing/ProjectBriefingEdit'
import ProjectBriefingView from '@/components/project/projectOverview/projectBriefing/ProjectBriefingView'
import ProjectUploadCreate from '@/components/project/projectOverview/projectUpload/ProjectUploadCreate'
import ProjectListCreate from '@/components/project/projectPublicity/projectLists/ProjectListCreate'
import ProjectListEdit from '@/components/project/projectPublicity/projectLists/ProjectListEdit'
import ProjectMaterialCreate from '@/components/project/projectPublicity/projectMaterial/ProjectMaterialCreate'
import ProjectMaterialEdit from '@/components/project/projectPublicity/projectMaterial/ProjectMaterialEdit'
import ProjectMediacontactPartnerEdit
  from '@/components/project/projectPublicity/projectMediacontactPartner/ProjectMediacontactPartnerEdit'
import ProjectMediacontactPartnerFeatureCreate
  from '@/components/project/projectPublicity/projectMediacontactPartner/projectMediacontactPartnerFeature/ProjectMediacontactPartnerFeatureCreate'
import ProjectMediacontactPartnerFeatureEdit
  from '@/components/project/projectPublicity/projectMediacontactPartner/projectMediacontactPartnerFeature/ProjectMediacontactPartnerFeatureEdit'
import ProjectInfluencerCreate from '@/components/project/projectSocialMedia/projectInfluencer/ProjectInfluencerCreate'
import ProjectInfluencerEdit from '@/components/project/projectSocialMedia/projectInfluencer/ProjectInfluencerEdit'
import CustomerCreate from '@/components/customers/CustomerCreate'
import CustomerEdit from '@/components/customers/CustomerEdit'
import CustomerContactCreate from '@/components/customers/customerContact/CustomerContactCreate'
import CustomerContactEdit from '@/components/customers/customerContact/CustomerContactEdit'
import MediacontactCreate from '@/components/mediacontact/MediacontactCreate'
import MediacontactEdit from '@/components/mediacontact/MediacontactEdit'
import MediacontactView from '@/components/mediacontact/MediacontactView'
import MediacontactAdd from '@/components/mediacontact/MediacontactAdd'
import MediacontactPrint from '@/components/mediacontact/MediacontactPrint'
import MediacontactPartnerCreate from '@/components/mediacontact/mediacontactPartner/MediacontactPartnerCreate'
import MediacontactPartnerEdit from '@/components/mediacontact/mediacontactPartner/MediacontactPartnerEdit'
import MediacontactPartnerView from '@/components/mediacontact/mediacontactPartner/MediacontactPartnerView'
import MediacontactCompanyCreate from '@/components/mediacontact/mediacontactCompany/MediacontactCompanyCreate'
import MediacontactCompanyEdit from '@/components/mediacontact/mediacontactCompany/MediacontactCompanyEdit'
import MediacontactPartnerCategoryCreate
  from '@/components/mediacontact/mediacontactPartnerCategory/MediacontactPartnerCategoryCreate'
import MediacontactPartnerCategoryEdit
  from '@/components/mediacontact/mediacontactPartnerCategory/MediacontactPartnerCategoryEdit'
import MediacontactProductTypeCreate
  from '@/components/mediacontact/mediacontactProductType/MediacontactProductTypeCreate'
import MediacontactProductTypeEdit from '@/components/mediacontact/mediacontactProductType/MediacontactProductTypeEdit'
import MediacontactRegionCreate from '@/components/mediacontact/mediacontactRegion/MediacontactRegionCreate'
import MediacontactRegionEdit from '@/components/mediacontact/mediacontactRegion/MediacontactRegionEdit'
import InfluencerCreate from '@/components/influencer/InfluencerCreate'
import InfluencerEdit from '@/components/influencer/InfluencerEdit'
import InfluencerView from '@/components/influencer/InfluencerView'
import InfluencerAdd from '@/components/influencer/InfluencerAdd'
import InfluencerPrint from '@/components/influencer/InfluencerPrint'
import InfluencerAudienceCreate from '@/components/influencer/influencerAudience/InfluencerAudienceCreate'
import InfluencerAudienceEdit from '@/components/influencer/influencerAudience/InfluencerAudienceEdit'
import InfluencerCategoryCreate from '@/components/influencer/influencerCategory/InfluencerCategoryCreate'
import InfluencerCategoryEdit from '@/components/influencer/influencerCategory/InfluencerCategoryEdit'
import InfluencerNetworkCreate from '@/components/influencer/influencerNetwork/InfluencerNetworkCreate'
import InfluencerNetworkEdit from '@/components/influencer/influencerNetwork/InfluencerNetworkEdit'
import InfluencerSubCategoryCreate from '@/components/influencer/influencerSubCategory/InfluencerSubCategoryCreate'
import InfluencerSubCategoryEdit from '@/components/influencer/influencerSubCategory/InfluencerSubCategoryEdit'
import BusinessContactCreate from '@/components/businessContact/BusinessContactCreate'
import BusinessContactEdit from '@/components/businessContact/BusinessContactEdit'
import BusinessContactCategoryCreate
  from '@/components/businessContact/businessContactCategory/BusinessContactCategoryCreate'
import BusinessContactCompanyCreate
  from '@/components/businessContact/businessContactCompany/BusinessContactCompanyCreate'
import BusinessContactTopicCreate from '@/components/businessContact/businessContactTopic/BusinessContactTopicCreate'
import BusinessContactCategoryEdit
  from '@/components/businessContact/businessContactCategory/BusinessContactCategoryEdit'
import BusinessContactCompanyEdit from '@/components/businessContact/businessContactCompany/BusinessContactCompanyEdit'
import BusinessContactTopicEdit from '@/components/businessContact/businessContactTopic/BusinessContactTopicEdit'
import BusinessContactView from '@/components/businessContact/BusinessContactView'
import BusinessContactPrint from '@/components/businessContact/BusinessContactPrint'
import ProjectListMediacontactMaterialCreate
  from '@/components/project/projectPublicity/projectLists/projectListMediacontact/projectListMediacontactMaterial/ProjectListMediacontactMaterialCreate'
import ProjectListMediacontactMaterialEdit
  from '@/components/project/projectPublicity/projectLists/projectListMediacontact/projectListMediacontactMaterial/ProjectListMediacontactMaterialEdit'
import ProjectListMediacontactMaterialList
  from '@/components/project/projectPublicity/projectLists/projectListMediacontact/projectListMediacontactMaterial/ProjectListMediacontactMaterialList'
import UserCreate from '@/components/users/UserCreate'
import UserEdit from '@/components/users/UserEdit'
import CustomerContactView from '@/components/customers/customerContact/CustomerContactView'
import CustomerContactPrint from '@/components/customers/customerContact/CustomerContactPrint.vue'

// project
Vue.component('ProjectCreate', ProjectCreate)
Vue.component('ProjectEdit', ProjectEdit)
Vue.component('ProjectAccountingDealCreate', ProjectAccountingDealCreate)
Vue.component('ProjectAccountingDealEdit', ProjectAccountingDealEdit)
Vue.component('ProjectAccountingInvoiceCreate', ProjectAccountingInvoiceCreate)
Vue.component('ProjectAccountingInvoiceEdit', ProjectAccountingInvoiceEdit)
Vue.component('ProjectAccountingItemCreate', ProjectAccountingItemCreate)
Vue.component('ProjectAccountingItemEdit', ProjectAccountingItemEdit)
Vue.component('ProjectAdBudgetCreate', ProjectAdBudgetCreate)
Vue.component('ProjectAdBudgetEdit', ProjectAdBudgetEdit)
Vue.component('ProjectAdCampaignCreate', ProjectAdCampaignCreate)
Vue.component('ProjectAdCampaignEdit', ProjectAdCampaignEdit)
Vue.component('ProjectAdMaterialCreate', ProjectAdMaterialCreate)
Vue.component('ProjectAdMaterialEdit', ProjectAdMaterialEdit)
Vue.component('ProjectBriefingCreate', ProjectBriefingCreate)
Vue.component('ProjectBriefingEdit', ProjectBriefingEdit)
Vue.component('ProjectBriefingView', ProjectBriefingView)
Vue.component('ProjectUploadCreate', ProjectUploadCreate)
Vue.component('ProjectListCreate', ProjectListCreate)
Vue.component('ProjectListEdit', ProjectListEdit)
Vue.component('ProjectListMediacontactMaterialList', ProjectListMediacontactMaterialList)
Vue.component('ProjectListMediacontactMaterialCreate', ProjectListMediacontactMaterialCreate)
Vue.component('ProjectListMediacontactMaterialEdit', ProjectListMediacontactMaterialEdit)
Vue.component('ProjectMaterialCreate', ProjectMaterialCreate)
Vue.component('ProjectMaterialEdit', ProjectMaterialEdit)
Vue.component('ProjectMediacontactPartnerEdit', ProjectMediacontactPartnerEdit)
Vue.component('ProjectMediacontactPartnerFeatureCreate', ProjectMediacontactPartnerFeatureCreate)
Vue.component('ProjectMediacontactPartnerFeatureEdit', ProjectMediacontactPartnerFeatureEdit)
Vue.component('ProjectInfluencerCreate', ProjectInfluencerCreate)
Vue.component('ProjectInfluencerEdit', ProjectInfluencerEdit)

// customer
Vue.component('CustomerCreate', CustomerCreate)
Vue.component('CustomerEdit', CustomerEdit)
Vue.component('CustomerContactCreate', CustomerContactCreate)
Vue.component('CustomerContactEdit', CustomerContactEdit)
Vue.component('CustomerContactView', CustomerContactView)
Vue.component('CustomerContactPrint', CustomerContactPrint)

// mediacontact
Vue.component('MediacontactCreate', MediacontactCreate)
Vue.component('MediacontactEdit', MediacontactEdit)
Vue.component('MediacontactView', MediacontactView)
Vue.component('MediacontactAdd', MediacontactAdd)
Vue.component('MediacontactPrint', MediacontactPrint)
Vue.component('MediacontactPartnerCreate', MediacontactPartnerCreate)
Vue.component('MediacontactPartnerEdit', MediacontactPartnerEdit)
Vue.component('MediacontactPartnerView', MediacontactPartnerView)
Vue.component('MediacontactCompanyCreate', MediacontactCompanyCreate)
Vue.component('MediacontactCompanyEdit', MediacontactCompanyEdit)
Vue.component('MediacontactPartnerCategoryCreate', MediacontactPartnerCategoryCreate)
Vue.component('MediacontactPartnerCategoryEdit', MediacontactPartnerCategoryEdit)
Vue.component('MediacontactProductTypeCreate', MediacontactProductTypeCreate)
Vue.component('MediacontactProductTypeEdit', MediacontactProductTypeEdit)
Vue.component('MediacontactRegionCreate', MediacontactRegionCreate)
Vue.component('MediacontactRegionEdit', MediacontactRegionEdit)

// influencer
Vue.component('InfluencerCreate', InfluencerCreate)
Vue.component('InfluencerEdit', InfluencerEdit)
Vue.component('InfluencerView', InfluencerView)
Vue.component('InfluencerAdd', InfluencerAdd)
Vue.component('InfluencerPrint', InfluencerPrint)
Vue.component('InfluencerAudienceCreate', InfluencerAudienceCreate)
Vue.component('InfluencerAudienceEdit', InfluencerAudienceEdit)
Vue.component('InfluencerCategoryCreate', InfluencerCategoryCreate)
Vue.component('InfluencerCategoryEdit', InfluencerCategoryEdit)
Vue.component('InfluencerNetworkCreate', InfluencerNetworkCreate)
Vue.component('InfluencerNetworkEdit', InfluencerNetworkEdit)
Vue.component('InfluencerSubCategoryCreate', InfluencerSubCategoryCreate)
Vue.component('InfluencerSubCategoryEdit', InfluencerSubCategoryEdit)

// business contact
Vue.component('BusinessContactCreate', BusinessContactCreate)
Vue.component('BusinessContactEdit', BusinessContactEdit)
Vue.component('BusinessContactView', BusinessContactView)
Vue.component('BusinessContactPrint', BusinessContactPrint)
Vue.component('BusinessContactCategoryCreate', BusinessContactCategoryCreate)
Vue.component('BusinessContactCategoryEdit', BusinessContactCategoryEdit)
Vue.component('BusinessContactCompanyCreate', BusinessContactCompanyCreate)
Vue.component('BusinessContactCompanyEdit', BusinessContactCompanyEdit)
Vue.component('BusinessContactTopicCreate', BusinessContactTopicCreate)
Vue.component('BusinessContactTopicEdit', BusinessContactTopicEdit)

// user
Vue.component('UserCreate', UserCreate)
Vue.component('UserEdit', UserEdit)
