import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectBriefings',
  urlRoot: '/project_briefings',
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectBriefing => projectBriefing.project.id === parseInt(id))
    },
    byProjectManager: (state) => (id) => {
      return Object.values(state.entities).filter(
        projectBriefing => projectBriefing.project.isActive &&
                    (projectBriefing.project.managers.some(user => user.id === id) || projectBriefing.usersInCharge.some(user => user.id === id))
      )
    },
    active: state => {
      return Object.values(state.entities).filter(projectBriefing => projectBriefing.project.isActive)
    }
  }
})
