<template>

  <base-modal name="project-list-mediacontact-material-list"
              title="Material"
              :width="400"
              height="auto"
              :adaptive="true"
              :clickToClose="false"
              v-on="$listeners"
              v-bind="$attrs">
    <div class="info">
      <div v-for="projectMaterial in projectMaterials" :key="projectMaterial.id">
        {{ projectMaterial.material.name }}:
        {{ spent(projectMaterial) }}/{{ projectMaterial.count }} verbraucht | {{ left(projectMaterial) }} übrig
      </div>
    </div>
  </base-modal>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProjectListMediacontactMaterialList',
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      await this.getProjectMaterials({ customUrl: '/projects/' + this.$route.params.projectId + '/project_materials' })
      this.loading = false
    },
    spent (projectMaterial) {
      return projectMaterial.projectListMediacontactMaterial
        .map(projectListMediacontactMaterial => projectListMediacontactMaterial.count)
        .reduce((cur, acc) => cur + acc, 0)
    },
    left (projectMaterial) {
      return projectMaterial.count - this.spent(projectMaterial)
    },
    ...mapActions({
      getProjectMaterials: 'projectMaterials/fetchList'
    })
  },
  computed: {
    projectMaterials () {
      return this.projectMaterialsByProject(this.$route.params.projectId)
    },
    projectListMediacontacts () {
      return this.projectListMediacontactsByProjectList(this.$route.params.projectListId)
    },
    ...mapGetters({
      projectMaterialsByProject: 'projectMaterials/byProject',
      projectListMediacontactsByProjectList: 'projectListMediacontacts/byProjectList'
    })
  }
}
</script>
