<template>

  <base-modal name="mediacontact-add"
              title="Medienkontakt zu Verteiler hinzufügen"
              :subtitle="`${selected.length} ausgewählt`"
              :width="450"
              v-on="$listeners"
              v-bind="$attrs">
      <form v-on:submit.prevent="">

        <div>
          <label>Projekt</label>
          <multiselect v-model="project"
                       :options="projects"
                       track-by="id"
                       label="title"
                       :customLabel="projectLabel"
                       placeholder="Auswählen"
                       :show-labels="false"
                       selectLabel="Auswählen"
                       deselectLabel="Entfernen"
                       selectedLabel="Ausgewählt"
                       @select="projectSelected"
                       :loading="loadingProjects">
          </multiselect>
        </div>

        <div v-if="project">
          <label>Verteiler</label>
          <multiselect v-model="projectList"
                       :options="projectLists"
                       track-by="id"
                       label="name"
                       :customLabel="projectListLabel"
                       :taggable="true"
                       @tag="addProjectList"
                       placeholder="Auswählen oder hinzufügen"
                       selectLabel="Auswählen"
                       deselectLabel="Entfernen"
                       selectedLabel="Ausgewählt"
                       tagPlaceholder="Hinzufügen"
                       @select="projectListSelected"
                       :loading="loadingProjectLists">
          </multiselect>
        </div>

        <div v-if="project && projectList && (selected.length !== filteredSelected.length)">
          <label>Info</label>
          <div class="subtitle">
            {{ selected.length - filteredSelected.length }} Medienkontakt bereits im Verteiler vorhanden.
          </div>
        </div>

        <div class="action">
          <BaseButton :loading="processing"
                      :disabled="checkForm || processing"
                      icon="plus"
                      :label="`${filteredSelected.length} hinzufügen`"
                      :submit="true"
                      @click="proceed"/>
        </div>

      </form>
  </base-modal>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'MediacontactAdd',
  props: {
    selected: Array
  },
  data () {
    return {
      project: null,
      projectList: null,
      loadingProjects: true,
      loadingProjectLists: false,
      filteredSelected: [],
      processing: false
    }
  },
  async created () {
    await this.getProjects()
    this.loadingProjects = false
  },
  methods: {
    async projectSelected (selectedOption) {
      this.projectList = null
      this.loadingProjectLists = true
      await this.getProjectLists({ customUrl: '/projects/' + selectedOption.id + '/project_lists' })
      this.loadingProjectLists = false
    },
    projectLabel ({ title, projectLists }) {
      return `${title} (${projectLists.length} Verteiler)`
    },
    projectListLabel ({ name, projectListMediacontacts }) {
      return `${name} (${projectListMediacontacts.length} Medienkontakt)`
    },
    addProjectList (name) {
      const projectList = {
        project: '/projects/' + this.project.id,
        name: name,
        projectListMediacontacts: []
      }
      this.projectLists.push(projectList)
      this.projectList = projectList

      // calculate intersection
      this.projectListSelected(projectList)
    },
    projectListSelected (selectedOption) {
      this.filteredSelected = this.selected.filter(mediacontact => {
        for (let i = 0; i < selectedOption.projectListMediacontacts.length; i++) {
          if (selectedOption.projectListMediacontacts[i].mediacontact === '/mediacontacts/' + mediacontact.id) {
            return false
          }
        }
        return true
      })
    },
    async proceed () {
      try {
        this.processing = true
        this.filteredSelected.forEach(mediacontact => {
          this.projectList.projectListMediacontacts.push({
            mediacontact: '/mediacontacts/' + mediacontact.id
          })
        })
        this.projectList.updatedBy = 'users/' + this.authUser.id
        this.projectList.updatedAt = moment().format()

        if (!this.projectList.id) {
          const response = await this.createProjectList({ data: this.projectList })
          this.projectList = response.data
        } else {
          await this.updateProjectList({ id: this.projectList.id, data: this.projectList })
        }
        await this.getProjectLists({ customUrl: '/projects/' + this.project.id + '/project_lists' })
        await this.getProjectListMediacontacts({ customUrl: '/project_lists/' + this.projectList.id + '/project_list_mediacontacts' })
        this.processing = false
        this.$vfm.hide('mediacontact-add')
        this.$toasted.success(this.filteredSelected.length + ' Medienkontakte hinzugefügt', { icon: 'fas fa-user-edit' })
        this.$router.push({
          name: 'projectListMediacontactList',
          params: {
            projectId: this.project.id,
            projectListId: this.projectList.id
          }
        })
      } catch (error) {
        this.processing = false
      }
    },
    ...mapActions({
      getProjects: 'projects/fetchList',
      getProjectLists: 'projectLists/fetchList',
      createProjectList: 'projectLists/create',
      updateProjectList: 'projectLists/replace',
      getProjectListMediacontacts: 'projectListMediacontacts/fetchList'
    })
  },
  computed: {
    projects () {
      return this.activeProjectsByUser(this.authUser.id)
    },
    projectLists () {
      if (this.project && this.project.id) {
        return this.projectListsByProject(this.project.id)
      }
      return []
    },
    checkForm () {
      return !(this.project && this.project.id && this.projectList && this.projectList.name && this.filteredSelected.length)
    },
    ...mapGetters({
      activeProjectsByUser: 'projects/activeByUser',
      projectListsByProject: 'projectLists/byProject',
      authUser: 'auth/authUser'
    })
  }
}
</script>
