import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'mediacontactRegions',
  urlRoot: '/mediacontact_regions',
  state: {
    filter: {
      search: ''
    }
  }
})
