<template>

    <div>
        <BaseList title="Aktivitäten" icon="fire-alt" :count="filteredActivities.length">
          <VirtualList
            :data-key="'id'"
            :data-sources="filteredActivities"
            :data-component="dashboardActivityListItem"
            :estimated-size="50"
            item-class="listitem"
          />

            <skeleton-row :show="loading"></skeleton-row>
            <not-found-row entity="Aktivitäten" :show="!loading && !filteredActivities.length"></not-found-row>
        </BaseList>
    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DashboardActivityListItem from './DashboardActivityListItem.vue'
import SkeletonRow from '@/components/SkeletonRow.vue'
import NotFoundRow from '@/components/NotFoundRow.vue'
import moment from 'moment'
import { orderBy } from 'lodash'

export default {
  name: 'DashboardActivity',
  components: {
    NotFoundRow,
    SkeletonRow
  },
  data () {
    return {
      loading: true,
      filter: {
        range: { label: 'Eine Woche', number: 4, value: 'week' }
      },
      dashboardActivityListItem: DashboardActivityListItem
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      await Promise.all([
        this.mediacontacts.length || this.getMediacontacts(),
        this.mediacontactPartners.length || this.getMediacontactPartners(),
        this.influencers.length || this.getInfluencers(),
        this.businessContacts.length || this.getBusinessContacts()
      ])
      this.loading = false
    },
    ...mapActions({
      getMediacontacts: 'mediacontacts/fetchList',
      getMediacontactPartners: 'mediacontactPartners/fetchList',
      getInfluencers: 'influencers/fetchList',
      getBusinessContacts: 'businessContacts/fetchList'
    })
  },
  computed: {
    filteredActivities () {
      const activities = []
      this.mediacontacts.forEach(mediacontact => {
        mediacontact.activityCategory = 'mediacontact'
        activities.push(mediacontact)
      })
      this.mediacontactPartners.forEach(mediacontactMedium => {
        mediacontactMedium.activityCategory = 'mediacontactPartner'
        activities.push(mediacontactMedium)
      })
      this.influencers.forEach(influencer => {
        influencer.activityCategory = 'influencer'
        activities.push(influencer)
      })
      this.businessContacts.forEach(businessContact => {
        businessContact.activityCategory = 'businessContact'
        activities.push(businessContact)
      })
      return orderBy(activities
        .filter(activity => activity.updatedBy || activity.updatedAt)
        .filter(activity => !this.filter.range || moment(activity.updatedAt).isBetween(moment().subtract(this.filter.range.number, this.filter.range.value), moment()))
      , 'updatedAt', 'desc')
    },
    ...mapGetters({
      mediacontacts: 'mediacontacts/list',
      mediacontactPartners: 'mediacontactPartners/list',
      influencers: 'influencers/list',
      businessContacts: 'businessContacts/list',
      authUser: 'auth/authUser',
      hasChiefRole: 'auth/hasChiefRole'
    })
  }
}
</script>

<style lang="scss">
.scroller-activities {
  height: 500px;
}
</style>
