import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectLists',
  urlRoot: '/project_lists',
  state: {
    filter: {
      search: '',
      mediapartnerProductTypes: [],
      mediapartnerDepartments: [],
      mediapartnerPartnerCategories: [],
      mediapartnerPartners: [],
      mediapartnerCompanies: [],
      mediapartnerRegions: [],
      mediapartnerStatus: [{ name: 'Aktiv', value: true }],
      projectListMediapartnerStatus: [],
      sortBy: 'mediacontact.lastName',
      sortDirection: 'asc',
      selected: []
    }
  },
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectList => projectList.project === '/projects/' + id)
    },
    byProjectManager: (state) => (id) => {
      return Object.values(state.entities).filter(projectList => projectList.project.isActive && projectList.project.managers.some(user => user.id === parseInt(id)))
    }
  }
})
