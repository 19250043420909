<template>

  <base-modal toptitle="Influencer"
              :title="influencer.name"
              :subtitle="`Letzte Änderung von ${updatedBy} ${updatedAt}`"
              name="influencer-view"
              :width="1200"
              v-on="$listeners"
              v-bind="$attrs">
    <div v-if="influencer" class="middle">

      <div class="group">
        <div class="title">Name</div>
        <div v-if="influencer.name" class="info-item">
          <div class="label">Künstlername</div>
          <font-awesome-icon icon="grin-stars"/>
          {{ influencer.name }}
        </div>
        <div v-if="influencer.fullName" class="info-item">
          <div class="label">Vollständiger Name</div>
          <font-awesome-icon icon="user"/>
          {{ influencer.fullName }}
        </div>

        <div class="title">Kategorie & Zielgruppe</div>
        <div v-if="influencer.influencerCategory" class="info-item">
          <div class="label">Kategorie</div>
          <font-awesome-icon icon="tags"/>
          {{ influencer.influencerCategory.name }}
        </div>

        <div v-if="influencer.influencerSubCategories.length" class="info-item">
          <div class="label">Schwerpunkte</div>
          <font-awesome-icon icon="th"/>
          <span v-for="influencerSubCategory in influencer.influencerSubCategories"
                :key="influencerSubCategory.id"
                class="chip">
                            {{ influencerSubCategory.name }}
                        </span>
        </div>

        <div v-if="influencer.influencerAudiences.length" class="info-item">
          <div class="label">Zielgruppen</div>
          <font-awesome-icon icon="bullseye"/>
          <span v-for="influencerAudience in influencer.influencerAudiences" :key="influencerAudience.id"
                class="chip">
                            {{ influencerAudience.name }}
                        </span>
        </div>

        <div v-if="influencer.region" class="info-item">
          <div class="label">Region</div>
          <font-awesome-icon icon="map-marker-alt"/>
          {{ influencer.region }}
        </div>

        <div v-if="influencer.notes" class="info-item">
          <div class="label">Notizen</div>
          <font-awesome-icon icon="sticky-note"/>
          {{ influencer.notes }}
        </div>
      </div>

      <div class="group">
        <div class="title">Ansprechpartner</div>

        <div v-if="influencer.network" class="info-item">
          <div class="label">Netzwerk</div>
          <font-awesome-icon icon="project-diagram"/>
          <div>{{ influencer.network.name }}</div>
        </div>

        <div v-if="influencer.asp" class="info-item">
          <div class="label">Name</div>
          <font-awesome-icon icon="grin-stars"/>
          <div>{{ influencer.asp }}</div>
        </div>

        <div v-if="influencer.email" class="info-item">
          <div class="label">E-Mail</div>
          <font-awesome-icon icon="envelope"/>
          <a v-if="influencer.email" :href="'mailto:'+influencer.email">{{ influencer.email }}</a>
        </div>

        <div v-if="influencer.phone" class="info-item">
          <div class="label">Telefon</div>
          <font-awesome-icon icon="phone-alt"/>
          <div v-if="influencer.phone">{{ influencer.phone }}</div>
        </div>

        <div v-if="influencer.street" class="info-item">
          <div class="label">Adresse</div>
          <font-awesome-icon icon="map-marker-alt"/>
          {{ influencer.street }}<br>
          {{ influencer.zip }} {{ influencer.city }}<br>
          {{ influencer.country }}
        </div>

        <div v-if="influencer.projectInfluencers.length" class="info-item">
          <div class="label">Projekte</div>
          <font-awesome-icon icon="stream"/>
          <span v-for="projectInfluencer in influencer.projectInfluencers" :key="projectInfluencer.id"
                class="chip">
                            <router-link
                              :to="{ name: 'projectPublicity', params: { projectId: projectInfluencer.project.id }}">
                                {{ projectInfluencer.project.title }}
                            </router-link>
                        </span>
        </div>

      </div>

      <div class="group">
        <div class="title">Kanäle</div>

        <div v-if="influencer.facebookPageName" class="info-item">
          <div class="label">Facebook</div>
          <a :href="facebookUrl" target="_blank">
            <div v-if="facebookPageImageUrl && influencer.facebookPageLikesCount"
                 class="social-profile">
              <img :src="facebookPageImageUrl" class="profile-pic"/>
              <div>
                <div>{{ influencer.facebookPageLikesCount.toLocaleString() }} Likes</div>
                <div v-if="influencer.facebookPageUpdatedAt" class="subtitle">aktualisiert
                  {{ facebookPageUpdatedAt }}
                </div>
              </div>
            </div>
            <div v-else>
              <font-awesome-icon :icon="['fab', 'facebook']" size="lg"/>
              {{ influencer.facebookPageName }}
            </div>
          </a>
        </div>
        <div v-if="influencer.instagramProfileName" class="info-item">
          <div class="label">Instagram</div>
          <a :href="instagramUrl" target="_blank">
            <div v-if="influencer.instagramProfileImageUrl && influencer.instagramProfileFollowerCount"
                 class="social-profile">
              <img :src="influencer.instagramProfileImageUrl" class="profile-pic"/>
              <div>
                <div>{{ influencer.instagramProfileFollowerCount.toLocaleString() }} Abonnenten
                </div>
                <div v-if="influencer.instagramProfileUpdatedAt" class="subtitle">aktualisiert
                  {{ instagramProfileUpdatedAt }}
                </div>
              </div>
            </div>
            <div v-else>
              <font-awesome-icon :icon="['fab', 'instagram']" size="lg"/>
              {{ influencer.instagramProfileName }}
            </div>
          </a>
        </div>
        <div v-if="influencer.twitterProfileName" class="info-item">
          <div class="label">Twitter</div>
          <a :href="twitterUrl" target="_blank">
            <div v-if="influencer.twitterProfileImageUrl && influencer.twitterProfileFollowerCount"
                 class="social-profile">
              <img :src="influencer.twitterProfileImageUrl" class="profile-pic"/>
              <div>
                <div>{{ influencer.twitterProfileFollowerCount.toLocaleString() }} Follower</div>
                <div v-if="influencer.twitterProfileUpdatedAt" class="subtitle">aktualisiert
                  {{ twitterProfileUpdatedAt }}
                </div>
              </div>
            </div>
            <div v-else>
              <font-awesome-icon :icon="['fab', 'twitter']" size="lg"/>
              {{ influencer.twitterProfileName }}
            </div>
          </a>
        </div>
        <div v-if="influencer.youtubeChannelId" class="info-item">
          <div class="label">YouTube</div>
          <a :href="youtubeUrl" target="_blank">
            <div v-if="influencer.youtubeChannelImageUrl && influencer.youtubeChannelSubscriberCount"
                 class="social-profile">
              <img :src="influencer.youtubeChannelImageUrl" class="profile-pic"/>
              <div>
                <div>{{ influencer.youtubeChannelSubscriberCount.toLocaleString() }} Abonnenten
                </div>
                <div v-if="influencer.youtubeChannelUpdatedAt" class="subtitle">aktualisiert
                  {{ youtubeChannelUpdatedAt }}
                </div>
              </div>
            </div>
            <div v-else>
              <font-awesome-icon :icon="['fab', 'youtube']" size="lg"/>
              {{ influencer.youtubeChannelId }}
            </div>
          </a>
        </div>
        <div v-if="influencer.tiktokUserId" class="info-item">
          <div class="label">TikTok</div>
          <a :href="tiktokUrl" target="_blank">
            <div v-if="influencer.tiktokUserImageUrl && influencer.tiktokUserFollowerCount"
                 class="social-profile">
              <img :src="influencer.tiktokUserImageUrl" class="profile-pic"/>
              <div>
                <div>{{ influencer.tiktokUserFollowerCount.toLocaleString() }} Follower
                </div>
                <div v-if="influencer.tiktokUserUpdatedAt" class="subtitle">aktualisiert
                  {{ tiktokUserUpdatedAt }}
                </div>
              </div>
            </div>
            <div v-else>
              <font-awesome-icon :icon="['fab', 'tiktok']" size="lg"/>
              {{ influencer.tiktokUserId }}
            </div>
          </a>
        </div>
        <div v-if="influencer.twitchChannelId" class="info-item">
          <div class="label">Twitch</div>
          <a :href="twitchUrl" target="_blank">
            <div v-if="influencer.twitchChannelImageUrl && influencer.twitchChannelFollowerCount"
                 class="social-profile">
              <img :src="influencer.twitchChannelImageUrl" class="profile-pic"/>
              <div>
                <div>{{ influencer.twitchChannelFollowerCount.toLocaleString() }} Follower
                </div>
                <div v-if="influencer.twitchChannelUpdatedAt" class="subtitle">aktualisiert
                  {{ twitchChannelUpdatedAt }}
                </div>
              </div>
            </div>
            <div v-else>
              <font-awesome-icon :icon="['fab', 'twitch']" size="lg"/>
              {{ influencer.twitchChannelId }}
            </div>
          </a>
        </div>

        <div v-if="influencer.applePodcastId" class="info-item">
          <div class="label">Apple Podcast</div>
          <a :href="applePodcastUrl" target="_blank">

            <div v-if="influencer.applePodcastImageUrl && influencer.applePodcastTrackCount"
                 class="social-profile">
              <img :src="influencer.applePodcastImageUrl" class="profile-pic"/>
              <div>
                <div>{{ influencer.applePodcastTrackCount.toLocaleString() }} Folgen
                </div>
                <div v-if="influencer.applePodcastUpdatedAt" class="subtitle">aktualisiert
                  {{ applePodcastUpdatedAt }}
                </div>
              </div>
            </div>
            <div v-else>
              <font-awesome-icon icon="podcast" size="lg"/>
              {{ influencer.applePodcastId }}
            </div>
          </a>
        </div>

        <div v-if="influencer.blog" class="info-item">
          <div class="label">Blog</div>
          <font-awesome-icon icon="desktop"/>
          <a :href="influencer.blog">{{ influencer.blog }}</a>
        </div>
      </div>
    </div>
    <div class="action">
      <button class="button button-flat" @click="edit()">
        <font-awesome-icon icon="pen" size="sm"/>
        <span>Bearbeiten</span>
      </button>
    </div>
  </base-modal>

</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'InfluencerView',
  props: {
    influencer: Object
  },
  methods: {
    edit () {
      this.$vfm.hide('influencer-view')
      this.$vfm.show({ component: 'InfluencerEdit', bind: { influencer: this.influencer } })
    }
  },
  computed: {
    instagramUrl () {
      return `https://www.instagram.com/${this.influencer.instagramProfileName}/`
    },
    instagramProfileUpdatedAt () {
      return moment(this.influencer.instagramProfileUpdatedAt).from()
    },
    youtubeUrl () {
      return `https://www.youtube.com/channel/${this.influencer.youtubeChannelId}`
    },
    youtubeChannelUpdatedAt () {
      return moment(this.influencer.youtubeChannelUpdatedAt).from()
    },
    facebookUrl () {
      return `https://www.facebook.com/${this.influencer.facebookPageName}`
    },
    facebookPageImageUrl () {
      return `https://graph.facebook.com/${this.influencer.facebookPageName}/picture?type=square`
    },
    facebookPageUpdatedAt () {
      return moment(this.influencer.facebookPageUpdatedAt).from()
    },
    twitterUrl () {
      return `https://www.twitter.com/${this.influencer.twitterProfileName}`
    },
    twitterProfileUpdatedAt () {
      return moment(this.influencer.twitterProfileUpdatedAt).from()
    },
    tiktokUrl () {
      return `https://tiktok.com/@${this.influencer.tiktokUserId}`
    },
    tiktokUserUpdatedAt () {
      return moment(this.influencer.tiktokUserUpdatedAt).from()
    },
    twitchUrl () {
      return `https://twitch.tv/${this.influencer.twitchChannelId}`
    },
    twitchChannelUpdatedAt () {
      return moment(this.influencer.twitchChannelUpdatedAt).from()
    },
    applePodcastUrl () {
      return `https://podcasts.apple.com/de/podcast/${this.influencer.applePodcastId}`
    },
    applePodcastUpdatedAt () {
      return moment(this.influencer.applePodcastUpdatedAt).from()
    },
    updatedBy () {
      return this.influencer.updatedBy.id === this.authUser.id ? 'mir' : this.influencer.updatedBy.firstName
    },
    updatedAt () {
      return moment(this.influencer.updatedAt).fromNow()
    },
    ...mapGetters({
      authUser: 'auth/authUser'
    })
  }
}
</script>
