<template>

  <base-modal name="business-contact-category-create"
              title="Kategorie erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <business-contact-category-form :businessContactCategory="businessContactCategory"
                                    type="create"/>
  </base-modal>

</template>

<script>
import BusinessContactCategoryForm from './BusinessContactCategoryForm.vue'

export default {
  name: 'BusinessContactCategoryCreate',
  components: { BusinessContactCategoryForm },
  data: () => ({
    businessContactCategory: {
      name: null
    }
  })
}
</script>
