<template>

  <base-modal name="project-ad-campaign-create"
              :width="800"
              height="auto"
              title="Kampagne erstellen"
              v-on="$listeners"
              v-bind="$attrs">
      <project-ad-campaign-form :projectAdCampaign="projectAdCampaign" type="create"/>
  </base-modal>

</template>

<script>
import ProjectAdCampaignForm from './ProjectAdCampaignForm.vue'

export default {
  name: 'ProjectAdCampaignCreate',
  components: { ProjectAdCampaignForm },
  data: () => ({
    projectAdCampaign: {
      project: null,
      adCreation: false
    }
  }),
  created () {
    this.projectAdCampaign.project = '/projects/' + this.$route.params.projectId
  }
}
</script>
