<template>

  <form v-on:submit.prevent="">

    <div class="middle">
      <div class="group">
        <div class="title">Name</div>
        <BaseInputFloatinglabel v-model="form.firstName"
                                icon="user-tie"
                                placeholder="Vorname"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.lastName"
                                icon="user-tie"
                                placeholder="Nachname"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.position"
                                icon="sitemap"
                                placeholder="Position"
                                type="text"/>

        <div class="title">Kontakt</div>
        <BaseInputFloatinglabel v-model="form.email"
                                icon="envelope"
                                placeholder="E-Mail"
                                type="email"/>
        <BaseInputFloatinglabel v-model="form.phone"
                                icon="phone-alt"
                                placeholder="Telefon"
                                type="tel"/>
        <BaseInputFloatinglabel v-model="form.mobile"
                                icon="mobile-alt"
                                placeholder="Mobil"
                                type="tel"/>
        <BaseCheckbox v-if="type === 'edit'"
                      v-model="form.isActive"
                      :id="form.id"
                      field="customerContact"
                      placeholder="Aktiv"/>
      </div>
    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="hasChiefRole && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'CustomerContactForm',
  props: ['customerContact', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.customerContact)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        const response = await this.createCustomerContact({ data: this.form })
        this.$vfm.hide('customer-contact-create')
        this.$toasted.success('Ansprechpartner ' + response.data.firstName + ' ' + response.data.lastName + ' erstellt', { icon: 'fas fa-user-tie' })
        this.creating = false
        this.getCustomerContacts({ customUrl: '/customers/' + this.$route.params.customerId + '/customer_contacts' })
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateCustomerContact({ id: this.form.id, data: this.form })
        this.$vfm.hide('customer-contact-edit')
        this.$toasted.success('Ansprechpartner ' + response.data.firstName + ' ' + response.data.lastName + ' gespeichert', { icon: 'fas fa-user-tie' })
        this.updating = true
        this.getCustomerContact({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteCustomerContact({ id: this.form.id })
        this.$vfm.hide('customer-contact-edit')
        this.$toasted.success('Ansprechpartner gelöscht', { icon: 'fas fa-user-tie' })
        this.removing = false
        this.getCustomerContacts({ customUrl: '/customers/' + this.$route.params.customerId + '/customer_contacts' })
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      getCustomer: 'customers/fetchSingle',
      createCustomerContact: 'customerContacts/create',
      updateCustomerContact: 'customerContacts/replace',
      deleteCustomerContact: 'customerContacts/destroy',
      getCustomerContact: 'customerContacts/fetchSingle',
      getCustomerContacts: 'customerContacts/fetchList'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.firstName && this.form.lastName && this.validEmail)
    },
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.form.email)
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasChiefRole: 'auth/hasChiefRole'
    })
  }
}
</script>
