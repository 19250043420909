<template>

  <form v-on:submit.prevent="">

    <div class="middle">
      <div class="group">
        <div class="title">Titel</div>
        <BaseInputFloatinglabel v-model="form.title"
                                icon="stream"
                                placeholder="Titel"
                                type="text"/>

        <multiselect v-model="form.category"
                     :options="['Kino', 'Home', 'Serie', 'Musik', 'Buch', 'Hörbuch', 'Shop']"
                     :show-labels="false"
                     placeholder="Kategorie">
          <template #placeholder>
            <font-awesome-icon icon="tags"/>
            <span>Kategorie</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="tags"/>
            <span>{{ props.option }}</span>
          </template>
        </multiselect>

        <div class="title">Veröffentlichung</div>
        <v-date-picker
          v-model="form.estDate"
          :popover="{ visibility: 'click' }"
          :is-dark="darkmodeStatus"
          color="blue"
          is24hr
          mode="date"
          title-position="left">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="input-wrapper-floatinglabel">
              <font-awesome-icon icon="calendar-day"/>
              <input
                placeholder="EST"
                type="text"
                :value="inputValue"
                required
                v-on="inputEvents" />
              <label>
                EST
              </label>
            </div>
          </template>
        </v-date-picker>

        <v-date-picker
          v-model="form.releaseDate"
          :popover="{ visibility: 'click' }"
          :is-dark="darkmodeStatus"
          color="blue"
          is24hr
          mode="date"
          title-position="left">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="input-wrapper-floatinglabel">
              <font-awesome-icon icon="calendar-day"/>
              <input
                placeholder="Release"
                type="text"
                :value="inputValue"
                required
                v-on="inputEvents" />
              <label>
                Release
              </label>
            </div>
          </template>
        </v-date-picker>

        <BaseCheckbox v-model="form.tbc"
                      placeholder="TBC"
                      :id="form.id"
                      field="tbc"/>

      </div>

      <div class="group">
        <div class="title">Kunde</div>
        <multiselect v-model="customer"
                     :options="this.customers"
                     :show-labels="false"
                     label="name"
                     placeholder="Kunde"
                     track-by="id">
          <template #placeholder>
            <font-awesome-icon icon="building"/>
            <span>Kunde</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="building"/>
            <span>{{ props.option.name }}</span>
          </template>
        </multiselect>
        <multiselect v-if="project.customer"
                     v-model="form.customerContacts"
                     :customLabel="customerContactLabel"
                     :options="customerContacts"
                     :show-labels="false"
                     multiple
                     placeholder="Ansprechpartner"
                     track-by="id">
          <template #placeholder>
            <font-awesome-icon icon="user-tie"/>
            <span>Ansprechpartner</span>
          </template>
        </multiselect>

        <div class="title">Team & Manager</div>
        <multiselect v-model="form.departments"
                     :options="['Social Media', 'Marketing', 'Online PR', 'Offline PR', 'Web Development']"
                     :show-labels="false"
                     multiple
                     placeholder="Auswählen">
          <template #placeholder>
            <font-awesome-icon icon="th"/>
            <span>Bereiche</span>
          </template>
        </multiselect>
        <multiselect v-model="form.managers"
                     :hide-selected="true"
                     :options="this.users"
                     :show-labels="false"
                     label="firstName"
                     multiple
                     placeholder="Manager"
                     track-by="id">
          <template #placeholder>
            <font-awesome-icon icon="user-tie"/>
            <span>Manager</span>
          </template>
        </multiselect>

        <div class="title">Konzept</div>

        <multiselect v-model="form.conceptStatus"
                     :options="['Erforderlich', 'In Bearbeitung', 'Verschickt', 'Freigegeben', 'Nicht erforderlich']"
                     :show-labels="false"
                     placeholder="Status">
          <template #placeholder>
            <font-awesome-icon icon="file-alt"/>
            <span>Konzept Status</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="file-alt"/>
            <span>{{ props.option }}</span>
          </template>
        </multiselect>

        <multiselect v-if="project.conceptStatus"
                     v-model="form.conceptBy"
                     :options="this.users"
                     :show-labels="false"
                     label="firstName"
                     placeholder="Konzept-Ersteller"
                     track-by="id">
          <template #placeholder>
            <font-awesome-icon icon="user-edit"/>
            <span>Konzept Status</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="user-edit"/>
            <span>{{ props.option.firstName }}</span>
          </template>
        </multiselect>

      </div>

      <div class="group">

        <div class="title">Poster</div>
        <vue-dropzone id="dropzone"
                      ref="myVueDropzone"
                      :options="dropzoneOptions"
                      @vdropzone-mounted="dropzoneMounted"
                      @vdropzone-success="success">
        </vue-dropzone>

        <div v-if="hasChiefRole && type === 'edit'" class="title">Status</div>
        <BaseCheckbox v-if="hasChiefRole && type === 'edit'"
                      v-model="form.isActive"
                      :id="form.id"
                      field="active"
                      placeholder="Aktiv"/>

      </div>
    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="hasChiefRole && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import moment from 'moment'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectForm',
  props: ['project', 'type'],
  components: { vueDropzone: vue2Dropzone },
  data () {
    return {
      form: {
        id: null
      },
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + '/projects/' + this.project.id + '/cover',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        autoProcessQueue: false,
        dictDefaultMessage: 'Poster hierherziehen',
        maxFiles: 1,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Cache-Control': '',
          'X-Requested-With': ''
        },
        init () {
          this.on('addedfiles', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
          this.on('maxfilesexceeded', function (file) {
            this.removeAllFiles()
            this.addFile(file)
          })
        }
      },
      loading: true,
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.fetchData()
    this.form = cloneDeep(this.project)
  },
  methods: {
    async fetchData () {
      await Promise.all([
        this.users.length || this.getUsers(),
        this.customers.length || this.getCustomers()
      ])
      this.loading = false
    },
    async create () {
      try {
        this.creating = true
        const response = await this.createProject({ data: this.form })
        this.form = response.data
        // create with cover
        if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
          this.$refs.myVueDropzone.setOption('url', process.env.VUE_APP_API_URL + '/projects/' + this.form.id + '/cover')
          this.$refs.myVueDropzone.processQueue()
        } else {
          // create without cover
          this.$vfm.hide('project-create')
          await this.getProject({ id: this.form.id })
        }
        this.$toasted.success('Projekt ' + response.data.title + ' erstellt', { icon: 'fas fa-stream' })
        this.creating = false
        await this.getProjects()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        // update with cover
        if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
          this.$refs.myVueDropzone.processQueue()
        } else {
          // update without cover
          const response = await this.updateProject({ id: this.form.id, data: this.form })
          this.$toasted.success('Projekt ' + response.data.title + ' gespeichert', { icon: 'fas fa-stream' })
          this.$vfm.hide('project-edit')
        }

        this.updating = false
        await this.getProject({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProject({ id: this.form.id })
        this.$vfm.hide('project-edit')
        this.$toasted.success('Projekt gelöscht', { icon: 'fas fa-stream' })
        this.removing = false
        // this.$router.push({name: 'projectList'});
        await this.getProjects()
      } catch (error) {
        this.creating = false
      }
    },
    dropzoneMounted () {
      if (this.form.coverName) {
        this.$refs.myVueDropzone.manuallyAddFile({
          name: this.form.coverName,
          type: this.form.coverMimeType,
          size: this.form.coverSize
        }, process.env.VUE_APP_API_URL + '/files/projects/' + this.form.id + '/' + this.form.coverName)
      }
    },
    async success (file, response) {
      this.$toasted.success('Poster hochgeladen')
      this.form.coverName = response.coverName
      this.form.coverSize = response.coverSize
      this.form.coverMimeType = response.coverMimeType
      await this.updateProject({ id: this.form.id, data: this.form })
      this.$toasted.success('Projekt gespeichert')
      this.$vfm.hide('project-create')
      this.$vfm.hide('project-edit')
      await this.getProject({ id: this.form.id })
    },
    customerContactLabel ({ firstName, lastName }) {
      return `${firstName} ${lastName}`
    },
    ...mapActions({
      createProject: 'projects/create',
      updateProject: 'projects/replace',
      deleteProject: 'projects/destroy',
      getProject: 'projects/fetchSingle',
      getProjects: 'projects/fetchList',
      getUsers: 'users/fetchList',
      getCustomers: 'customers/fetchList'
    })
  },
  computed: {
    customer: {
      get () {
        return this.form.customer
      },
      set (customer) {
        this.form.customer = customer
        this.form.customerContacts = []
      }
    },
    customerContacts () {
      return this.form.customer.customerContacts.filter(customerContact => customerContact.isActive)
    },
    budget: {
      get () {
        return this.form.budget
      },
      set (budget) {
        if (budget !== this.form.budget) {
          this.form.budgetUpdatedAt = moment().format()
        }
        this.form.budget = budget
      }
    },
    checkForm () {
      return !(this.form.title && this.form.category && this.form.customer && this.form.departments)
    },
    ...mapGetters({
      users: 'users/active',
      customers: 'customers/list',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      hasChiefRole: 'auth/hasChiefRole',
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>
