<template>

    <main>
        <BaseTopBar :breadcrump="false" :icon="icon" :title="title"/>
        <dashboard-info/>
        <BaseGrid>
            <div>
                <dashboard-user-info-list/>
                <dashboard-calendar-list/>
            </div>
            <dashboard-project-list/>
            <dashboard-activity-list/>
        </BaseGrid>
    </main>

</template>

<script>
import { mapGetters } from 'vuex'
import DashboardInfo from './dashboardInfo/DashboardInfo.vue'
import DashboardCalendarList from '@/components/dashboard/dashboardCalendar/DashboardCalendarList'
import DashboardProjectList from './dashboardProject/DashboardProjectList.vue'
import DashboardActivityList from '@/components/dashboard/dashboardActivity/DashboardActivityList'
import DashboardUserInfoList from '@/components/dashboard/dashboardUserInfo/DashboardUserInfoList'
import moment from 'moment'

export default {
  name: 'Dashboard',
  components: {
    DashboardUserInfoList,
    DashboardInfo,
    DashboardCalendarList,
    DashboardProjectList,
    DashboardActivityList
  },
  computed: {
    icon () {
      if (moment().hour() < 12) {
        return 'coffee'
      } else if (moment().hour() < 17) {
        return 'user'
      } else {
        return 'moon'
      }
    },
    title () {
      if (moment().hour() < 12) {
        return `Guten Morgen ${this.authUser.firstName}!`
      } else if (moment().hour() < 17) {
        return `Moin ${this.authUser.firstName}!`
      } else {
        return `Guten Abend ${this.authUser.firstName}!`
      }
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasBetaRole: 'auth/hasBetaRole'
    })
  }
}

</script>

<style lang="scss">

.dashboard {
    //margin-top: 80px;
}

.dashboard-grid {
    display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-column-gap: 20px;
    width: 1680px;
    margin: 0 auto;
    padding: 0 20px;
}

.dashboard-grid .dashboard-col.double {
    grid-column: auto / span 2;
}

.dashboard-grid .dashboard-col .dashboard-col-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    height: 60px;
}

.dashboard-grid .dashboard-col .dashboard-col-top .left {
    display: flex;
    align-items: center;
}

.dashboard-grid .dashboard-col .dashboard-col-top .left svg {
    margin-right: 15px;
    color: $text-color;
    font-size: 1.25rem;
}

.dashboard-grid .dashboard-col .dashboard-col-top .left .title {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.25;
    display: flex;
    align-items: center;
}

.dashboard-grid .dashboard-col .dashboard-col-top .left .title span {
    color: $text-color-2;
    margin-left: 5px;
    font-weight: 400;
}

.dashboard-grid .dashboard-col .dashboard-col-top .left .subtitle {
    font-size: 0.875rem;
    color: $text-color-2;
}

.dashboard-grid .dashboard-col .list::-webkit-scrollbar {
    display: none;
}

@media (max-width: 768px) {
    .dashboard-grid .dashboard-col .dashboard-col-top {
        flex-flow: row wrap;
    }
}

#app.dark {

    .dashboard-grid .dashboard-col .dashboard-col-top .left svg {
        color: $gray-500
    }

    .dashboard-grid .dashboard-col .dashboard-col-top .left .title span {
        color: $gray-500
    }

    .dashboard-grid .dashboard-col .dashboard-col-top .left .subtitle {
        color: $gray-500;
    }

}

</style>
