<template>

  <base-modal toptitle="Medienpartner"
              :title="mediacontactPartner.name"
              :subtitle="lastUpdate"
              name="mediacontact-partner-view"
              :width="800"
              v-on="$listeners"
              v-bind="$attrs">

    <div class="middle">

      <div class="group">
        <div v-if="mediacontactPartner.company" class="title">Unternehmen</div>
        <div v-if="mediacontactPartner.company" class="info-item">
          <div class="label">Unternehmen</div>
          <font-awesome-icon icon="building" size="lg"/>
          <div>{{ mediacontactPartner.company.name }}</div>
        </div>
        <div class="title">Medienpartner</div>
        <div v-if="mediacontactPartner.name" class="info-item">
          <div class="label">Name</div>
          <font-awesome-icon icon="layer-group" size="lg"/>
          <div>{{ mediacontactPartner.name }}</div>
        </div>

        <div v-if="mediacontactPartner.description" class="info-item">
          <div class="label">Beschreibung</div>
          <font-awesome-icon icon="sticky-note" size="lg"/>
          <div>{{ mediacontactPartner.description }}</div>
        </div>

        <div class="title">Mediadaten</div>
        <div v-if="mediacontactPartner.uniqueUser" class="info-item">
          <div class="label">UniqueUser</div>
          <font-awesome-icon icon="user-plus" size="lg"/>
          <div>{{ mediacontactPartner.uniqueUser.toLocaleString() }}</div>
        </div>
        <div v-if="mediacontactPartner.visits" class="info-item">
          <div class="label">Visits</div>
          <font-awesome-icon icon="mouse" size="lg"/>
          <div>{{ mediacontactPartner.visits.toLocaleString() }}</div>
        </div>
        <div v-if="mediacontactPartner.printCount" class="info-item">
          <div class="label">Printauflage</div>
          <font-awesome-icon icon="print" size="lg"/>
          {{ mediacontactPartner.printCount.toLocaleString() }}
        </div>
      </div>

      <div class="group">
        <div class="title">Kanäle</div>
        <div v-if="mediacontactPartner.website" class="info-item">
          <div class="label">Website</div>
          <font-awesome-icon icon="link" size="lg"/>
          <a :href="mediacontactPartner.website" target="_blank">{{ mediacontactPartner.website }}</a>
        </div>
        <div v-if="mediacontactPartner.facebookPageLikesCount" class="info-item">
          <div class="label">Facebook</div>
          <font-awesome-icon :icon="['fab', 'facebook']" size="lg"/>
          <div>{{ mediacontactPartner.facebookPageName }}</div>
          <div>{{ mediacontactPartner.facebookPageLikesCount.toLocaleString() }} "Gefällt mir" Angaben
          </div>
        </div>
        <div v-if="mediacontactPartner.instagramProfileFollowerCount" class="info-item">
          <div class="label">Instagram</div>
          <font-awesome-icon :icon="['fab', 'instagram']" size="lg"/>
          <div>{{ mediacontactPartner.instagramProfileName }}</div>
          <div>{{ mediacontactPartner.instagramProfileFollowerCount.toLocaleString() }} Follower</div>
        </div>
        <div v-if="mediacontactPartner.youtubeChannelSubscriberCount" class="info-item">
          <div class="label">YouTube</div>
          <font-awesome-icon :icon="['fab', 'youtube']" size="lg"/>
          <div>{{ mediacontactPartner.youtubeChannelId }}</div>
          <div>{{ mediacontactPartner.youtubeChannelSubscriberCount.toLocaleString() }} Abonnenten</div>
        </div>
      </div>
    </div>

    <div class="action">
      <button class="button button-flat" @click="edit">
        <font-awesome-icon icon="pen" size="sm"/>
        <span>Bearbeiten</span>
      </button>
    </div>

  </base-modal>

</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'MediacontactPartnerView',
  props: {
    mediacontactPartner: Object
  },
  methods: {
    edit () {
      this.$vfm.hide('mediacontact-partner-view')
      this.$vfm.show({ component: 'MediacontactPartnerEdit', bind: { mediacontactPartner: this.mediacontactPartner } })
    }
  },
  computed: {
    lastUpdate () {
      if (!this.updatedBy || !this.updatedAt) return 'Noch nie geändert'

      return `Letzte Änderung von ${this.updatedBy} ${this.updatedAt}`
    },
    updatedBy () {
      if (this.mediacontactPartner.updatedBy) {
        return this.mediacontactPartner.updatedBy.id === this.authUser.id ? 'mir' : this.mediacontactPartner.updatedBy.firstName
      }
      return ''
    },
    updatedAt () {
      return moment(this.mediacontactPartner.updatedAt).fromNow()
    },
    ...mapGetters({
      authUser: 'auth/authUser'
    })
  }
}
</script>
