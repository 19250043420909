<template>
    <div>
        <div class="title">
            <div>Beiträge <span>{{ projectMediacontactPartnerFeatures.length }}</span></div>

          <BaseButton :loading="creating"
                      :disabled="creating"
                      icon="plus"
                      label="Beitrag hinzufügen"
                      @click="create"/>
        </div>
        <div class="table">
            <div class="header">
                <base-list-item>
                    <div class="content">
                        <div class="middle">
                            <div class="col project-mediacontact-partner-feature-type">
                                <font-awesome-icon icon="newspaper" size="sm"/>
                                Art
                            </div>
                            <div class="col project-mediacontact-partner-feature-status">
                                <font-awesome-icon icon="toggle-on" size="sm"/>
                                Status
                            </div>
                            <div class="col project-mediacontact-partner-feature-date">
                                <font-awesome-icon icon="calendar-day" size="sm"/>
                                Datum
                            </div>
                            <div class="col project-mediacontact-partner-feature-link">
                                <font-awesome-icon icon="link" size="sm"/>
                                Link
                            </div>
                            <div class="col project-mediacontact-partner-feature-text">
                                <font-awesome-icon icon="comment-alt" size="sm"/>
                                Anmerkungen
                            </div>
                        </div>
                    </div>
                </base-list-item>
            </div>
            <ProjectMediacontactPartnerFeatureListItem
                v-for="projectMediacontactPartnerFeature in projectMediacontactPartnerFeatures"
                :key="projectMediacontactPartnerFeature.id"
                :projectMediacontactPartner="projectMediacontactPartner"
                :projectMediacontactPartnerFeature="projectMediacontactPartnerFeature"/>
            <not-found-row entity="Beiträge" :show="!projectMediacontactPartnerFeatures.length"/>
        </div>

    </div>

</template>

<script>
import ProjectMediacontactPartnerFeatureListItem from '@/components/project/projectPublicity/projectMediacontactPartner/projectMediacontactPartnerFeature/ProjectMediacontactPartnerFeatureListItem'
import NotFoundRow from '@/components/NotFoundRow'
import { mapActions, mapGetters } from 'vuex'
import { orderBy } from 'lodash'

export default {
  name: 'ProjectMediacontactPartnerFeatureList',
  components: { ProjectMediacontactPartnerFeatureListItem, NotFoundRow },
  data () {
    return {
      creating: false
    }
  },
  props: {
    projectMediacontactPartner: Object
  },
  methods: {
    async create () {
      this.creating = true
      const projectMediacontactPartnerFeature = {
        projectMediacontactPartner: '/project_mediacontact_partners/' + this.projectMediacontactPartner.id,
        type: 'News',
        status: 'Anfrage',
        highlight: false
      }
      await this.createProjectMediacontactPartnerFeature({ data: projectMediacontactPartnerFeature })
      this.creating = false
      this.$toasted.success('Beitrag auf ' + this.projectMediacontactPartner.mediacontactPartner.name + ' hinzugefügt', { icon: 'fas fa-layer-group' })
      await this.getProjectMediacontactPartnerFeatures()
    },
    ...mapActions({
      createProjectMediacontactPartnerFeature: 'projectMediacontactPartnerFeatures/create',
      getProjectMediacontactPartnerFeatures: 'projectMediacontactPartnerFeatures/fetchList'
    })
  },
  computed: {
    projectMediacontactPartnerFeatures () {
      return orderBy(this.projectMediacontactPartnerFeaturesByProjectMediacontactPartner(this.projectMediacontactPartner.id), 'createdAt', 'desc')
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      projectMediacontactPartnerFeaturesByProjectMediacontactPartner: 'projectMediacontactPartnerFeatures/byProjectMediacontactPartner'
    })
  }
}
</script>

<style lang="scss">
.row .content .middle .col.project-mediacontact-partner-feature-type {
    width: 200px;
}

.row .content .middle .col.project-mediacontact-partner-feature-status {
    width: 140px;
}

.row .content .middle .col.project-mediacontact-partner-feature-date {
    width: 140px;
}

.row .content .middle .col.project-mediacontact-partner-feature-link {
    width: 150px;
}

.row .content .middle .col.project-mediacontact-partner-feature-link .input-wrapper-floatinglabel {
    min-width: auto;
}

.row .content .middle .col.project-mediacontact-partner-feature-date .mx-datepicker {
    width: auto;
}

.row .content .middle .col.project-mediacontact-partner-feature-clippings {
    width: 140px;
}

.row .content .middle .col.project-mediacontact-partner-feature-text {
    width: 160px;
}

.row .content .middle .col.project-mediacontact-partner-feature-text .textarea-wrapper {
    min-width: auto;
    height: 50px;
}

</style>
