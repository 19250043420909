<template>

  <base-modal name="project-create"
              title="Projekt erstellen"
              :width="1200"
              v-on="$listeners"
              v-bind="$attrs">
      <project-form :project="project" type="create"></project-form>
  </base-modal>

</template>

<script>
import ProjectForm from '@/components/project/ProjectForm.vue'

export default {
  name: 'ProjectCreate',
  components: { ProjectForm },
  data: () => ({
    project: {
      title: null,
      category: null,
      customer: null,
      customerContacts: [],
      managers: [],
      departments: [],
      concept: false,
      conceptBy: null,
      tbc: false,
      releaseDate: null,
      estDate: null,
      budgetUpdatedAt: null,
      isActive: true
    }
  })
}
</script>
