<template>
  <div>
    <base-list icon="calendar-alt" title="Termine">
      <template #groups>
        <dashboard-calendar-absence-list/>
        <dashboard-calendar-birthday-list/>
        <dashboard-calendar-meeting-list/>
        <dashboard-calendar-event-list/>
      </template>
    </base-list>
  </div>
</template>

<script>
import DashboardCalendarMeetingList from '@/components/dashboard/dashboardCalendar/DashboardCalendarMeetingList'
import DashboardCalendarAbsenceList from '@/components/dashboard/dashboardCalendar/DashboardCalendarAbsenceList'
import DashboardCalendarEventList from '@/components/dashboard/dashboardCalendar/DashboardCalendarEventList'
import DashboardCalendarBirthdayList from '@/components/dashboard/dashboardCalendar/DashboardCalendarBirthdayList'

export default {
  name: 'DashboardCalendarList',
  components: {
    DashboardCalendarBirthdayList,
    DashboardCalendarEventList,
    DashboardCalendarAbsenceList,
    DashboardCalendarMeetingList
  }
}
</script>
