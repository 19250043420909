<template>

  <base-modal name="project-list-mediacontact-material-create"
              title="Material hinzufügen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <project-list-mediacontact-material-form :projectListMediacontactMaterial="projectListMediacontactMaterial"
                                             :projectListMediacontact="projectListMediacontact"
                                             type="create"/>
  </base-modal>

</template>

<script>
import ProjectListMediacontactMaterialForm from './ProjectListMediacontactMaterialForm'

export default {
  name: 'ProjectListMediacontactMaterialCreate',
  components: { ProjectListMediacontactMaterialForm },
  props: {
    projectListMediacontact: Object
  },
  data () {
    return {
      projectListMediacontactMaterial: {
        projectMaterial: null,
        projectListMediacontact: '/project_list_mediacontacts/' + this.projectListMediacontact.id,
        count: null
      }
    }
  }
}
</script>
