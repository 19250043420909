import { render, staticRenderFns } from "./BaseCardItem.vue?vue&type=template&id=6248dc0e"
import script from "./BaseCardItem.vue?vue&type=script&lang=js"
export * from "./BaseCardItem.vue?vue&type=script&lang=js"
import style0 from "./BaseCardItem.vue?vue&type=style&index=0&id=6248dc0e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports