import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import auth from './modules/auth'
import projects from './modules/projects'
import projectBriefings from './modules/projectBriefings'
import projectUploads from './modules/projectUploads'
import projectLists from './modules/projectLists'
import projectListMediacontacts from './modules/projectListMediacontacts'
import projectListMediacontactMaterials from './modules/projectListMediacontactMaterials'
import projectMaterials from './modules/projectMaterials'
import projectMediacontactPartners from './modules/projectMediacontactPartners'
import projectMediacontactPartnerFeatures from './modules/projectMediacontactPartnerFeatures'
import projectMediacontactPartnerFeatureClippings from './modules/projectMediacontactPartnerFeatureClippings'
import projectInfluencers from './modules/projectInfluencers'
import projectAdBudgets from './modules/projectAdBudgets'
import projectAdCampaigns from './modules/projectAdCampaigns'
import projectAdMaterials from './modules/projectAdMaterials'
import projectAccountingDeals from './modules/projectAccountingDeals'
import projectAccountingItems from './modules/projectAccountingItems'
import projectAccountingInvoices from './modules/projectAccountingInvoices'
import materials from './modules/materials'
import customers from './modules/customers'
import customerContacts from './modules/customerContacts'
import mediacontacts from './modules/mediacontacts'
import mediacontactPartnerCategories from './modules/mediacontactPartnerCategories'
import mediacontactProductTypes from './modules/mediacontactProductTypes'
import mediacontactPartners from './modules/mediacontactPartners'
import mediacontactCompanies from './modules/mediacontactCompanies'
import mediacontactRegions from './modules/mediacontactRegions'
import influencers from './modules/influencers'
import influencerAudiences from './modules/influencerAudiences'
import influencerCategories from './modules/influencerCategories'
import influencerSubCategories from './modules/influencerSubCategories'
import influencerNetworks from './modules/influencerNetworks'
import businessContacts from './modules/businessContacts'
import businessContactCompanies from './modules/businessContactCompanies'
import businessContactCategories from './modules/businessContactCategories'
import businessContactTopics from './modules/businessContactTopics'
import news from './modules/news'
import users from './modules/users'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    projects,
    projectBriefings,
    projectUploads,
    projectLists,
    projectListMediacontacts,
    projectListMediacontactMaterials,
    projectMaterials,
    projectMediacontactPartners,
    projectMediacontactPartnerFeatures,
    projectMediacontactPartnerFeatureClippings,
    projectInfluencers,
    projectAdBudgets,
    projectAdCampaigns,
    projectAdMaterials,
    projectAccountingDeals,
    projectAccountingItems,
    projectAccountingInvoices,
    materials,
    customers,
    customerContacts,
    mediacontacts,
    mediacontactProductTypes,
    mediacontactPartnerCategories,
    mediacontactPartners,
    mediacontactCompanies,
    mediacontactRegions,
    influencers,
    influencerAudiences,
    influencerCategories,
    influencerSubCategories,
    influencerNetworks,
    businessContacts,
    businessContactCompanies,
    businessContactCategories,
    businessContactTopics,
    news,
    users
  }
})
