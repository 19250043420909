<template>

  <base-modal name="customer-create"
              title="Kunde erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <customer-form :customer="customer" type="create"></customer-form>
  </base-modal>

</template>

<script>
import CustomerForm from './CustomerForm.vue'

export default {
  name: 'CustomerCreate',
  components: { CustomerForm },
  data: () => ({
    customer: {}
  })
}
</script>
