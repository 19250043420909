<template>

  <base-modal name="business-contact-category-edit"
              toptitle="Kategorie bearbeiten"
              :title="businessContactCategory.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <business-contact-category-form :businessContactCategory="businessContactCategory" type="edit"/>
  </base-modal>

</template>

<script>
import BusinessContactCategoryForm from './BusinessContactCategoryForm.vue'

export default {
  name: 'BusinessContactCategoryEdit',
  components: { BusinessContactCategoryForm },
  props: {
    businessContactCategory: Object
  }
}
</script>
