<template>

  <base-modal name="influencer-audience-edit"
              toptitle="Zielgruppe bearbeiten"
              :title="influencerAudience.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <influencer-audience-form :influencerAudience="influencerAudience" type="edit"/>
  </base-modal>

</template>

<script>
import InfluencerAudienceForm from './InfluencerAudienceForm.vue'

export default {
  name: 'InfluencerAudienceEdit',
  components: { InfluencerAudienceForm },
  props: {
    influencerAudience: Object
  }
}
</script>
