<template>

    <form v-on:submit.prevent="">
        <div class="middle">

            <div class="group">
                <div class="title">Kontakt</div>
                <BaseInputFloatinglabel v-model="form.name"
                                        icon="project-diagram"
                                        placeholder="Name"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.phone"
                                        icon="phone-alt"
                                        placeholder="Telefon"
                                        type="tel"/>
                <BaseInputFloatinglabel v-model="form.website"
                                        icon="link"
                                        placeholder="Website"
                                        type="url"/>

                <div class="title">Anschrift</div>
                <BaseInputFloatinglabel v-model="form.street"
                                        icon="home"
                                        placeholder="Straße"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.zip"
                                        icon="map-marker-alt"
                                        placeholder="PLZ"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.city"
                                        icon="city"
                                        placeholder="Stadt"
                                        type="text"/>
                <BaseInputFloatinglabel v-model="form.country"
                                        icon="flag"
                                        placeholder="Land"
                                        type="text"/>
            </div>

        </div>
        <div class="action">
          <BaseButton v-if="type === 'create'"
                       :loading="creating"
                       :disabled="checkForm || creating"
                       icon="plus"
                       label="Erstellen"
                       :submit="true"
                       @click="create"/>
          <BaseButton v-if="hasManagerRole && type === 'edit'"
                       :loading="removing"
                       :disabled="removing"
                       icon="trash"
                       label="Löschen"
                       :remove="true"
                       @click="remove"/>
          <BaseButton v-if="type === 'edit'"
                       :loading="updating"
                       :disabled="checkForm || updating"
                       icon="save"
                       label="Speichern"
                       :submit="true"
                       @click="update"/>
        </div>

    </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'InfluencerNetworkForm',
  props: ['influencerNetwork', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.influencerNetwork)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        const response = await this.createInfluencerNetwork({ data: this.form })
        this.$vfm.hide('influencer-network-create')
        this.$toasted.success('Influencer Netzwerk ' + response.data.name + ' erstellt', { icon: 'fas fa-project-diagram' })
        this.creating = false
        await this.getInfluencerNetworks()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateInfluencerNetwork({ id: this.form.id, data: this.form })
        this.$vfm.hide('influencer-network-edit')
        this.$toasted.success('Influencer Netzwerk ' + response.data.name + ' gespeichert', { icon: 'fas fa-project-diagram' })
        this.updating = false
        await this.getInfluencerNetwork({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteInfluencerNetwork({ id: this.form.id })
        this.$vfm.hide('influencer-network-edit')
        this.$toasted.success('Influencer Netzwerk gelöscht', { icon: 'fas fa-project-diagram' })
        this.removing = false
        await this.getInfluencerNetworks()
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      createInfluencerNetwork: 'influencerNetworks/create',
      updateInfluencerNetwork: 'influencerNetworks/replace',
      deleteInfluencerNetwork: 'influencerNetworks/destroy',
      getInfluencerNetwork: 'influencerNetworks/fetchSingle',
      getInfluencerNetworks: 'influencerNetworks/fetchList'
    })
  },
  computed: {
    checkForm () {
      return !this.form.name
    },
    ...mapGetters({
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
