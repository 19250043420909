import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'mediacontactPartnerCategories',
  urlRoot: '/mediacontact_partner_categories',
  state: {
    filter: {
      search: ''
    }
  }
})
