<template>

    <div class="row">

        <div class="content">

            <div class="icon">
                <font-awesome-icon :icon="icon" size="lg"/>
            </div>

            <div class="middle">

                <div class="col dashboard-calendar-title">
                    <div class="title">{{ item.event.subject }}</div>
                    <span>{{ subtitle }}</span>
                </div>

            </div>

        </div>

    </div>

</template>

<script>
import moment from 'moment'

export default {
  name: 'DashboardCalendarItem',
  props: {
    item: {}
  },
  methods: {
    day (day) {
      return `${moment.utc(day).local().calendar(null, {
                    sameDay: '[Heute]',
                    nextDay: '[Morgen]',
                    nextWeek: 'dddd',
                    lastDay: '[Gestern]',
                    lastWeek: '[Diesen] dddd',
                    sameElse: 'DD.MM.YYYY'
                })}`
    }
  },
  computed: {
    icon () {
      switch (this.item.category) {
        case 'conferenceRoomBig':
          return 'calendar-day'
        case 'conferenceRoomSmall':
          return 'calendar-day'
        case 'busy':
          return 'user-clock'
        case 'event':
          return 'calendar-day'
        case 'birthday':
          return 'gift'
        default:
          return ''
      }
    },
    subtitle () {
      if (!this.item.event.isAllDay) {
        return `${this.day(this.item.event.start.dateTime)} von ${this.start}-${this.end} Uhr`
      } else {
        return `${this.day(this.item.event.start.dateTime)}`
      }
    },
    start () {
      return moment.utc(this.item.event.start.dateTime).local().format('H:mm')
    },
    end () {
      return moment.utc(this.item.event.end.dateTime).local().format('H:mm')
    }
  }
}
</script>

<style lang="scss">

    section.list .row .content .middle .col.dashboard-calendar-title {
        width: 100%;
    }

</style>
