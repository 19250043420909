<template>

  <base-modal name="project-upload-create"
              title="Datei hochladen"
              :width="400"
              height="auto"
              :adaptive="true"
              :clickToClose="false"
              v-on="$listeners"
              v-bind="$attrs">

    <form v-on:submit.prevent="create">
      <vue-dropzone ref="myVueDropzone" id="dropzone"
                    :options="dropzoneOptions"
                    @vdropzone-sending="sendingEvent"
                    @vdropzone-success="success">
      </vue-dropzone>
    </form>

  </base-modal>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'ProjectUploadCreate',
  components: { vueDropzone: vue2Dropzone },
  data: () => ({
    upload: {
      name: null,
      project: 'projects/' + this.$route.params.projectId
    },
    dropzoneOptions: {
      url: process.env.VUE_APP_API_URL + '/project_uploads',
      thumbnailWidth: 200,
      addRemoveLinks: true,
      autoProcessQueue: true,
      dictDefaultMessage: 'Datei hierherziehen',
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    },
    loading: true
  }),
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.project || await this.getProject({ id: this.$route.params.projectId })
      this.loading = false
    },
    create () {
      this.$refs.myVueDropzone.processQueue()
    },
    sendingEvent (file, xhr, formData) {
      formData.append('project', this.$route.params.projectId)
    },
    async success (file, response) {
      this.$toasted.success(response.name + ' hochgeladen')
      await this.getProjectUploads({ customUrl: '/projects/' + this.$route.params.projectId + '/project_uploads/' })
      // await this.getProject({ id: this.$route.params.projectId });
      this.$vfm.hide('project-upload-create')
    },
    ...mapActions({
      getProject: 'projects/fetchSingle',
      getProjectUploads: 'projectUploads/fetchList'
    })
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    ...mapGetters({
      projectById: 'projects/byId'
    })
  }
}
</script>
