<template>

  <button :class="classObject"
          :type="type"
          @click="click"
          :disabled="disabled">
    <font-awesome-icon :icon="icon"
                       size="sm"
                       v-if="icon && !loading"/>
    <clip-loader :loading="loading"
                 :color="color"
                 size="16px"></clip-loader>
    <span v-if="label">{{ label }}</span>
  </button>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseButton',
  props: {
    icon: String,
    label: String,
    loading: Boolean,
    remove: Boolean,
    submit: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      classObject: {
        button: true,
        'button-flat': true,
        'button-delete': this.remove
      },
      type: this.submit ? 'submit' : 'button'
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  },
  computed: {
    color () {
      return this.darkmodeStatus ? '#424242' : 'rgba(0, 0, 0, 0.38)'
    },
    ...mapGetters({
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>

<style lang="scss">

.button {
  -webkit-appearance: none;
  border: none;
  background: none;
  padding: 10px 8px;
  outline: 0;
  font: inherit;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  border-radius: 5px;
  color: $text-color;
  cursor: pointer;
  transition: 0.3s all ease;
  letter-spacing: 0.5px;
  height: 36px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.button svg {
  color: $text-color;
  font-size: 0.875rem;
}

.button span {
  margin-left: 8px;
}

.button:hover {
  background-color: $gray-200;
}

.button:active {
  background-color: $gray-300;
}

/* Button Light */
.button-light {
  color: $white;
}

.button-light svg {
  color: $white;
}

.button-light:hover {
  background-color: rgba($white, 0.1);
}

.button-light:active {
  background-color: rgba($white, 0.2);
}

/* Button Dark */
.button-dark {
  color: $text-color;
}

.button-dark:hover,
.button-dark:active {
  background-color: $gray-200;
}

/* Disabled */
.button:disabled, .button:disabled svg {
  color: $text-color-3;
  cursor: default;
}

.button:disabled:hover, .button:disabled:active {
  background: none;
}

/* Delete */
.button-delete, .button-delete svg {
  color: $red-800;
}

@media (max-width: 768px) {

  .button span {
    display: none;
  }

  .button svg {
    display: block;
  }

}

#app.dark {

  .button, .button svg {
    color: $gray-50;
  }

  .button:hover {
    background-color: $black-4;
  }

  .button:active {
    background-color: $black-4;
  }

  .button:disabled:hover {
    background: none;
  }

  .button:disabled, .button:disabled svg {
    color: $gray-800;
  }

  /* Delete */
  .button-delete, .button-delete svg {
    color: $red-400;
  }

}

</style>
