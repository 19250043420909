import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectAdCampaigns',
  urlRoot: '/project_ad_campaigns',
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectAdCampaign => projectAdCampaign.project === '/projects/' + id)
    },
    customerNetByProject: (state, getters) => id => {
      return getters.byProject(id)
        .filter(projectAdCampaign => projectAdCampaign.customerNet)
        .map(projectAdCampaign => parseFloat(projectAdCampaign.customerNet))
        .reduce((cur, acc) => cur + acc, 0)
    },
    agencyNetByProject: (state, getters) => id => {
      return getters.byProject(id)
        .filter(projectAdCampaign => projectAdCampaign.agencyNet)
        .map(projectAdCampaign => parseFloat(projectAdCampaign.agencyNet))
        .reduce((cur, acc) => cur + acc, 0)
    },
    amountByProject: (state, getters) => id => {
      return getters.customerNetByProject(id) - getters.agencyNetByProject(id)
    }
  }
})
