<template>

  <base-modal name="influencer-edit"
              toptitle="Influencer bearbeiten"
              :title="influencer.name"
              :width="1200"
              v-on="$listeners"
              v-bind="$attrs">
    <influencer-form :influencer="influencer" type="edit"/>
  </base-modal>

</template>

<script>
import InfluencerForm from './InfluencerForm.vue'

export default {
  name: 'InfluencerEdit',
  components: { InfluencerForm },
  props: {
    influencer: Object
  }
}
</script>
