import axios from 'axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    darkmodeAppSetting: localStorage.getItem('darkmode') ? parseInt(localStorage.getItem('darkmode')) : null,
    darkmodeSystemSetting: window.matchMedia('(prefers-color-scheme: dark)').matches,
    position: {},
    weather: {},
    calendar: {}
  },
  mutations: {
    darkmodeAppSettingEnabled: (state) => {
      state.darkmodeAppSetting = 1
      localStorage.setItem('darkmode', 1)
    },
    darkmodeAppSettingDisabled: (state) => {
      state.darkmodeAppSetting = 0
      localStorage.setItem('darkmode', 0)
    },
    darkmodeAppSettingSystem: (state) => {
      state.darkmodeAppSetting = null
      localStorage.removeItem('darkmode')
    },
    darkmodeSystemSettingActivate: (state) => {
      state.darkmodeSystemSetting = 0
    },
    darkmodeSystemSettingDeactivate: (state) => {
      state.darkmodeSystemSetting = 1
    },
    positionLoaded: (state, payload) => {
      state.position = payload
    },
    weatherLoaded: (state, payload) => {
      state.weather = payload.data
    },
    calendarLoaded: (state, payload) => {
      state.calendar = payload.data
    },
    slackNotificationSend: () => {

    },
    slackNotificationError: () => {

    }
  },
  actions: {
    async loadWeather ({ commit, rootGetters }) {
      try {
        const responseIpStack = await axios.get('https://ipinfo.io/' + rootGetters['auth/authUser'].ip + '/?token=1ee58b6410688b', {
          transformRequest: [(data, headers) => {
            delete headers.common.Authorization
          }]
        })
        if (responseIpStack.data.loc) {
          const latitude = responseIpStack.data.loc.split(',')[0]
          const longitude = responseIpStack.data.loc.split(',')[1]
          const responseWeather = await axios.get('https://api.openweathermap.org/data/2.5/weather?lat=' + latitude + '&lon=' + longitude + '&lang=de&units=metric&APPID=6647c186418a8cedcab16b2b5851f4f4', {
            transformRequest: [(data, headers) => {
              delete headers.common.Authorization
            }]
          })
          commit('weatherLoaded', responseWeather)
        }
      } catch (error) {
        // console.error(error)
      }
    },
    async loadCalendar ({ commit }) {
      const response = await axios.get(process.env.VUE_APP_API_URL + '/fetch/calendar', {
        params: {
          startDateTime: moment.utc().startOf('day').toISOString(),
          endDateTime: moment.utc().endOf('week').toISOString()
        }
      })
      commit('calendarLoaded', response)
    },
    async sendSlackNotification ({ commit }, payload) {
      let color
      let status
      let users
      switch (payload.response.data.status) {
        case 'Idee':
          color = '#ffc107'
          status = `:bulb: ${payload.response.data.status}`
          break
        case 'Gebucht':
          color = '#4caf50'
          status = `:white_check_mark: ${payload.response.data.status}`
          break
        case 'Nicht gebucht':
          color = '#ff5722'
          status = `:x: ${payload.response.data.status}`
          break
      }
      if (payload.response.data.usersInCharge.length) {
        users = payload.response.data.usersInCharge.map(userInCharge => `<@${userInCharge.slackId}>`)
      } else if (payload.response.data.project.managers.length) {
        users = payload.response.data.project.managers.map(user => `<@${user.slackId}>`)
      }
      const data = {
        attachments: [
          {
            color: color,
            pretext: `Ein ${payload.response.data.category} Briefing für <${process.env.VUE_APP_URL}/projekte/${payload.response.data.project.id}|${payload.response.data.project.title}> wurde ${payload.type}! ${users}`,
            title: payload.response.data.name,
            title_link: `${process.env.VUE_APP_URL}/projekte/${payload.response.data.project.id}/übersicht`,
            text: payload.response.data.description,
            fields: [
              {
                title: status
              }
            ],
            footer: payload.response.data.updatedBy.firstName,
            ts: moment(payload.response.data.updatedAt).unix()
          }
        ]
      }
      try {
        const response = await fetch(process.env.VUE_APP_SLACK_URL, {
          method: 'POST',
          body: JSON.stringify(data)
        })
        commit('slackNotificationSend', response)
      } catch (error) {
        commit('slackNotificationError', error)
      }
    }
  },
  getters: {
    darkmodeAppSetting: state => state.darkmodeAppSetting,
    darkmodeStatus: state => state.darkmodeAppSetting !== null ? !!parseInt(state.darkmodeAppSetting) : state.darkmodeSystemSetting,
    weather: state => state.weather,
    calendar: state => state.calendar
  }
}
