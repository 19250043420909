<template>

  <vue-final-modal
    :name="name"
    classes="modal-container"
    content-class="modal-content"
    :content-style="{'max-width': width + 'px'}"
    v-slot="{ close }"
    v-on="$listeners"
    v-bind="$attrs">

    <section class="modal">
      <div class="top">
        <div v-if="toptitle" class="toptitle">{{ toptitle }}</div>
        <div class="title">{{ title }}</div>
        <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
        <div class="close" @click="close">
          <font-awesome-icon icon="times" size="lg"/>
        </div>
      </div>
      <slot/>
    </section>

  </vue-final-modal>

</template>

<script>

export default {
  name: 'BaseModal',
  props: {
    name: String,
    width: {
      type: Number,
      default: 400
    },
    toptitle: String,
    title: String,
    subtitle: String
  }
}
</script>

<style lang="scss">
section.modal {
  margin: 50px 20px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  padding: 20px;
  position: relative;
}

/* Modal > Top */
section.modal .top {
  margin-bottom: 20px;
}

section.modal .top svg {
  margin-right: 5px;
}

section.modal .top .toptitle {
  font-size: 0.875rem;
  color: $text-color-2;
  margin-bottom: 5px;
}

section.modal .top .title {
  font-size: 1.5rem;
  font-weight: 700;
  color: $text-color;
}

section.modal .top .subtitle {
  font-size: 0.875rem;
  color: $text-color-2;
  margin-top: 5px;
}

section.modal .top .close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: $text-color-3;
  cursor: pointer;
  transition: 0.3s all ease;
}

section.modal .top .close:hover {
  color: $text-color;
}

/* Modal > Text */
section.modal .text {
  white-space: pre-line;
  line-height: 1.25;
  font-size: 0.875rem;
}

section.modal .icons {
  line-height: 1.5;
}

/* Modal > Form */
section.modal form {
  min-width: 300px;
}

/* Modal > Multiselect */
section.modal .multiselect {
  margin-bottom: 15px;
}

section.modal .multiselect__placeholder {
  font-size: 1rem;
}

section.modal .multiselect__input,
section.modal .multiselect__single {
  font-size: 1rem;
}

section.modal .multiselect__select {
  height: 44px;
}

section.modal .multiselect__tags {
  height: 44px;
  border: 1px solid $text-color-4;
  padding-top: 11px;
  padding-left: 10px;
}

section.modal .multiselect__tags svg {
  margin-right: 6px;
}

/* Modal > Datetimepicker */
section.modal .mx-datepicker {
  width: 100%;
  margin-bottom: 15px;
}

section.modal .mx-datepicker .mx-input {
  height: 44px;
  border-color: $text-color-4;
  border-radius: 5px;
}

/* Modal > Form > Middle */
section.modal .middle {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

section.modal .middle.extended {
  grid-template-columns: 1fr 3fr;
}

/* Modal > Form > Middle > Group > Title */
section.modal .middle .group .title {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: $text-color-3;
}

section.modal .middle .group .title span {
  color: $text-color-4;
}

section.modal .middle .group .title .button {
  margin-left: auto;
}

section.modal .middle .group .title .button span {
  color: $text-color;
}

/* Modal > Form > Middle > Group > Table */
section.modal .middle .group .table {
  //width: 200px;
  //flex-grow: 1;
}

section.modal .middle .group .table .header .row {
  padding-bottom: 20px;
  border-bottom: 1px solid $gray-200;
  font-weight: 500;
}

section.modal .middle .group .table .row:hover {
  background: none;
}

section.modal .middle .group .table .row {
  padding: 10px 0;
}

section.modal .middle .group .table .row:last-child {
  border-bottom: 1px solid $gray-200;
}

section.modal .middle .group .table .row .col {
  padding-right: 0;
}

section.modal .middle .group .table .row .multiselect {
  margin-bottom: 0;
}

section.modal .middle .group .table .row .mx-datepicker {
  margin-bottom: 0;
}

section.modal .middle .group .table .row .input-wrapper-floatinglabel {
  margin-bottom: 0;
}

/* Modal > Info */
section.modal .info-item {
  position: relative;
  margin-bottom: 16px;
  border: 1px solid $text-color-4;
  border-radius: 5px;
  min-height: 48px;
  padding: 16px 16px 16px 36px;
  white-space: pre-line;
}

section.modal .info-item .label {
  position: absolute;
  left: 32px;
  top: -6px;
  color: $text-color-3;
  font-size: 0.75rem;
  margin-top: 0;
  background-color: $white;
  padding: 0 4px;
}

section.modal .info-item .text {
  white-space: pre-line;
}

section.modal .info-item .subtitle {
  font-size: 0.875rem;
  color: $text-color-2;
}

section.modal .info-item svg {
  position: absolute;
  left: 12px;
  font-size: 0.875rem;
  color: $text-color-2;
}

section.modal .info-item .social-profile {
  display: flex;
  align-items: center;
  padding-left: 16px;
}

section.modal .info-item .social-profile .profile-pic {
  position: absolute;
  left: 12px;
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

/* Modal > Form > Action */
section.modal .action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.vm--overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}

.vm--modal {
  background: none !important;
  box-shadow: none !important;
  overflow: visible !important;
}

#app.dark {

  section.modal {
    background-color: $black-2;
  }

  section.modal .top .toptitle,
  section.modal .top .subtitle {
    color: $gray-500;
  }

  section.modal .top .title {
    color: $white;
  }

  section.modal .top .close {
    color: $gray-500;
  }

  section.modal .top .close:hover {
    color: $white;
  }

  section.modal .middle .group .title {
    color: $white;
  }

  section.modal .middle .group .title .button span {
    color: $white;
  }

  section.modal .middle .info-item {
    border-color: $gray-800;
  }

  section.modal .middle .info-item svg {
    color: $gray-700;
  }

  section.modal .middle .info-item .label {
    background-color: $black-2;
    color: $gray-700;
  }

  section.modal .middle .info-item .subtitle {
    color: $gray-500;
  }

  section.modal .multiselect__tags {
    border-color: $gray-800;
  }

  section.modal .mx-datepicker .mx-input {
    border-color: $gray-800;
  }

}

.modal-container {
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal-content {
  margin: auto;
  flex-grow: 1;
}

</style>
