<template>

  <base-modal name="project-ad-budget-edit"
              toptitle="Budget bearbeiten"
              :title="projectAdBudget.description"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <project-ad-budget-form :projectAdBudget="projectAdBudget" type="edit"></project-ad-budget-form>
  </base-modal>

</template>

<script>
import ProjectAdBudgetForm from './ProjectAdBudgetForm.vue'

export default {
  name: 'ProjectAdBudgetEdit',
  components: { ProjectAdBudgetForm },
  props: {
    projectAdBudget: Object
  }
}
</script>
