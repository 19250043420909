<template>

    <a class="row" :href="app.link" target="_blank">
        <div class="content">
            <div class="icon">
                <font-awesome-icon :icon="app.icon" size="lg" />
            </div>
            <div class="middle">
                <div class="col dashboard-title">
                    {{ app.toptitle }}
                    <div class="title">{{ app.title }}</div>
                    {{ app.subtitle }}
                </div>
            </div>
        </div>
    </a>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardUserInfoListItem',
  props: {
    app: {}
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/authUser'
    })
  }
}
</script>
