<template>

  <form v-on:submit.prevent="">

    <div class="middle">
      <div class="group">
        <div class="title">Titel</div>

        <BaseInputFloatinglabel v-model="form.name"
                                icon="file-image"
                                placeholder="Name"
                                type="text"/>

        <div class="title">Typ & Größe</div>
        <multiselect v-model="form.fileType"
                     :options="fileTypes"
                     placeholder="Dateityp"
                     :show-labels="false">
          <template #placeholder>
            <font-awesome-icon icon="file-image"/>
            <span>Dateityp</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="file-image"/>
            <span>{{ props.option }}</span>
          </template>
        </multiselect>

        <BaseInputFloatinglabel v-model="form.specs"
                                icon="ruler"
                                placeholder="Abmessungen (HxB)"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.ratio"
                                icon="file-image"
                                placeholder="Verhältnis (x:x)"
                                type="text"/>

        <BaseInputFloatinglabel v-model.number="form.size"
                                icon="file-image"
                                placeholder="Größe (KB)"
                                type="number"/>

        <BaseCheckbox :id="form.id"
                      v-model="form.isHtml5"
                      placeholder="HTML5"
                      field="html5"/>
        <BaseCheckbox :id="form.id"
                      v-model="form.isAnimated"
                      placeholder="Animiert"
                      field="animated"/>

      </div>
      <div class="group">
        <div class="title">Zeit</div>
        <BaseInputFloatinglabel v-model.number="form.duration"
                                icon="file-image"
                                placeholder="Dauer (Sek.)"
                                type="number"/>

        <BaseInputFloatinglabel v-model.number="form.framerate"
                                icon="file-image"
                                placeholder="Framerate (FPS)"
                                type="number"/>

        <BaseInputFloatinglabel v-model.number="form.bitrate"
                                icon="file-image"
                                placeholder="Bitrate (KB/s.)"
                                type="number"/>

        <div class="title">Status</div>

        <v-date-picker
          v-model="form.deadline"
          :popover="{ visibility: 'click' }"
          :is-dark="darkmodeStatus"
          color="blue"
          is24hr
          mode="date"
          title-position="left">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="input-wrapper-floatinglabel">
              <font-awesome-icon icon="calendar-day"/>
              <input
                placeholder="Deadline"
                type="text"
                :value="inputValue"
                required
                v-on="inputEvents" />
              <label>
                Deadline
              </label>
            </div>
          </template>
        </v-date-picker>

        <multiselect v-model="form.status"
                     :options="['Offen', 'Erledigt']"
                     placeholder="Status"
                     :show-labels="false">
          <template #placeholder>
            <font-awesome-icon icon="file-image"/>
            <span>Status</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="file-image"/>
            <span>{{ props.option }}</span>
          </template>
        </multiselect>

      </div>

    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>
  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectAdCampaignForm',
  props: {
    projectAdMaterial: Object,
    type: String
  },
  data () {
    return {
      form: {},
      fileTypes: ['JPG', 'PNG', 'GIF', 'HTML-Zip', 'CSS', 'JS', 'SVG', 'H.264', 'QuickTime'],
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.projectAdMaterial)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        this.form.updatedBy = 'users/' + this.authUser.id
        this.form.updatedAt = moment().format()
        const response = await this.createProjectAdCampaign({ data: this.form })
        this.$vfm.hide('project-ad-material-create')
        this.$toasted.success('Werbemittel ' + response.data.name + ' erstellt', { icon: 'fas fa-file-image' })
        this.creating = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        this.form.updatedBy = 'users/' + this.authUser.id
        this.form.updatedAt = moment().format()
        const response = await this.updateProjectAdCampaign({
          id: this.form.id,
          data: this.form
        })
        this.$vfm.hide('project-ad-material-edit')
        this.$toasted.success('Werbemittel ' + response.data.name + ' gespeichert', { icon: 'fas fa-file-image' })
        this.updating = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectAdCampaign({ id: this.form.id })
        this.$toasted.success('Werbemittel gelöscht', { icon: 'fas fa-file-image' })
        this.$vfm.hide('project-ad-material-edit')
        this.removing = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      createProjectAdCampaign: 'projectAdMaterials/create',
      updateProjectAdCampaign: 'projectAdMaterials/replace',
      deleteProjectAdCampaign: 'projectAdMaterials/destroy',
      getProject: 'projects/fetchSingle'
    })
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    isManager () {
      return (this.project && this.project.managers && this.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    checkForm () {
      return !(this.form.name && this.form.status)
    },
    ...mapGetters({
      projectById: 'projects/byId',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>
