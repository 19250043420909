<template>

    <body id="app" :class="{ dark : darkmodeStatus }">
        <router-view/>
        <modals-container></modals-container>
    </body>

</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'App',
  created () {
    document.addEventListener('touchstart', function () {}, true)
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => e.matches && this.darkmodeSystemSettingDeactivate())
    window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', e => e.matches && this.darkmodeSystemSettingActivate())
  },
  methods: {
    ...mapMutations({
      darkmodeSystemSettingActivate: 'app/darkmodeSystemSettingActivate',
      darkmodeSystemSettingDeactivate: 'app/darkmodeSystemSettingDeactivate'
    })
  },
  computed: {
    ...mapGetters({
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>
