<template>

  <base-modal name="influencer-network-edit"
              toptitle="Netzwerk bearbeiten"
              :title="influencerNetwork.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <influencer-network-form :influencerNetwork="influencerNetwork"
                             type="edit"/>
  </base-modal>

</template>

<script>
import InfluencerNetworkForm from './InfluencerNetworkForm.vue'

export default {
  name: 'InfluencerNetworkEdit',
  components: { InfluencerNetworkForm },
  props: {
    influencerNetwork: Object
  }
}
</script>
