<template>

  <base-modal name="influencer-print"
              title="Einzeldruck"
              subtitle="Änderungen werden nicht gespeichert"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <form v-on:submit.prevent="">
      <BaseInputFloatinglabel v-model="form.name"
                              icon="grin-stars"
                              placeholder="Name"
                              type="text"/>
      <BaseInputFloatinglabel v-model="form.asp"
                              icon="user"
                              placeholder="Ansprechpartner"
                              type="text"/>
      <BaseInputFloatinglabel v-model="form.street"
                              icon="home"
                              placeholder="Straße"
                              type="text"/>
      <BaseInputFloatinglabel v-model="form.zip"
                              placeholder="PLZ"
                              icon="map-marker-alt"
                              type="text"/>
      <BaseInputFloatinglabel v-model="form.city"
                              placeholder="Stadt"
                              icon="city"
                              type="text"/>
      <BaseInputFloatinglabel v-model="form.country"
                              placeholder="Land"
                              icon="flag"
                              type="text"/>
      <div class="action">
        <BaseButton :loading="printing"
                    :disabled="checkForm || printing"
                    icon="print"
                    label="Drucken"
                    :submit="true"
                    @click="printMediapartner"/>
      </div>
    </form>
  </base-modal>

</template>

<script>
import print from 'print-js'
import axios from 'axios'
import { cloneDeep } from 'lodash'

export default {
  name: 'MediapartnerPrint',
  props: {
    influencer: Object
  },
  data () {
    return {
      printing: false,
      form: {}
    }
  },
  created () {
    this.form = cloneDeep(this.influencer)
  },
  methods: {
    async printMediapartner () {
      this.printing = true
      const response = await axios.post(process.env.VUE_APP_API_URL + '/print/influencer', { influencer: this.form }, { responseType: 'blob' })
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileUrl = window.URL.createObjectURL(file)
      print(fileUrl)
      this.printing = false
    }
  },
  computed: {
    checkForm () {
      return !(this.form.name && this.form.asp && this.form.street && this.form.zip && this.form.city && this.form.country)
    }
  }
}
</script>
