import Vue from 'vue'
import axios from 'axios'

export default function createCrudModule ({ urlRoot, state = {}, getters = {} }) {
  return {
    namespaced: true,
    state: {
      entities: {},
      list: [],
      ...state
    },
    mutations: {
      fetchListSuccess (state, payload) {
        payload.data.forEach(m => {
          // state.entities[m.id] = m
          Vue.set(state.entities, m.id, m)
        })
        state.list = payload.data.map(m => m.id)
      },
      fetchSingleSuccess (state, payload) {
        // state.entities[payload.data.id] = payload.data
        Vue.set(state.entities, payload.data.id, payload.data)
      },
      createSuccess (state, payload) {
        // state.entities[payload.data.id] = payload.data
        Vue.set(state.entities, payload.data.id, payload.data)
        state.list.push(payload.data.id)
      },
      replaceSuccess (state, payload) {
        // state.entities[payload.data.id] = payload.data
        Vue.set(state.entities, payload.data.id, payload.data)
      },
      destroySuccess (state, id) {
        // state.entities[payload.data.id] = payload.data
        const listIndex = state.list.indexOf(id)
        if (listIndex >= 0) {
          Vue.delete(state.list, listIndex)
        }
        Vue.delete(state.entities, id)
      }
    },
    actions: {
      async fetchList ({ commit }, { customUrl } = {}) {
        try {
          const response = await axios.get(customUrl ?? urlRoot)
          commit('fetchListSuccess', response)
          return Promise.resolve(response)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      async fetchSingle ({ commit }, { id }) {
        try {
          const response = await axios.get(urlRoot + '/' + id)
          commit('fetchSingleSuccess', response)
          return Promise.resolve(response)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      async create ({ commit }, { data }) {
        try {
          const response = await axios.post(urlRoot, data)
          commit('createSuccess', response)
          return Promise.resolve(response)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      async replace ({ commit }, { id, data }) {
        try {
          const response = await axios.put(urlRoot + '/' + id, data)
          commit('replaceSuccess', response)
          return Promise.resolve(response)
        } catch (error) {
          return Promise.reject(error)
        }
      },
      async destroy ({ commit }, { id }) {
        try {
          const response = await axios.delete(urlRoot + '/' + id)
          commit('destroySuccess', id)
          return Promise.resolve(response)
        } catch (error) {
          return Promise.reject(error)
        }
      }
    },
    getters: {
      list (state) {
        return state.list.map(id => state.entities[id])
      },
      byId: state => id => {
        return state.entities[id]
      },
      ...getters
    }
  }
}
