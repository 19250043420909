<template>

  <base-modal name="project-ad-material-edit"
              toptitle="Werbemittel bearbeiten"
              :title="projectAdMaterial.name"
              :width="800"
              v-on="$listeners"
              v-bind="$attrs">
    <project-ad-material-form :projectAdMaterial="projectAdMaterial" type="edit"></project-ad-material-form>
  </base-modal>

</template>

<script>
import ProjectAdMaterialForm from './ProjectAdMaterialForm.vue'

export default {
  name: 'ProjectAdMaterialEdit',
  components: { ProjectAdMaterialForm },
  props: {
    projectAdMaterial: Object
  }
}
</script>
