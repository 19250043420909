<template>

  <form v-on:submit.prevent="">

    <div class="middle">
      <div class="group">
        <div class="title">Name</div>
        <BaseInputFloatinglabel v-model="form.name"
                                placeholder="Name"
                                icon="building"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.fullName"
                                placeholder="Firmierung"
                                icon="building"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.website"
                                placeholder="Website"
                                icon="link"
                                type="text"/>

        <div class="title">Anschrift</div>
        <BaseInputFloatinglabel v-model="form.street"
                                placeholder="Straße"
                                icon="home"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.zip"
                                placeholder="PLZ"
                                icon="map-marker-alt"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.city"
                                placeholder="Stadt"
                                icon="city"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.country"
                                placeholder="Land"
                                icon="flag"
                                type="text"/>
      </div>
    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="hasChiefRole && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'CustomerForm',
  props: ['customer', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.customer)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        const response = await this.createCustomer({ data: this.form })
        this.$vfm.hide('customer-create')
        this.$toasted.success('Kunde ' + response.data.name + ' erstellt', { icon: 'fas fa-building' })
        this.creating = false
        await this.getCustomers()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateCustomer({ id: this.form.id, data: this.form })
        this.$vfm.hide('customer-edit')
        this.$toasted.success('Kunde ' + response.data.name + ' gespeichert', { icon: 'fas fa-building' })
        this.updating = false
        await this.getCustomer({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteCustomer({ id: this.form.id })
        this.$vfm.hide('customer-edit')
        this.$toasted.success('Kunde gelöscht', { icon: 'fas fa-building' })
        this.removing = false
        await this.getCustomers()
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      createCustomer: 'customers/create',
      updateCustomer: 'customers/replace',
      deleteCustomer: 'customers/destroy',
      getCustomer: 'customers/fetchSingle',
      getCustomers: 'customers/fetchList'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.name && this.form.fullName)
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasChiefRole: 'auth/hasChiefRole'
    })
  }

}
</script>
