<template>

    <form v-on:submit.prevent="">

        <multiselect v-model="form.category"
                     :options="['Social Media', 'Marketing', 'Werbemittel', 'Online PR', 'Offline PR', 'Special']"
                     :show-labels="false"
                     placeholder="Kategorie">
            <template #placeholder>
                <font-awesome-icon icon="clipboard-check"/>
                <span>Kategorie</span>
            </template>
            <template #singleLabel="props">
                <font-awesome-icon icon="clipboard-check"/>
                <span>{{ props.option }}</span>
            </template>
        </multiselect>

        <BaseInputFloatinglabel v-model="form.name"
                                icon="clipboard-check"
                                placeholder="Titel"
                                type="text"/>

        <BaseTextarea v-model="form.description"
                      icon="sticky-note"
                      placeholder="Inhalt"/>

        <multiselect v-model="form.usersInCharge"
                     :hide-selected="true"
                     :options="this.users"
                     :show-labels="false"
                     label="firstName"
                     multiple
                     placeholder="Verantwortliche"
                     track-by="id">
            <template #placeholder>
                <font-awesome-icon icon="users"/>
                <span>Verantwortliche</span>
            </template>
        </multiselect>

        <multiselect v-model="form.status"
                     :options="['Idee', 'Gebucht', 'Nicht gebucht']"
                     :show-labels="false"
                     placeholder="Status">
            <template #placeholder>
                <font-awesome-icon icon="check-circle"/>
                <span>Status</span>
            </template>
            <template #singleLabel="props">
                <font-awesome-icon icon="check-circle"/>
                <span>{{ props.option }}</span>
            </template>
        </multiselect>

        <div class="action">
          <BaseButton v-if="type === 'create'"
                       :loading="creating"
                       :disabled="checkForm || creating"
                       icon="plus"
                       label="Erstellen"
                       :submit="true"
                       @click="create"/>
          <BaseButton v-if="isManager && type === 'edit'"
                       :loading="removing"
                       :disabled="removing"
                       icon="trash"
                       label="Löschen"
                       :remove="true"
                       @click="remove"/>
          <BaseButton v-if="type === 'edit'"
                       :loading="updating"
                       :disabled="checkForm || updating"
                       icon="save"
                       label="Speichern"
                       :submit="true"
                       @click="update"/>
        </div>

    </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectBriefingForm',
  props: {
    project: Object,
    projectBriefing: Object,
    type: {
      type: String,
      default: 'create'
    }
  },
  data () {
    return {
      form: {
        category: null,
        project: {
          managers: []
        }
      },
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.fetchData()
    this.form = cloneDeep(this.projectBriefing)
  },
  methods: {
    async fetchData () {
      this.users.length || await this.getUsers()
      this.loading = false
    },
    async create () {
      try {
        this.creating = true
        this.form.project = '/projects/' + this.project.id
        const response = await this.createProjectBriefing({ data: this.form })
        this.$vfm.hide('project-briefing-create')
        this.$toasted.success('Briefing ' + response.data.name + ' erstellt', { icon: 'fas fa-clipboard-check' })
        this.creating = false
        await this.getProjectBriefings()
        await this.getProject({ id: this.project.id })
        await this.sendSlackNotification({ type: 'erstellt', response })
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateProjectBriefing({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-briefing-edit')
        this.$toasted.success('Briefing ' + response.data.name + ' gespeichert', { icon: 'fas fa-clipboard-check' })
        this.updating = false
        await this.getProjectBriefings({ url: '/projects/' + this.project.id + '/project_briefings' })
        await this.getProject({ id: this.project.id })
        await this.sendSlackNotification({ type: 'aktualisiert', response })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectBriefing({ id: this.form.id })
        this.$vfm.hide('project-briefing-edit')
        this.$toasted.success('Briefing gelöscht', { icon: 'fas fa-clipboard-check' })
        this.removing = false
        await this.getProjectBriefings()
        await this.getProject({ id: this.project.id })
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      createProjectBriefing: 'projectBriefings/create',
      updateProjectBriefing: 'projectBriefings/replace',
      deleteProjectBriefing: 'projectBriefings/destroy',
      getProjectBriefings: 'projectBriefings/fetchList',
      getProject: 'projects/fetchSingle',
      getUsers: 'users/fetchList',
      sendSlackNotification: 'app/sendSlackNotification'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.category && this.form.status)
    },
    isManager () {
      return (this.form.project && this.form.project.managers && this.form.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      users: 'users/active'
    })
  }
}
</script>
