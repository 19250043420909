<template>

    <base-modal name="project-briefing-edit"
                toptitle="Briefing bearbeiten"
                :title="projectBriefing.name"
                :width="400"
                v-on="$listeners"
                v-bind="$attrs">
            <project-briefing-form :projectBriefing="projectBriefing" type="edit"/>
    </base-modal>

</template>

<script>
import ProjectBriefingForm from './ProjectBriefingForm.vue'

export default {
  name: 'ProjectBriefingEdit',
  components: { ProjectBriefingForm },
  props: {
    projectBriefing: Object
  }
}
</script>
