<template>

  <base-modal name="project-list-mediacontact-material-edit"
              toptitle="Material bearbeiten"
              :title="projectListMediacontactMaterial.projectMaterial.material.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <project-list-mediacontact-material-form :projectListMediacontactMaterial="projectListMediacontactMaterial"
                                             :projectListMediacontact="projectListMediacontact"
                                             type="edit"/>
  </base-modal>

</template>

<script>
import ProjectListMediacontactMaterialForm from './ProjectListMediacontactMaterialForm.vue'

export default {
  name: 'ProjectListMediacontactMaterialEdit',
  components: { ProjectListMediacontactMaterialForm },
  props: {
    projectListMediacontact: Object,
    projectListMediacontactMaterial: Object
  }
}
</script>
