<template>
    <div v-if="show">
        <div class="row-skeleton" v-for="n in 1" :key="n">
            <div class="left">
                <div class="icon"></div>
                <div class="col">
                    <span></span>
                    <div class="title"></div>
                    <span></span>
                </div>
            </div>
            <div class="right">
                <span></span>
            </div>
        </div>
    </div>

</template>

<script>
export default {
  name: 'SkeletonRow',
  props: ['show']
}
</script>

<style lang="scss">
    $animation-duration: 1.5s;
    $avatar-offset: 52 + 16;

    @mixin background-gradient {
        background-image: linear-gradient(90deg, $gray-300 0px, $gray-200 40px, $gray-300 80px);
        background-size: 600px;
    }

    @keyframes shine-lines {
        0% {
            background-position: -100px
        }
        40%, 100% {
            background-position: 140px;
        }
    }

    @keyframes shine-avatar {
        0% {
            background-position: -100px + $avatar-offset
        }
        40%, 100% {
            background-position: 140px + $avatar-offset
        }
    }

    .row-skeleton {
        border-bottom: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 5px 20px 5px 15px;
        transition: 0.3s all ease;
        border-bottom: 1px solid $gray-200;
        height: 70px;
        background-color: $white;
    }

    .row-skeleton:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .row-skeleton:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom: none;
    }

    .row-skeleton .left {
        display: flex;
        align-items: center;
    }

    .row-skeleton .left .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        @include background-gradient;
        animation: shine-avatar $animation-duration infinite linear;
    }

    .row-skeleton .left .col {
        margin-left: 15px;
    }

    .row-skeleton .left .col span {
        display: block;
        height: 10px;
        width: 70px;
        border-radius: 2px;
        @include background-gradient;
        animation: shine-lines $animation-duration infinite linear;
    }

    .row-skeleton .left .col span:first-child {
        width: 100px;
    }

    .row-skeleton .left .col .title {
        height: 15px;
        width: 150px;
        margin: 5px 0;
        border-radius: 2px;
        @include background-gradient;
        animation: shine-lines $animation-duration infinite linear
    }

    .row-skeleton .right span {
        display: block;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        @include background-gradient;
        animation: shine-lines $animation-duration infinite linear
    }

    #app.dark {

        @mixin background-gradient {
            background-image: linear-gradient(90deg, $black-4 0px, $black-5 40px, $black-4 80px);
            background-size: 600px;
        }

        .row-skeleton {
            background-color: $black-2;
            border-color: $black-4;
        }

        .row-skeleton .left .icon {
            @include background-gradient;
        }

        .row-skeleton .left .col span {
            @include background-gradient;
        }

        .row-skeleton .left .col .title {
            @include background-gradient;
        }

        .row-skeleton .right span {
            @include background-gradient;
        }

    }

</style>
