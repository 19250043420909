<template>

  <form v-on:submit.prevent="">
    <multiselect v-model="form.material"
                 :options="materials"
                 label="name"
                 track-by="id"
                 placeholder="Material"
                 :taggable="true"
                 @tag="addMaterial"
                 :show-labels="false">

      <template #placeholder>
        <font-awesome-icon icon="tags"/>
        <span>Material</span>
      </template>
      <template #singleLabel="props">
        <font-awesome-icon icon="gift"/>
        <span>{{ props.option.name }}</span>
      </template>
    </multiselect>

    <BaseInputFloatinglabel v-model.number="form.count"
                            icon="times"
                            placeholder="Menge"
                            type="text"/>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectMaterialForm',
  props: ['projectMaterial', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.fetchData()
    this.form = cloneDeep(this.projectMaterial)
  },
  methods: {
    async fetchData () {
      this.materials.length || await this.getMaterials()
    },
    async create () {
      try {
        this.creating = true
        const response = await this.createProjectMaterial({ data: this.form })
        this.$vfm.hide('project-material-create')
        this.$toasted.success(response.data.count + ' ' + response.data.material.name + ' hinzugefügt', { icon: 'fas fa-gift' })
        this.creating = false
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateProjectMaterial({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-material-edit')
        this.$toasted.success('Material ' + response.data.material.name + ' gespeichert', { icon: 'fas fa-gift' })
        this.updating = false
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectMaterial({ id: this.form.id })
        this.$vfm.hide('project-material-edit')
        this.$toasted.success('Material gelöscht', { icon: 'fas fa-gift' })
        this.removing = false
      } catch (error) {
        this.removing = false
      }
    },
    addMaterial (value) {
      const material = { name: value }
      this.materials.push(material)
      this.form.material = material
    },
    ...mapActions({
      getMaterials: 'materials/fetchList',
      createProjectMaterial: 'projectMaterials/create',
      updateProjectMaterial: 'projectMaterials/replace',
      deleteProjectMaterial: 'projectMaterials/destroy',
      getProjectMaterials: 'projectMaterials/fetchList',
      getProject: 'projects/fetchSingle'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.material && this.form.count)
    },
    isManager () {
      return (this.form.project && this.form.project.managers && this.form.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    ...mapGetters({
      materials: 'materials/list',
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
