<template>

  <form v-on:submit.prevent="">

    <BaseInputFloatinglabel v-model="form.name"
                            icon="tags"
                            placeholder="Name"
                            type="text"/>
    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="hasManagerRole && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'InfluencerCategoryForm',
  props: ['influencerCategory', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.influencerCategory)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        const response = await this.createInfluencerCategory({ data: this.form })
        this.$vfm.hide('influencer-category-create')
        this.$toasted.success('Influencer Kategorie ' + response.data.name + ' erstellt', { icon: 'fas fa-tags' })
        this.creating = false
        await this.getInfluencerCategories()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateInfluencerCategory({ id: this.form.id, data: this.form })
        this.$vfm.hide('influencer-category-edit')
        this.$toasted.success('Influencer Kategorie ' + response.data.name + ' gespeichert', { icon: 'fas fa-tags' })
        this.updating = false
        await this.getInfluencerCategory({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteInfluencerCategory({ id: this.form.id })
        this.$vfm.hide('influencer-category-edit')
        this.$toasted.success('Influencer Kategorie gelöscht', { icon: 'fas fa-tags' })
        this.removing = false
        await this.getInfluencerCategories()
      } catch (error) {
        this.removing = false
      }
    },
    addCategory (category) {
      this.form.influencerSubCategories.push({
        name: category
      })
    },
    ...mapActions({
      createInfluencerCategory: 'influencerCategories/create',
      updateInfluencerCategory: 'influencerCategories/replace',
      deleteInfluencerCategory: 'influencerCategories/destroy',
      getInfluencerCategory: 'influencerCategories/fetchSingle',
      getInfluencerCategories: 'influencerCategories/fetchList'
    })
  },
  computed: {
    checkForm () {
      return !this.form.name
    },
    options () {
      return this.form.influencerSubCategories || []
    },
    ...mapGetters({
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
