<template>

    <section class="list">

        <div class="top" v-if="title || subtitle">
            <div class="title" v-if="title">
                <font-awesome-icon :icon="icon" size="sm" :style="{ color: iconColor }"/>
                {{ title }}
                <span>{{ count }}</span>
            </div>

            <div class="subtitle" v-if="subtitle">
                <font-awesome-icon :icon="icon" size="sm" :style="{ color: iconColor }"/>
                {{ subtitle }}
                <span>{{ count }}</span>
            </div>
            <div class="action">
                <slot name="action"/>
            </div>
        </div>

        <div class="groups">
            <slot name="groups"/>
        </div>

        <div class="wrapper" v-if="hasDefaultSlot">
            <slot/>
        </div>
    </section>

</template>

<script>
export default {
  name: 'BaseList',
  props: {
    icon: String,
    iconColor: String,
    title: String,
    subtitle: String,
    count: Number
  },
  computed: {
    hasDefaultSlot () {
      return !!this.$slots.default
    }
  }
}
</script>

<style lang="scss">

    section.list {
        margin: 20px auto;
        padding: 0 20px;
        max-width: 1680px;
    }

    section.list .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
        height: 50px;
        padding: 0 15px;
    }

    section.list .top .title {
        font-size: 1.25rem;
    }

    section.list .top .title svg,
    section.list .top .subtitle svg {
        margin-right: 5px;
        color: $text-color;
    }

    section.list .top .title span,
    section.list .top .subtitle span {
        font-weight: 400;
        color: $text-color-2;
        margin-left: 2px;
    }

    section.list .top .action {
      display: flex;
      margin-left: auto;
    }

    section.list .wrapper {
        color: $text-color-2;
        font-size: 0.875rem;
        box-shadow: 0 0 5px $text-color-4;
        border-radius: 10px;
        background-color: $white;
        overflow: hidden;
    }

    section.list .cards {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(350px,1fr));
        grid-gap: 10px;
    }

    #app.dark {

        section.list .top .title span {
            color: $gray-500;
        }

        section.list .top svg {
            color: $white;
        }

        section.list .wrapper {
            color: $gray-50;
            background-color: $black-2;
        }

    }

</style>
