import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectMediacontactPartnerFeatures',
  urlRoot: '/project_mediacontact_partner_features',
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectMediacontactPartner => projectMediacontactPartner.project === '/projects/' + id)
    },
    byProjectMediacontactPartner: (state) => (id) => {
      return Object.values(state.entities).filter(projectMediacontactPartnerFeature => projectMediacontactPartnerFeature.projectMediacontactPartner === '/project_mediacontact_partners/' + id)
    }
    // groupedByProject: (state, getters) => (id) => {
    //   const projectMediacontactPartnerByMediacontactPartner = {}
    //   getters.byProject(id).forEach(projectMediacontactPartner => {
    //     if (!projectMediacontactPartnerByMediacontactPartner[projectMediacontactPartner.mediacontactPartner.name]) {
    //       projectMediacontactPartnerByMediacontactPartner[projectMediacontactPartner.mediacontactPartner.name] = {}
    //       projectMediacontactPartnerByMediacontactPartner[projectMediacontactPartner.mediacontactPartner.name].mediacontactPartner = projectMediacontactPartner.mediacontactPartner
    //       projectMediacontactPartnerByMediacontactPartner[projectMediacontactPartner.mediacontactPartner.name].projectMediacontactPartners = []
    //     }
    //     projectMediacontactPartnerByMediacontactPartner[projectMediacontactPartner.mediacontactPartner.name].projectMediacontactPartners.push(projectMediacontactPartner)
    //   })
    //   return projectMediacontactPartnerByMediacontactPartner
    // }
  }
})
