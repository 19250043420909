<template>

  <form v-on:submit.prevent="">

    <div class="middle">
      <div class="group">
        <div class="title">Kontaktdaten</div>
        <BaseInputFloatinglabel v-model="form.firstName"
                                icon="user-alt"
                                placeholder="Vorname"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.lastName"
                                icon="user-alt"
                                placeholder="Nachname"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.email"
                                icon="envelope"
                                placeholder="E-Mail"
                                type="email"/>

        <BaseInputFloatinglabel v-model="form.plainPassword"
                                icon="key"
                                placeholder="Passwort"
                                type="password"/>

        <BaseInputFloatinglabel
          v-if="authUser && authUser.roles.some(role => role === 'ROLE_CHIEF' || role === 'ROLE_ADMIN')"
          v-model="form.phone"
          icon="phone-alt"
          placeholder="Durchwahl"
          type="number"/>

        <BaseInputFloatinglabel v-model="form.mobile"
                                icon="mobile-alt"
                                placeholder="Handynummer"
                                type="tel"/>

        <BaseInputFloatinglabel
          v-if="authUser && authUser.roles.some(role => role === 'ROLE_CHIEF' || role === 'ROLE_ADMIN')"
          v-model="form.slackId"
          :icon="['fab', 'slack']"
          placeholder="Slack"
          type="text"/>

      </div>

      <div class="group">
        <div class="title">Rollen & Berechtigung</div>

        <BaseInputFloatinglabel
          v-if="authUser && authUser.roles.some(role => role === 'ROLE_CHIEF' || role === 'ROLE_ADMIN')"
          v-model="form.position"
          icon="sitemap"
          placeholder="Position"
          type="text"/>

        <multiselect
          v-if="authUser && authUser.roles.some(role => role === 'ROLE_CHIEF' || role === 'ROLE_ADMIN')"
          v-model="form.teams"
          :hide-selected="true"
          :options="['Azubi', 'Social Media', 'Marketing', 'Online PR', 'Offline PR', 'Web Development', 'Geschäftsführung']"
          :show-labels="false"
          multiple
          placeholder="Team">
          <template #placeholder>
            <font-awesome-icon icon="users"/>
            <span>Team</span>
          </template>
        </multiselect>

        <multiselect
          v-if="authUser && authUser.roles.some(role => role === 'ROLE_CHIEF' || role === 'ROLE_ADMIN')"
          v-model="form.roles"
          :custom-label="customRoleLabel"
          :hide-selected="true"
          :options="['ROLE_TRAINEE', 'ROLE_USER', 'ROLE_MANAGER', 'ROLE_CHIEF', 'ROLE_ADMIN', 'ROLE_BETA', 'ROLE_NEARBY']"
          :show-labels="false"
          multiple
          placeholder="Berechtigungen">
          <template #placeholder>
            <font-awesome-icon icon="key"/>
            <span>Berechtigungen</span>
          </template>
        </multiselect>

        <vue-dropzone id="dropzone"
                      ref="userDropzone"
                      :options="dropzoneOptions"
                      @vdropzone-mounted="dropzoneMounted"
                      @vdropzone-success="success">
        </vue-dropzone>

        <BaseCheckbox v-if="hasChiefRole"
                      v-model="form.isActive"
                      :id="form.id"
                      field="isActive"
                      placeholder="Aktiv"/>

      </div>
    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="hasChiefRole && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import { cloneDeep } from 'lodash'

export default {
  name: 'UserForm',
  components: { vueDropzone: vue2Dropzone },
  props: ['user', 'type'],
  data () {
    return {
      form: {
        id: null
      },
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + '/users/' + this.user.id + '/picture',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        autoProcessQueue: false,
        dictDefaultMessage: 'Foto hierherziehen',
        maxFiles: 1,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Cache-Control': '',
          'X-Requested-With': ''
        },
        init () {
          this.on('addedfiles', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
          this.on('maxfilesexceeded', function (file) {
            this.removeAllFiles()
            this.addFile(file)
          })
        }
      },
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.user)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        const response = await this.createUser({ data: this.form })
        this.form = response.data
        if (this.$refs.userDropzone.getQueuedFiles().length > 0) {
          this.$refs.userDropzone.setOption('url', process.env.VUE_APP_API_URL + '/users/' + this.form.id + '/picture')
          this.$refs.userDropzone.processQueue()
        } else {
          this.$vfm.hide('user-create')
          this.$toasted.success('Mitarbeiter ' + response.data.firstName + ' ' + response.data.lastName + ' erstellt', { icon: 'fas fa-user' })
          this.creating = false
          await this.getUsers()
        }
      } catch (error) {
        this.creating = false
        this.$toasted.error(error)
      }
    },
    async update () {
      try {
        this.updating = true
        if (this.$refs.userDropzone.getQueuedFiles().length > 0) {
          this.$refs.userDropzone.processQueue()
        } else {
          const response = await this.updateUser({ id: this.form.id, data: this.form })
          this.$vfm.hide('user-edit')
          this.$toasted.success('Mitarbeiter ' + response.data.firstName + ' ' + response.data.lastName + ' gespeichert', { icon: 'fas fa-user' })
          this.updating = false
          await this.getUser({ id: this.form.id })
        }
      } catch (error) {
        this.updating = false
        this.$toasted.error(error)
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteUser({ id: this.form.id })
        this.$toasted.success('Mitarbeiter gelöscht', { icon: 'fas fa-user' })
        this.$vfm.hide('user-edit')
        this.removing = false
        await this.getUsers()
      } catch (error) {
        this.removing = false
        this.$toasted.error(error)
      }
    },
    dropzoneMounted () {
      if (this.form.pictureName) {
        this.$refs.userDropzone.manuallyAddFile({
          name: this.form.pictureName,
          type: this.form.pictureMimeType,
          size: this.form.pictureSize
        }, process.env.VUE_APP_API_URL + '/files/users/' + this.form.id + '/' + this.form.pictureName)
      }
    },
    async success (file, response) {
      this.$toasted.success('Bild hochgeladen')
      this.form.pictureName = response.pictureName
      this.form.pictureSize = response.pictureSize
      this.form.pictureMimeType = response.pictureMimeType
      await this.updateUser({ id: this.form.id, data: this.form })
      this.$toasted.success('Benutzer gespeichert')
      this.$vfm.hide('user-create')
      this.$vfm.hide('user-edit')
      await this.getUser({ id: this.form.id })
    },
    customRoleLabel (role) {
      switch (role) {
        case 'ROLE_ADMIN':
          return 'Administrator'
        case 'ROLE_CHIEF':
          return 'Geschäftsführer'
        case 'ROLE_MANAGER':
          return 'Manager'
        case 'ROLE_USER':
          return 'Mitarbeiter'
        case 'ROLE_TRAINEE':
          return 'Azubi'
        case 'ROLE_BETA':
          return 'Beta Features'
        case 'ROLE_NEARBY':
          return 'In der Nähe'
      }
    },
    ...mapActions({
      createUser: 'users/create',
      updateUser: 'users/replace',
      deleteUser: 'users/destroy',
      getUser: 'users/fetchSingle',
      getUsers: 'users/fetchList'
    })
  },
  computed: {
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.form.email)
    },
    checkForm () {
      return !(this.form.firstName && this.form.lastName && this.validEmail)
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasChiefRole: 'auth/hasChiefRole'
    })
  }
}
</script>
