<template>

    <div class="row">

        <div class="content">
            <div class="icon">
                <font-awesome-icon :icon="icon" size="lg"/>
            </div>
            <div class="middle">
                <div class="col dashboard-activity-title" @click="link">
                    <div>{{ topMessage }}</div>
                    <div class="title">{{ title }}</div>
                    <div>{{ subMessage }}</div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'DashboardAcitvityItem',
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    link () {
      switch (this.source.activityCategory) {
        case 'mediacontact':
          this.$vfm.show({ component: 'MediacontactView', bind: { mediacontact: this.source } })
          break
        case 'mediacontactPartner':
          this.$vfm.show({ component: 'MediacontactPartnerView', bind: { mediacontactPartner: this.source } })
          break
        case 'influencer':
          this.$vfm.show({ component: 'InfluencerView', bind: { influencer: this.source } })
          break
        case 'businessContact':
          this.$vfm.show({ component: 'BusinessContactView', bind: { businessContact: this.source } })
          break
        case 'projectBriefing':
          this.$vfm.show({ component: 'ProjectBriefingView', bind: { projectBriefing: this.source } })
          break
        case 'projectAds':
          this.$router.push({ name: 'projectAdCampaignList', params: { projectId: this.source.id } })
          break
        case 'projectList':
          this.$router.push({ name: 'projectListMediapartners', params: { projectId: this.source.project.id, projectListId: this.source.id } })
          break
      }
    }
  },
  computed: {
    icon () {
      switch (this.source.activityCategory) {
        case 'mediacontact':
          return 'user-edit'
        case 'mediacontactPartner':
          return 'layer-group'
        case 'influencer':
          return 'grin-stars'
        case 'businessContact':
          return 'user-tie'
        case 'projectBriefing':
          switch (this.source.category) {
            case 'Social Media':
              return 'users'
            case 'Marketing':
              return 'bullhorn'
            case 'Werbemittel':
              return 'images'
            case 'Online PR':
              return 'comment'
            case 'Print PR':
              return 'print'
            case 'Special':
              return 'star'
            default:
              return ''
          }
        case 'projectAds':
          return 'dollar-sign'
        case 'projectList':
          return 'list-ul'
        default:
          return ''
      }
    },
    topMessage () {
      switch (this.source.activityCategory) {
        case 'mediacontact':
          return 'Medienkontakt aktualisiert'
        case 'mediacontactPartner':
          return 'Medienpartner aktualisiert'
        case 'influencer':
          return 'Influencer aktualisiert'
        case 'businessContact':
          return 'B2B Kontakt aktualisiert'
        case 'projectBriefing':
          return `${this.source.category} Briefing in ${this.source.project.title} aktualisiert`
        case 'projectAds':
          return `Budget auf ${this.source.budget.toLocaleString()}€ aktualisiert`
        case 'projectList':
          return `Verteiler in  ${this.source.project.title} aktualisiert`
        default:
          return ''
      }
    },
    subMessage () {
      if (!this.source.updatedBy || !this.source.updatedAt) return 'Noch nie geändert'

      return `von ${this.updatedBy} ${this.updatedAt}`
    },
    title () {
      switch (this.source.activityCategory) {
        case 'mediacontact':
          return this.source.firstName + ' ' + this.source.lastName
        case 'mediacontactPartner':
          return this.source.name
        case 'influencer':
          return this.source.name
        case 'businessContact':
          return this.source.firstName + ' ' + this.source.lastName
        case 'projectBriefing':
          return this.source.name
        case 'projectAds':
          return this.source.title
        case 'projectList':
          return this.source.name
        default:
          return ''
      }
    },
    updatedBy () {
      return this.source.updatedBy.id === this.authUser.id ? 'mir' : this.source.updatedBy.firstName
    },
    updatedAt () {
      return moment(this.source.updatedAt).fromNow()
    },
    ...mapGetters({
      authUser: 'auth/authUser'
    })
  }
}
</script>

<style lang="scss">

    section.list .row .content .middle .col.dashboard-activity-title {
        width: 100%;
        cursor: pointer;
    }

    section.list .row .content .middle .col.dashboard-activity-timestamp {
        width: 100px;
        display: flex;
        justify-content: flex-end;
    }

</style>
