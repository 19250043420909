<template>

  <form v-on:submit.prevent="">

    <multiselect v-if="type === 'create'"
                 v-model="form.projectMaterial"
                 :custom-label="customLabel"
                 :loading="loading"
                 :options="projectMaterials"
                 :show-labels="false"
                 placeholder="Material">
      <template #placeholder>
        <font-awesome-icon icon="gift"/>
        <span>Material</span>
      </template>
      <template #singleLabel="props">
        <font-awesome-icon icon="gift"/>
        <span>{{ customLabel(props.option) }}</span>
      </template>
    </multiselect>

    <BaseInputFloatinglabel v-model.number="form.count"
                            icon="layer-group"
                            placeholder="Menge"
                            type="number"/>

    <v-date-picker
      v-model="form.date"
      :popover="{ visibility: 'click' }"
      :is-dark="darkmodeStatus"
      color="blue"
      is24hr
      mode="date"
      title-position="left">
      <template v-slot="{ inputValue, inputEvents }">
        <div class="input-wrapper-floatinglabel">
          <font-awesome-icon icon="paper-plane"/>
          <input
            placeholder="Versendet"
            type="text"
            :value="inputValue"
            required
            v-on="inputEvents" />
          <label>
            Versendet
          </label>
        </div>
      </template>
    </v-date-picker>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Hinzufügen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>
  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectListMediacontactMaterialForm',
  props: {
    projectListMediacontactMaterial: Object,
    projectListMediacontact: Object,
    type: String
  },
  data () {
    return {
      form: {},
      loading: true,
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.fetchData()
    this.form = cloneDeep(this.projectListMediacontactMaterial)
  },
  methods: {
    async fetchData () {
      try {
        this.projectMaterials.length || await this.getProjectMaterials({ customUrl: '/projects/' + this.$route.params.projectId + '/project_materials' })
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    async create () {
      try {
        this.creating = true
        await this.createProjectListMediacontactMaterial({ data: this.form })
        this.$vfm.hide('project-list-mediacontact-material-create')
        this.$toasted.success('Material ' + this.form.count + 'x ' + this.form.projectMaterial.material.name + ' hinzugefügt', { icon: 'fas fa-gift' })
        this.creating = false
        await this.getProjectListMediacontact({ id: this.projectListMediacontact.id })
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        await this.updateProjectListMediacontactMaterial({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-list-mediacontact-material-edit')
        this.$toasted.success('Material ' + this.form.count + 'x ' + this.form.projectMaterial.material.name + ' gespeichert', { icon: 'fas fa-gift' })
        this.updating = false
        await this.getProjectListMediacontact({ id: this.projectListMediacontact.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectListMediacontactMaterial({ id: this.form.id })
        this.$vfm.hide('project-list-mediacontact-material-edit')
        this.$toasted.success('Material gelöscht', { icon: 'fas fa-gift' })
        this.removing = false
        await this.getProjectListMediacontact({ id: this.projectListMediacontact.id })
      } catch (error) {
        this.removing = false
      }
    },
    customLabel (projectMaterial) {
      return `${projectMaterial.material.name} (${this.spent(projectMaterial)}/${projectMaterial.count} verbraucht | ${this.left(projectMaterial)} übrig)`
    },
    spent (projectMaterial) {
      return projectMaterial.projectListMediacontactMaterial
        .map(projectListMediacontactMaterial => projectListMediacontactMaterial.count)
        .reduce((cur, acc) => cur + acc, 0)
    },
    left (projectMaterial) {
      return projectMaterial.count - this.spent(projectMaterial)
    },
    ...mapActions({
      getProjectMaterials: 'projectMaterials/fetchList',
      createProjectListMediacontactMaterial: 'projectListMediacontactMaterials/create',
      updateProjectListMediacontactMaterial: 'projectListMediacontactMaterials/replace',
      deleteProjectListMediacontactMaterial: 'projectListMediacontactMaterials/destroy',
      getProjectListMediacontactMaterial: 'projectListMediacontactMaterials/fetchSingle',
      getProjectListMediacontactMaterials: 'projectListMediacontactMaterials/fetchList',
      getProjectListMediacontact: 'projectListMediacontacts/fetchSingle'
    })
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    projectMaterials () {
      return this.projectMaterialsByProject(this.$route.params.projectId)
    },
    checkForm () {
      return !(this.form.projectMaterial && this.form.count)
    },
    isManager () {
      return (this.project && this.project.managers && this.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    ...mapGetters({
      projectById: 'projects/byId',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      projectMaterialsByProject: 'projectMaterials/byProject',
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>
