<template>

  <base-modal name="business-contact-print"
              title="Einzeldruck"
              subtitle="Änderungen werden nicht gespeichert"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">

    <form v-on:submit.prevent="">

      <BaseInputFloatinglabel v-if="form.company"
                              v-model="form.company.name"
                              icon="building"
                              placeholder="Unternehmen"
                              type="text"/>
      <BaseInputFloatinglabel v-model="form.firstName"
                              icon="user-tie"
                              placeholder="Vorname"
                              type="text"/>
      <BaseInputFloatinglabel v-model="form.lastName"
                              icon="user-tie"
                              placeholder="Nachname"
                              type="text"/>
      <BaseInputFloatinglabel v-if="form.company"
                              v-model="form.company.street"
                              icon="home"
                              placeholder="Straße"
                              type="text"/>
      <BaseInputFloatinglabel v-if="form.company"
                              v-model="form.company.zip"
                              icon="map-marker-alt"
                              placeholder="PLZ"
                              type="text"/>
      <BaseInputFloatinglabel v-if="form.company"
                              v-model="form.company.city"
                              icon="city"
                              placeholder="Stadt"
                              type="text"/>
      <BaseInputFloatinglabel v-if="form.company"
                              v-model="form.company.country"
                              icon="flag"
                              placeholder="Land"
                              type="text"/>
      <div class="action">
        <BaseButton :loading="printing"
                    :disabled="checkForm || printing"
                    icon="print"
                    label="Drucken"
                    :submit="true"
                    @click="printBusinessContact"/>
      </div>
    </form>

  </base-modal>

</template>

<script>
import print from 'print-js'
import axios from 'axios'
import { cloneDeep } from 'lodash'

export default {
  name: 'BusinessContactPrint',
  props: {
    businessContact: Object
  },
  data () {
    return {
      form: {},
      printing: false
    }
  },
  created () {
    this.form = cloneDeep(this.businessContact)
  },
  methods: {
    async printBusinessContact () {
      this.printing = true
      const response = await axios.post(process.env.VUE_APP_API_URL + '/print/business_contact', { businessContact: this.form }, { responseType: 'blob' })
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileUrl = window.URL.createObjectURL(file)
      print(fileUrl)
      this.printing = false
    }
  },
  computed: {
    checkForm () {
      return !(this.form.firstName && this.form.lastName)
    }
  }
}
</script>
