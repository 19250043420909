<template>

  <form v-on:submit.prevent="">

    <multiselect v-model="form.costCenter"
                 :custom-label="costCenterLabel"
                 :hide-selected="true"
                 :options="[1, 2, 3]"
                 :show-labels="false"
                 placeholder="Kostenstelle">
      <template #placeholder>
        <font-awesome-icon icon="receipt"/>
        <span>Kostenstelle</span>
      </template>
      <template #singleLabel="props">
        <font-awesome-icon icon="receipt"/>
        <span>{{ costCenterLabel(props.option) }}</span>
      </template>
    </multiselect>

    <BaseInputFloatinglabel v-model="form.title"
                            icon="receipt"
                            placeholder="Titel"
                            type="text"/>

    <BaseTextarea v-model="form.description"
                  icon="sticky-note"
                  placeholder="Beschreibung"/>

    <BaseInputFloatinglabel v-model="form.revenueAmount"
                            icon="euro-sign"
                            placeholder="Kunden-Netto"
                            step="0.01"
                            type="number"/>

    <BaseInputFloatinglabel v-model="form.spendingAmount"
                            icon="euro-sign"
                            placeholder="Agentur-Netto"
                            step="0.01"
                            type="number"/>

    <BaseCheckbox v-if="hasChiefRole"
                  v-model="form.isPublic"
                  placeholder="Sichtbar"
                  :id="form.id"
                  field="isPublic"
                  type="checkbox"/>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectAccountingItemForm',
  props: ['projectAccountingItem', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.projectAccountingItem)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        await this.createProjectAccountingItem({ data: this.form })
        this.$vfm.hide('project-accounting-item-create')
        this.$toasted.success('Buchung erstellt', { icon: 'fas fa-receipt' })
        this.creating = false
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        await this.updateProjectAccountingItem({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-accounting-item-edit')
        this.$toasted.success('Buchung gespeichert', { icon: 'fas fa-receipt' })
        this.updating = false
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectAccountingItem({ id: this.form.id })
        this.$vfm.hide('project-accounting-item-edit')
        this.$toasted.success('Buchung gelöscht', { icon: 'fas fa-receipt' })
        this.removing = false
      } catch (error) {
        this.removing = false
      }
    },
    costCenterLabel (costCenter) {
      switch (costCenter) {
        case 1:
          return 'PR'
        case 2:
          return 'Social Media'
        case 3:
          return 'Marketing'
      }
    },
    ...mapActions({
      createProjectAccountingItem: 'projectAccountingItems/create',
      updateProjectAccountingItem: 'projectAccountingItems/replace',
      deleteProjectAccountingItem: 'projectAccountingItems/destroy',
      getProjectAccountingItems: 'projectAccountingItems/fetchList'
    })
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    isManager () {
      return (this.project && this.project.managers && this.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    checkForm () {
      return false
    },
    ...mapGetters({
      projectById: 'projects/byId',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      hasChiefRole: 'auth/hasChiefRole'
    })
  }
}
</script>
