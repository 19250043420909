<template>

    <main>

        <BaseTopBar icon="user-friends" title="Mitarbeiter" :length="filteredUsers.length" :breadcrump="false">
            <template #action>
                <button v-if="authUser && authUser.roles.some(role => role === 'ROLE_CHIEF' || role === 'ROLE_ADMIN')"
                        class="button button-flat button-light"
                        @click="$vfm.show({component: 'UserCreate'})">
                    <font-awesome-icon icon="plus" size="sm"/>
                    <span>Erstellen</span>
                </button>
            </template>
        </BaseTopBar>

        <BaseFilterBar>
            <template #left>
                <div class="search">
                    <input type="text" class="flat" placeholder="Suchen" v-model="filter.search">
                    <font-awesome-icon icon="search" size="sm"/>
                </div>
                <multiselect v-model="filter.teams"
                             :options="['Azubi', 'Social Media', 'Marketing', 'Online PR', 'Offline PR', 'Web Development', 'Geschäftsführung']"
                             multiple
                             placeholder="Teams"
                             :show-labels="false"
                             :hide-selected="true">
                    <template #placeholder>
                        <font-awesome-icon icon="users"/>
                        <span>Teams</span>
                    </template>
                </multiselect>
                <multiselect v-model="filter.roles"
                             :options="[
                                { name: 'Azubi', value: 'ROLE_TRAINEE' },
                                { name: 'Mitarbeiter', value: 'ROLE_USER' },
                                { name: 'Manager', value: 'ROLE_MANAGER' },
                                { name: 'Geschäftsführer', value: 'ROLE_CHIEF' },
                                { name: 'Admin', value: 'ROLE_ADMIN' }]"
                             track-by="value"
                             label="name"
                             multiple
                             placeholder="Berechtigungen"
                             :show-labels="false"
                             :hide-selected="true">
                    <template #placeholder>
                        <font-awesome-icon icon="user-shield"/>
                        <span>Berechtigungen</span>
                    </template>
                </multiselect>
                <multiselect v-model="filter.status"
                             :options="[{ name: 'Aktiv', value: true }, { name: 'Deaktiviert', value: false }]"
                             label="name"
                             track-by="value"
                             multiple
                             placeholder="Status"
                             :show-labels="false"
                             :hide-selected="true">
                    <template #placeholder>
                        <font-awesome-icon icon="user-check"/>
                        <span>Status</span>
                    </template>
                </multiselect>
            </template>
        </BaseFilterBar>

        <BaseTable>
            <template #header>
                <base-list-item>
                    <div class="content">
                        <div class="icon"></div>
                        <div class="middle">
                            <div class="col user-name">
                                <font-awesome-icon icon="user"/>
                                Mitarbeiter
                            </div>
                            <div class="col user-teams">
                                <font-awesome-icon icon="users"/>
                                Teams
                            </div>
                            <div class="col user-phone">
                                <font-awesome-icon icon="phone-alt"/>
                                Durchwahl
                            </div>
                            <div class="col user-mobile">
                                <font-awesome-icon icon="mobile-alt"/>
                                Mobil
                            </div>
                            <div class="col user-roles">
                                <font-awesome-icon icon="mobile-alt"/>
                                Berechtigungen
                            </div>
                        </div>
                    </div>
                </base-list-item>
            </template>

            <user-list-item v-for="user in filteredUsers" :key="user.id" :user="user"></user-list-item>
            <skeleton-row :show="loading"></skeleton-row>
            <not-found-row entity="Mitarbeiter" :show="!loading && !filteredUsers.length"></not-found-row>
        </BaseTable>

    </main>

</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import SkeletonRow from '@/components/SkeletonRow.vue'
import NotFoundRow from '@/components/NotFoundRow.vue'
import UserListItem from './UserListItem.vue'
import { orderBy } from 'lodash'
import BaseTable from '@/components/base/BaseTable'

export default {
  name: 'Users',
  components: {
    BaseTable,
    UserListItem,
    NotFoundRow,
    SkeletonRow
  },
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.users.length || await this.getUsers()
      this.loading = false
    },
    ...mapActions({ getUsers: 'users/fetchList' })
  },
  computed: {
    filteredUsers: function () {
      return orderBy(this.users, ['firstName', 'lastName'], 'asc')
        .filter(user => user.email.toLowerCase().indexOf(this.filter.search.toLowerCase()) > -1)
        .filter(user => !this.filter.teams.length || this.filter.teams.some(filterTeam => user.teams && user.teams.some(userTeam => userTeam === filterTeam)))
        .filter(user => !this.filter.roles.length || this.filter.roles.some(filterRole => user.roles && user.roles.some(userRole => userRole === filterRole.value)))
        .filter(user => !this.filter.status.length || this.filter.status.some(filterStatus => filterStatus.value === user.isActive))
    },
    ...mapState({
      filter: state => state.users.filter
    }),
    ...mapGetters({
      users: 'users/list',
      authUser: 'auth/authUser'
    })
  }
}
</script>

<style lang="scss">

    section.table .row .content .middle .col.user-name {
        width: 380px;
    }

    section.table .row .content .middle .col.user-teams {
        width: 220px;
    }

    section.table .row .content .middle .col.user-phone {
        width: 120px;
    }

    section.table .row .content .middle .col.user-mobile {
        width: 130px;
    }

    section.table .row .content .middle .col.user-roles {
        width: 200px;
    }

    @media (max-width: 1024px) {

        section.table .row .content .middle .col.user-phone,
        section.table .row .content .middle .col.user-mobile,
        section.table .row .content .middle .col.user-teams,
        section.table .row .content .middle .col.user-roles {
            display: none;
        }

    }

</style>
