<template>

  <base-modal name="mediacontact-partner-edit"
              toptitle="Medienpartner bearbeiten"
              :title="mediacontactPartner.name"
              :width="800"
              v-on="$listeners"
              v-bind="$attrs">
    <mediacontact-partner-form :mediacontactPartner="mediacontactPartner" type="edit"/>
  </base-modal>

</template>

<script>
import MediacontactPartnerForm from './MediacontactPartnerForm.vue'

export default {
  name: 'MediacontactPartnerEdit',
  components: { MediacontactPartnerForm },
  props: {
    mediacontactPartner: Object
  }
}
</script>
