<template>

    <div class="grid">
        <slot/>
    </div>

</template>

<script>
export default {
  name: 'BaseGrid'
}
</script>

<style lang="scss">

    .grid {
        max-width: 1680px;
        width: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        //grid-column-gap: 20px;
    }

</style>
