<template>

  <base-modal name="business-contact-topic-edit"
              toptitle="Schwerpunkt bearbeiten"
              :title="businessContactTopic.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <business-contact-topic-form :businessContactTopic="businessContactTopic" type="edit"/>
  </base-modal>

</template>

<script>
import BusinessContactTopicForm from './BusinessContactTopicForm.vue'

export default {
  name: 'BusinessContactTopicEdit',
  components: { BusinessContactTopicForm },
  props: {
    businessContactTopic: Object
  }
}
</script>
