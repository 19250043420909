<template>

  <base-modal name="influencer-category-create"
              title="Kategorie erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <influencer-category-form :influencerCategory="influencerCategory"
                              type="create"/>
  </base-modal>

</template>

<script>
import InfluencerCategoryForm from './InfluencerCategoryForm.vue'

export default {
  name: 'InfluencerCategoryCreate',
  components: { InfluencerCategoryForm },
  data: () => ({
    influencerCategory: {
      name: null
    }
  })
}
</script>
