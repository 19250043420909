<template>

  <base-modal name="project-edit"
              :width="1200"
              toptitle="Projekt"
              :title="`${project.title} bearbeiten`"
              :subtitle="lastUpdated"
              v-on="$listeners"
              v-bind="$attrs">
    <project-form :project="project" type="edit"/>
  </base-modal>

</template>

<script>
import ProjectForm from '@/components/project/ProjectForm.vue'
import moment from 'moment'

export default {
  name: 'ProjectEdit',
  components: { ProjectForm },
  props: {
    project: Object
  },
  computed: {
    lastUpdated () {
      return `Letzte Änderung von ${this.project.updatedBy.firstName} ${moment(this.project.updatedAt).fromNow()}`
    }
  }
}
</script>
