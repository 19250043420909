<template>
  <div class="row">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'BaseListItem'
}
</script>

<style lang="scss">

/* Row */
.row {
  border-bottom: 1px solid $gray-200;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 5px 10px 5px 15px;
  transition: 0.3s all ease;
  font-size: 0.875rem;
  position: relative;
  background-color: $white;
}

.row:hover,
.row:active {
  background-color: $gray-50;
}

.row.Offen {
  background-color: rgba(purple, 0.1);
}

.row.Online {
  background-color: rgba(green, 0.1);
}

.row.Zugesagt {
  background-color: rgba(yellow, 0.1);
}

.row.Abgesagt {
  background-color: rgba(red, 0.1);
}

.row.selected {
  background-color: $gray-100;
}

/* Row > Content */
.row .content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 200px;
}

/* Row > Content > Icon */
.row .content .icon {
  color: $text-color;
  width: 20px;
  margin-right: 15px;
}

.row .content .icon img {
  width: 20px;
}

.row .content .icon img.picture {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.row .content .checkbox {
  width: 20px;
  margin-right: 10px;
}

/* Row > Content > Middle */
.row .content .middle {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* Row > Content > Middle > Col */
.row .content .middle .col {
  padding-right: 20px;
  line-height: 1.3;
  width: 170px;
  color: $text-color-2;
}

.row .content .middle .col:last-child {
  padding-right: 0;
}

/* Title */
.row .content .middle .col .title {
  font-size: 1rem;
  font-weight: 500;
  color: $text-color;
  cursor: pointer;
}

.row .content .middle .col .subtitle {
  color: $text-color;
  font-weight: 400;
}

.row .content .middle .col label {
  display: none;
}

.row .content .middle .col svg {
  color: $text-color-3;
}

/* Dashboard */
.row .content .middle .col.dashboard-title {
  width: 100%;
}

.row .action .button span {
  display: none;
}

@media (max-width: 1024px) {
  .row .content .middle .col .subtitle {
    color: $text-color-2;
  }

  .row .content .middle .col.dashboard-project-customer-contacts {
    display: none;
  }
}

#app.dark {

  .listitem {
    border-color: $black-3;
  }

  .row {
    border-color: $black-3;
    background-color: $black-1;
  }

  .row:hover {
    background-color: $black-2;
  }

  .row.selected {
    background-color: $black-3;
  }

  .row .icon {
    color: $white;
  }

  .row .col {
    color: $gray-500;
  }

  .row .col .title {
    color: $gray-300;
  }

  .row .col .subtitle {
    color: $gray-300;
  }

  .row .content .middle .col svg {
    color: $gray-500;
  }

  .row .col.select-wrapper-single select {
    border-bottom: none;
    padding-bottom: 12px;
  }
}

</style>
