import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'mediacontactProductTypes',
  urlRoot: '/mediacontact_product_types',
  state: {
    filter: {
      search: ''
    }
  }
})
