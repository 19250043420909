<template>

  <form v-on:submit.prevent="">

    <div class="middle">
      <div class="group">
        <div class="title">Eigenschaften</div>

        <multiselect v-if="type === 'create'"
                     v-model="mediacontactPartner"
                     :options="filteredMediacontactPartners"
                     label="name"
                     track-by="id"
                     placeholder="Medienpartner"
                     :loading="loading"
                     :show-labels="false">
          <template #placeholder>
            <font-awesome-icon icon="layer-group"/>
            <span>Medienpartner</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="layer-group"/>
            <span>{{ props.option.name }}</span>
          </template>
        </multiselect>

        <multiselect v-if="type === 'create'"
                     v-model="form.type"
                     :options="['News', 'Trailer', 'Review', 'Gewinnspiel', 'Interview', 'Exklusives Video']"
                     placeholder="Art"
                     :show-labels="false"
                     :hide-selected="true">
          <template #placeholder>
            <font-awesome-icon icon="desktop"/>
            <span>Art</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="desktop"/>
            <span>{{ props.option }}</span>
          </template>
        </multiselect>

        <multiselect v-model="form.status"
                     :options="['Zusage', 'Absage', 'Veröffentlicht', 'Anfrage', 'Angebot', 'TBC']"
                     placeholder="Status"
                     :show-labels="false"
                     :hide-selected="true">
          <template #placeholder>
            <font-awesome-icon icon="toggle-on"/>
            <span>Status</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="toggle-on"/>
            <span>{{ props.option }}</span>
          </template>
        </multiselect>

        <v-date-picker
          v-model="form.date"
          :popover="{ visibility: 'click' }"
          :is-dark="darkmodeStatus"
          color="blue"
          is24hr
          mode="date"
          title-position="left">
          <template v-slot="{ inputValue, inputEvents }">
            <div class="input-wrapper-floatinglabel">
              <font-awesome-icon icon="calendar-day"/>
              <input
                placeholder="Datum"
                type="text"
                :value="inputValue"
                required
                v-on="inputEvents" />
              <label>
                Datum
              </label>
            </div>
          </template>
        </v-date-picker>

        <BaseTextarea v-model="form.text"
                      icon="comment-alt"
                      placeholder="Anmerkungen"/>

        <BaseInputFloatinglabel v-if="form.type === 'Trailer' || form.type === 'Exklusives Video'"
                                v-model.number="form.views"
                                icon="video"
                                placeholder="Anzahl der Aufrufe"
                                type="number"/>

        <multiselect v-if="form.type === 'Review'"
                     v-model="form.mood"
                     :options="['Positiv', 'Gemischt', 'Negativ']"
                     placeholder="Stimmung"
                     :show-labels="false"
                     :hide-selected="true">
          <template #placeholder>
            <font-awesome-icon icon="grin-stars"/>
            <span>Stimmung</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="grin-stars"/>
            <span>{{ props.option }}</span>
          </template>
        </multiselect>

        <BaseTextarea v-if="form.type === 'Review'"
                      v-model="form.quote"
                      icon="quote-right"
                      placeholder="Zitat"/>

        <BaseCheckbox v-model="form.highlight"
                      :id="form.id"
                      field="highlight"
                      placeholder="Highlight"/>

      </div>
      <div class="group">

        <div class="title">Belege</div>

        <BaseInputFloatinglabel v-model="form.link"
                                icon="link"
                                placeholder="Link"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.facebookPostId"
                                :icon="['fab', 'facebook']"
                                placeholder="Facebook Beitrags ID"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.instagramPostId"
                                :icon="['fab', 'instagram']"
                                placeholder="Instagram Beitrags ID"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.youtubeVideoId"
                                :icon="['fab', 'youtube']"
                                placeholder="YouTube Video ID"
                                type="text"/>

        <div>
          <vue-dropzone id="dropzone"
                        ref="projectMediacontactPartnerDropzone"
                        :options="dropzoneOptions"
                        @vdropzone-mounted="dropzoneMounted"
                        @vdropzone-success="success">
          </vue-dropzone>
          <br>
        </div>

      </div>

    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Hinzufügen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import { cloneDeep, orderBy } from 'lodash'

export default {
  name: 'ProjectMediacontactPartnerFeatureForm',
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    projectMediacontactPartner: {
      type: Object,
      default: () => null
    },
    projectMediacontactPartnerFeature: Object,
    type: String
  },
  data () {
    return {
      mediacontactPartner: null,
      form: {
        id: null
      },
      creating: false,
      updating: false,
      removing: false,
      loading: true,
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + '/project_mediacontact_partner_features/' + this.projectMediacontactPartnerFeature.id + '/clippings',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        autoProcessQueue: false,
        dictDefaultMessage: 'Clippings hierherziehen',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Cache-Control': '',
          'X-Requested-With': ''
        }
      }
    }
  },
  created () {
    this.fetchData()
    if (this.projectMediacontactPartner) {
      this.mediacontactPartner = this.projectMediacontactPartner.mediacontactPartner
    }
    this.form = cloneDeep(this.projectMediacontactPartnerFeature)
  },
  methods: {
    async fetchData () {
      this.mediacontactPartners.length || await this.getMediacontactPartners()
      this.loading = false
    },
    async create () {
      try {
        this.creating = true
        let projectMediacontactPartner = this.projectMediacontactPartners.find(projectMediacontactPartner => projectMediacontactPartner.mediacontactPartner.id === this.mediacontactPartner.id)
        if (!projectMediacontactPartner) {
          const response = await this.createProjectMediacontactPartner({
            data: {
              project: '/projects/' + this.$route.params.projectId,
              mediacontactPartner: '/mediacontact_partners/' + this.mediacontactPartner.id,
              rejectedAll: false
            }
          })
          projectMediacontactPartner = response.data
        }
        this.form.projectMediacontactPartner = '/project_mediacontact_partners/' + projectMediacontactPartner.id
        const response = await this.createProjectMediacontactPartnerFeature({ data: this.form })
        this.form = response.data
        if (this.$refs.projectMediacontactPartnerDropzone.getQueuedFiles().length > 0) {
          this.$refs.projectMediacontactPartnerDropzone.setOption('url', process.env.VUE_APP_API_URL + '/project_mediacontact_partner_features/' + this.form.id + '/clippings')
          this.$refs.projectMediacontactPartnerDropzone.processQueue()
        } else {
          this.$vfm.hide('project-mediacontact-partner-feature-create')
          this.$toasted.success(this.form.type + ' zu Medienpartner hinzugefügt', { icon: 'fas fa-newspaper' })
          this.creating = false
        }
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        await this.updateProjectMediacontactPartnerFeature({ id: this.form.id, data: this.form })
        if (this.$refs.projectMediacontactPartnerDropzone.getQueuedFiles().length > 0) {
          this.$refs.projectMediacontactPartnerDropzone.processQueue()
        } else {
          this.$vfm.hide('project-mediacontact-partner-feature-edit')
          this.$toasted.success(this.form.type + ' bei Medienpartner gespeichert', { icon: 'fas fa-newspaper' })
          this.getProjectMediacontactPartnerFeature({ id: this.form.id })
        }
        this.updating = false
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectMediacontactPartnerFeature({ id: this.form.id })
        this.$vfm.hide('project-mediacontact-partner-feature-edit')
        this.$toasted.success(this.form.type + ' bei Medienpartner gelöscht', { icon: 'fas fa-newspaper' })
        this.removing = false
      } catch (error) {
        this.removing = false
      }
    },
    dropzoneMounted () {
      if (this.form.clippings && this.form.clippings.length) {
        this.form.clippings.forEach(clipping => {
          this.$refs.projectMediacontactPartnerDropzone.manuallyAddFile({
            name: clipping.name,
            type: clipping.mimeType,
            size: clipping.size
          }, process.env.VUE_APP_API_URL + '/' + clipping.url)
        })
      }
    },
    async success (file, response) {
      this.$vfm.hide('project-mediacontact-partner-feature-create')
      this.$vfm.hide('project-mediacontact-partner-feature-edit')
      this.$toasted.success(this.form.type + ' bei Medienpartner gespeichert', { icon: 'fas fa-newspaper' })
      this.getProjectMediacontactPartnerFeature({ id: this.form.id })
    },
    ...mapActions({
      createProjectMediacontactPartnerFeature: 'projectMediacontactPartnerFeatures/create',
      updateProjectMediacontactPartnerFeature: 'projectMediacontactPartnerFeatures/replace',
      deleteProjectMediacontactPartnerFeature: 'projectMediacontactPartnerFeatures/destroy',
      getProjectMediacontactPartnerFeature: 'projectMediacontactPartnerFeatures/fetchSingle',
      getProjectMediacontactPartners: 'projectMediacontactPartners/fetchList',
      createProjectMediacontactPartner: 'projectMediacontactPartners/create',
      getMediacontactPartners: 'mediacontactPartners/fetchList',
      getProject: 'projects/fetchSingle'
    })
  },
  computed: {
    isManager () {
      return (this.project && this.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    projectMediacontactPartners () {
      return this.projectMediacontactPartnersByProject(this.$route.params.projectId)
    },
    filteredMediacontactPartners () {
      return this.mediacontactPartners ? orderBy(this.mediacontactPartners, 'name', 'ASC') : []
    },
    checkForm () {
      return !(this.form.type && this.form.status)
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      mediacontactPartners: 'mediacontactPartners/list',
      projectMediacontactPartnersByProject: 'projectMediacontactPartners/byProject',
      projectById: 'projects/byId',
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>
