<template>

    <base-modal name="project-briefing-create"
                :toptitle="project.title"
                title="Briefing erstellen"
                :width="400"
                v-on="$listeners"
                v-bind="$attrs">
            <project-briefing-form :project-briefing="projectBriefing" :project="project"/>
    </base-modal>

</template>

<script>
import ProjectBriefingForm from './ProjectBriefingForm.vue'

export default {
  name: 'ProjectBriefingCreate',
  components: { ProjectBriefingForm },
  props: {
    project: {
      type: Object,
      default: () => ({
        title: null
      })
    }
  },
  data () {
    return {
      projectBriefing: {}
    }
  }
}
</script>
