import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'users',
  urlRoot: '/users',
  state: {
    filter: {
      search: '',
      teams: [],
      roles: [],
      status: [{ name: 'Aktiv', value: true }]
    }
  },
  getters: {
    active: state => {
      return Object.values(state.entities).filter(user => user.isActive)
    }
  }
})
