<template>

  <base-modal name="project-accounting-deal-create"
              title="Deal erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <project-accounting-deal-form :projectAccountingDeal="projectAccountingDeal"
                                  type="create"/>
  </base-modal>

</template>

<script>
import ProjectAccountingDealForm from './ProjectAccountingDealForm.vue'

export default {
  name: 'ProjectAccountingDealCreate',
  components: { ProjectAccountingDealForm },
  data: () => ({
    projectAccountingDeal: {
      project: null
    }
  }),
  created () {
    this.projectAccountingDeal.project = '/projects/' + this.$route.params.projectId
  }
}
</script>
