<template>

    <div class="backend">

        <Header/>
        <router-view/>

    </div>

</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'Backend',
  components: { Header }
}
</script>
