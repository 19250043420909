<template>

  <form v-on:submit.prevent="">

    <BaseInputFloatinglabel v-model="form.amount"
                            icon="euro-sign"
                            placeholder="Budget"
                            step="0.01"
                            type="number"/>

    <BaseTextarea v-model="form.description"
                  icon="sticky-note"
                  placeholder="Beschreibung"/>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Hinzufügen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectAdBudgetForm',
  props: {
    projectAdBudget: Object,
    type: String
  },
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.projectAdBudget)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        const response = await this.createProjectAdBudget({ data: this.form })
        this.$vfm.hide('project-ad-budget-create')
        this.$toasted.success('Budget von ' + response.data.amount + '€ hinzugefügt', { icon: 'fas fa-euro-sign' })
        this.creating = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateProjectAdBudget({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-ad-budget-edit')
        this.$toasted.success('Budget von ' + response.data.amount + '€ gespeichert', { icon: 'fas fa-euro-sign' })
        this.updating = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectAdBudget({ id: this.form.id })
        this.$toasted.success('Budget gelöscht', { icon: 'fas fa-euro-sign' })
        this.$vfm.hide('project-ad-budget-edit')
        this.removing = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      createProjectAdBudget: 'projectAdBudgets/create',
      updateProjectAdBudget: 'projectAdBudgets/replace',
      deleteProjectAdBudget: 'projectAdBudgets/destroy',
      getProject: 'projects/fetchSingle'
    })
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    isManager () {
      return (this.project && this.project.managers && this.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    checkForm () {
      return false
    },
    ...mapGetters({
      projectById: 'projects/byId',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
