<template>

    <section class="infobar">
        <div class="wrapper">
            <slot/>
        </div>
    </section>

</template>

<script>
export default {
  name: 'BaseInfoBar'
}
</script>

<style lang="scss">

    section.infobar {
        position: sticky;
        top: 110px;
        padding: 0 20px;
        z-index: 10;
    }

    section.infobar::after {
        content: "";
        position: absolute;
        background-color: $primary-color;
        height: 30px;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        box-shadow: 0 0 3px $text-color-4;
    }

    section.infobar .wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        transition: 0.3s all ease;
        position: relative;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: 0 0 5px $text-color-4;
        background-color: $white;
        font-size: 0.875rem;
        max-width: 1640px;
        width: 100%;
        color: $text-color-2;
        //border: 1px solid $gray-200;
    }

    @media (max-width: 768px) {
        section.infobar {
            position: relative;
            top: 0;
        }
    }

    #app.dark {
        section.infobar::after {
            background-color: $black-2;
        }

        section.infobar .wrapper {
            background-color: $black-3;
            border-color: $black-4;
        }
    }

</style>
