<template>

    <div class="header-search">
        <input type="text"
               class="raised"
               placeholder="Suchen"
               v-model="search"
               @keyup.down="onDown"
               @keyup.up="onUp"
               @keyup.enter="onSubmit"
               ref="search"
               required>
        <font-awesome-icon icon="search" size="sm"/>
        <div class="results" ref="results">
            <div v-if="!loading">
                <div class="result-row" :class="{ selected: selectedIndex === results.indexOf(result) }"
                     v-for="result in results"
                     :key="result.id"
                     @click="select(result)"
                     ref="result">
                    <font-awesome-icon :icon="result.icon" size="sm"/>
                    <div>
                        <div class="subtitle">{{ result.category }}</div>
                        <div class="title">{{ result.label }}</div>
                    </div>
                </div>
            </div>
            <clip-loader :loading="loading" color="#9E9E9E" size="20px"></clip-loader>
            <div class="result-row" v-if="!results.length && !loading">
                <font-awesome-icon icon="search" size="sm"/>
                Keine Ergebnisse
            </div>
        </div>
    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce, orderBy } from 'lodash'

export default {
  name: 'HeaderSearch',
  data () {
    return {
      search: '',
      selectedIndex: 0,
      loading: true,
      results: []
    }
  },
  created () {
    this.fetchData()
    this.debouncedGetResults = debounce(this.filterResults, 500)

    window.addEventListener('keydown', event => {
      if (event.metaKey && event.key === 'f') {
        event.preventDefault()
        setTimeout(() => {
          this.$refs.search.focus()
        }, 50)
      }
    })
  },
  watch: {
    search () {
      this.loading = true
      this.debouncedGetResults()
    }
  },
  methods: {
    async fetchData () {
      await Promise.all([
        // this.projects.length || this.getProjects(),
        // this.customers.length || this.getCustomers(),
        // this.mediapartners.length || this.getMediapartners(),
        // this.influencers.length || this.getInfluencers(),
        // this.businessContacts.length || this.getBusinessContacts()
      ])
      this.loading = false
    },
    filterResults () {
      this.results = []
      this.selectedIndex = 0
      this.projects.forEach(project => {
        if (project.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
          const result = {}
          result.id = project.id
          result.icon = project.category === 'Kino' ? 'film' : 'home'
          result.category = 'Projekt'
          result.label = `${project.title}`
          result.link = { name: 'project', params: { projectId: project.id } }
          this.results.push(result)
        }
      })
      this.customers.forEach(customer => {
        if (customer.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
          const result = {}
          result.id = customer.id
          result.icon = 'building'
          result.category = 'Kunde'
          result.label = customer.name
          result.link = { name: 'customerContactList', params: { customerId: customer.id } }
          this.results.push(result)
        }
      })
      // this.mediapartners.forEach(mediacontact => {
      //     const result = {};
      //     result.icon = 'user-edit';
      //     result.category = 'Medienpartner';
      //     result.label = `${mediacontact.firstName} ${mediacontact.lastName}`;
      //     result.link = {name: 'mediapartnerView', params: {mediapartnerId: mediacontact.id}};
      //     //result.entity = mediacontact;
      //     this.entities.push(result);
      // });
      // this.influencers.forEach(influencer => {
      //     const result = {};
      //     result.icon = 'grin-stars';
      //     result.category = 'Influencer';
      //     result.label = `${influencer.name}`;
      //     result.link = {name: 'influencerView', params: {influencerId: influencer.id}};
      //     //result.entity = influencer;
      //     this.entities.push(result);
      // });
      // this.businessContacts.forEach(businessContact => {
      //     const result = {};
      //     result.icon = 'user-tie';
      //     result.category = 'B2B Kontakt';
      //     result.label = `${businessContact.firstName} ${businessContact.lastName}`;
      //     result.link = {name: 'businessContactView', params: {businessContactId: businessContact.id}};
      //     //result.entity = businessContact;
      //     this.entities.push(result);
      // });

      this.results = orderBy(this.results, ['category', 'label'], 'asc')
      this.loading = false
    },
    onDown () {
      if (this.selectedIndex < this.results.length - 1) {
        this.selectedIndex++
      }
      this.fixScrolling()
    },
    onUp () {
      if (this.selectedIndex > 0) {
        this.selectedIndex--
      }
      this.fixScrolling()
    },
    onSubmit (event) {
      this.$router.push(this.results[this.selectedIndex].link)
      event.target.blur()
    },
    select (result) {
      this.selectedIndex = this.results.indexOf(result)
      this.$router.push(this.results[this.selectedIndex].link)
    },
    fixScrolling () {
      const resultHeight = this.$refs.result[this.selectedIndex].clientHeight
      this.$refs.results.scrollTop = resultHeight * this.selectedIndex
    },
    ...mapActions({
      getProjects: 'projects/fetchList',
      getCustomers: 'customers/fetchList'
      // getMediapartners: 'mediapartners/fetchList',
      // getInfluencers: 'influencers/fetchList',
      // getBusinessContacts: 'businessContacts/fetchList'
    })
  },
  computed: {
    ...mapGetters({
      projects: 'projects/list',
      customers: 'customers/list'
      // mediapartners: 'mediapartners/list',
      // influencers: 'influencers/list',
      // businessContacts: 'businessContacts/list'
    })
  }
}
</script>

<style lang="scss">

    header .wrapper .left .header-search {
        display: flex;
        align-items: center;
        position: relative;
    }

    header .wrapper .left .header-search input {
        border-radius: 10px;
        transition: 0.3s all ease;
        width: 30px;
        padding: 10px 10px 10px 35px;
        font-size: 0.875rem;
    }

    header .wrapper .left .header-search input + svg {
        position: absolute;
        top: 10px;
        left: 10px;
        color: rgba($white, 0.3);
        transition: 0.3s all ease;
        pointer-events: none;
        cursor: pointer;
    }

    header .wrapper .left .header-search input:focus,
    header .wrapper .left .header-search input:valid {
        background-color: $gray-200;
        width: 180px;
    }

    header .wrapper .left .header-search input:focus + svg,
    header .wrapper .left .header-search input:valid + svg {
        color: $text-color;
    }

    header .wrapper .left .header-search input:valid:focus ~ .results,
    header .wrapper .left .header-search .results:hover {
        display: flex;
    }

    header .wrapper .left .header-search .results {
        display: none;
        flex-flow: column nowrap;
        position: absolute;
        top: 36px;
        left: 0;
        min-width: 180px;
        //max-width: auto;
        max-height: 184px;
        padding: 10px 0;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        z-index: 400;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
    }

    header .wrapper .left .header-search .results::-webkit-scrollbar {
        display: none;
    }

    header .wrapper .left .header-search .results .result-row {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 5px 10px;
        //border-radius: 10px;
        font-size: 0.875rem;
        font-weight: 500;
        white-space: nowrap;
        cursor: pointer;
        //transition: 0.3s all ease-out;
        border-bottom: 1px solid $gray-200;
    }

    header .wrapper .left .header-search .results .result-row svg {
        color: $text-color-2;
        margin-right: 10px;
    }

    header .wrapper .left .header-search .results .result-row .subtitle {
        color: $text-color-3;
        font-size: 0.75rem;
        margin-bottom: 3px;
    }

    header .wrapper .left .header-search .results .result-row .title {
        color: $text-color-3;
    }

    header .wrapper .left .header-search .results .result-row:hover {
        background-color: $gray-50;
    }

    header .wrapper .left .header-search .results .result-row.selected {
        background-color: $gray-100;
    }

    header .wrapper .left .header-search .results .result-row.selected .subtitle {
        color: $text-color-2;
    }

    header .wrapper .left .header-search .results .result-row.selected .title {
        color: $text-color;
    }

    header .wrapper .left .header-search .results .result-row.selected svg {
        color: $text-color;
    }

    header .wrapper .left .header-search .results .result-row:last-child {
        border-bottom: none;
    }

    #app.dark {

        header .wrapper .left .header-search input + svg {
            color: $gray-500;
        }

        header .wrapper .left .header-search input:focus,
        header .wrapper .left .header-search input:valid {
            background-color: $black-3;
        }

        header .wrapper .left .header-search input:focus + svg,
        header .wrapper .left .header-search input:valid + svg {
            color: $white;
        }

        header .wrapper .left .header-search .results {
            background-color: $black-3;
        }

        header .wrapper .left .header-search .results .result-row svg {
            color: $gray-500;
        }

        header .wrapper .left .header-search .results .result-row .subtitle {
            color: $gray-500;
        }

        header .wrapper .left .header-search .results .result-row .title {
            color: $white;
        }

        header .wrapper .left .header-search .results .result-row:hover {
            background-color: $gray-800;
        }

        header .wrapper .left .header-search .results .result-row.selected {
            background-color: $gray-800;
        }

        header .wrapper .left .header-search .results .result-row.selected svg {
            color: $white;
        }

    }

</style>
