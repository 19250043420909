import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectMaterials',
  urlRoot: '/project_materials',
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectMaterial => projectMaterial.project === '/projects/' + id)
    }
  }
})
