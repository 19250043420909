<template>

  <base-modal name="project-accounting-item-edit"
              :width="400"
              toptitle="Buchung bearbeiten"
              :title="projectAccountingItem.title || projectAccountingItem.description"
              v-on="$listeners"
              v-bind="$attrs">
      <project-accounting-item-form :projectAccountingItem="projectAccountingItem" type="edit"/>
  </base-modal>

</template>

<script>
import ProjectAccountingItemForm from './ProjectAccountingItemForm.vue'

export default {
  name: 'ProjectAccountingItemEdit',
  components: { ProjectAccountingItemForm },
  props: {
    projectAccountingItem: Object
  },
  computed: {
    costCenterLabel () {
      switch (this.projectAccountingItem.costCenter) {
        case 1:
          return 'PR'
        case 2:
          return 'Social Media'
        case 3:
          return 'Marketing'
        default:
          return ''
      }
    }
  }
}
</script>
