<template>

  <base-modal name="project-accounting-invoice-create"
              title="Rechnung erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <project-accounting-invoice-form :projectAccountingInvoice="projectAccountingInvoice" type="create"/>
  </base-modal>

</template>

<script>
import ProjectAccountingInvoiceForm from './ProjectAccountingInvoiceForm.vue'

export default {
  name: 'ProjectAccountingInvoiceCreate',
  components: { ProjectAccountingInvoiceForm },
  data: () => ({
    projectAccountingInvoice: {
      project: null,
      isPublic: true
    }
  }),
  created () {
    this.projectAccountingInvoice.project = '/projects/' + this.$route.params.projectId
  }
}
</script>
