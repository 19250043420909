<template>

  <base-modal name="project-ad-campaign-edit"
              toptitle="Kampagne bearbeiten"
              :title="projectAdCampaign.publisher"
              :width="800"
              v-on="$listeners"
              v-bind="$attrs">
      <project-ad-campaign-form :projectAdCampaign="projectAdCampaign" type="edit"/>
  </base-modal>

</template>

<script>
import ProjectAdCampaignForm from './ProjectAdCampaignForm.vue'

export default {
  name: 'ProjectAdCampaignEdit',
  components: { ProjectAdCampaignForm },
  props: {
    projectAdCampaign: Object
  }
}
</script>
