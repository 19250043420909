<template>

  <form v-on:submit.prevent="">

    <BaseInputFloatinglabel v-model="form.name"
                            icon="building"
                            placeholder="Name"
                            type="text"/>
    <BaseInputFloatinglabel v-model="form.street"
                            icon="home"
                            placeholder="Straße"
                            type="text"/>
    <BaseInputFloatinglabel v-model="form.zip"
                            icon="map-marker-alt"
                            placeholder="PLZ"
                            type="text"/>
    <BaseInputFloatinglabel v-model="form.city"
                            icon="city"
                            placeholder="Stadt"
                            type="text"/>
    <BaseInputFloatinglabel v-model="form.country"
                            icon="flag"
                            placeholder="Land" type="text"/>
    <BaseInputFloatinglabel v-model="form.website"
                            icon="desktop"
                            placeholder="Website"
                            type="text"/>
    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="hasManagerRole && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'BusinessContactCompanyForm',
  props: ['businessContactCompany', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.businessContactCompany)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        const response = await this.createBusinessContactCompany({ data: this.form })
        this.$vfm.hide('business-contact-company-create')
        this.$toasted.success('B2B Kontakt Unternehmen ' + response.data.name + ' erstellt', { icon: 'fas fa-building' })
        this.creating = false
        await this.getBusinessContactCompanies()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateBusinessContactCompany({ id: this.form.id, data: this.form })
        this.$vfm.hide('business-contact-company-edit')
        this.$toasted.success('B2B Kontakt Unternehmen ' + response.data.name + ' gespeichert', { icon: 'fas fa-building' })
        this.updating = false
        await this.getBusinessContactCompany({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteBusinessContactCompany({ id: this.form.id })
        this.$vfm.hide('business-contact-company-edit')
        this.$toasted.success('B2B Kontakt Unternehmen gelöscht', { icon: 'fas fa-building' })
        this.removing = false
        await this.getBusinessContactCompanies()
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      createBusinessContactCompany: 'businessContactCompanies/create',
      updateBusinessContactCompany: 'businessContactCompanies/replace',
      deleteBusinessContactCompany: 'businessContactCompanies/destroy',
      getBusinessContactCompany: 'businessContactCompanies/fetchSingle',
      getBusinessContactCompanies: 'businessContactCompanies/fetchList'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.name)
    },
    ...mapGetters({
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
