<template>

    <header>

        <div class="wrapper">

            <div class="left">

                <div class="logo">
                    <img :src="logo()" alt=""/>
                    <span>Voll:kontakt</span>
                </div>

                <nav class="menu">
                    <router-link :to="{ name: 'dashboard' }">
                        <font-awesome-icon icon="tachometer-alt" size="sm"/>
                        <span>Dashboard</span>
                    </router-link>

                    <router-link :to="{ name: 'projectList' }">
                        <font-awesome-icon icon="stream" size="sm"/>
                        <span>Projekte</span>
                    </router-link>

                    <router-link :to="{ name: 'customerList' }">
                        <font-awesome-icon icon="building" size="sm"/>
                        <span>Kunden</span>
                    </router-link>

                    <router-link :to="{ name: 'mediacontactList' }">
                        <font-awesome-icon icon="user-edit" size="sm"/>
                        <span>Medienkontakte</span>
                        <nav class="submenu">
                            <router-link :to="{ name: 'mediacontactProductTypeList' }">
                                <font-awesome-icon icon="tags" size="sm"/>
                                <span>Produktarten</span>
                            </router-link>
                            <router-link :to="{ name: 'mediacontactPartnerCategoryList' }">
                                <font-awesome-icon icon="th" size="sm"/>
                                <span>Media Kategorien</span>
                            </router-link>
                            <router-link :to="{ name: 'mediacontactPartnerList' }">
                                <font-awesome-icon icon="layer-group" size="sm"/>
                                <span>Medienpartner</span>
                            </router-link>
                            <router-link :to="{ name: 'mediacontactCompanyList' }">
                                <font-awesome-icon icon="building" size="sm"/>
                                <span>Unternehmen</span>
                            </router-link>
                            <router-link :to="{ name: 'mediacontactRegionList' }">
                                <font-awesome-icon icon="map-marker-alt" size="sm"/>
                                <span>Regionen</span>
                            </router-link>
                        </nav>
                    </router-link>

                    <router-link :to="{ name: 'influencerList' }">
                        <font-awesome-icon icon="grin-stars" size="sm"/>
                        <span>Influencer</span>
                        <nav class="submenu">
                            <router-link :to="{ name: 'influencerCategoryList' }">
                                <font-awesome-icon icon="tags" size="sm"/>
                                <span>Kategorien</span>
                            </router-link>
                            <router-link :to="{ name: 'influencerSubCategoryList' }">
                                <font-awesome-icon icon="th" size="sm"/>
                                <span>Schwerpunkte</span>
                            </router-link>
                            <router-link :to="{ name: 'influencerAudienceList' }">
                                <font-awesome-icon icon="bullseye" size="sm"/>
                                <span>Zielgruppen</span>
                            </router-link>
                            <router-link :to="{ name: 'influencerNetworkList' }">
                                <font-awesome-icon icon="project-diagram" size="sm"/>
                                <span>Netzwerke</span>
                            </router-link>
                        </nav>
                    </router-link>

                    <router-link :to="{ name: 'businessContactList' }">
                        <font-awesome-icon icon="user-tie" size="sm"/>
                        <span>B2B Kontakte</span>
                        <nav class="submenu">
                            <router-link :to="{ name: 'businessContactCompanyList' }">
                                <font-awesome-icon icon="building" size="sm"/>
                                <span>Unternehmen</span>
                            </router-link>
                            <router-link :to="{ name: 'businessContactCategoryList' }">
                                <font-awesome-icon icon="tags" size="sm"/>
                                <span>Kategorien</span>
                            </router-link>
                            <router-link :to="{ name: 'businessContactTopicList' }">
                                <font-awesome-icon icon="th" size="sm"/>
                                <span>Schwerpunkte</span>
                            </router-link>
                        </nav>
                    </router-link>

                    <router-link :to="{ name: 'userList' }">
                        <font-awesome-icon icon="user-friends" size="sm"/>
                        <span>Mitarbeiter</span>
                    </router-link>

                </nav>

                <header-search v-if="hasBetaRole" />

            </div>

            <div class="right">

                <nav class="menu" v-if="authUser">
                    <router-link :to="{ name: 'settings' }">
                        <img v-if="user && user.pictureName" :src="picture" class="picture" alt=""/>
                        <font-awesome-icon v-else :icon="userIcon" size="sm"/>
                        <span>{{ authUser.firstName }}</span>
                        <nav class="submenu">
                            <router-link :to="{ name: 'settings' }">
                                <font-awesome-icon icon="cog" size="sm"/>
                                <span>Einstellungen</span>
                            </router-link>
                            <router-link :to="{ name: 'logout' }">
                                <font-awesome-icon icon="sign-out-alt" size="sm"/>
                                <span>Abmelden</span>
                            </router-link>
                        </nav>
                    </router-link>
                </nav>

            </div>

        </div>

    </header>

</template>

<script>
import { mapGetters } from 'vuex'
import HeaderSearch from './HeaderSearch'

export default {
  name: 'Header',
  components: { HeaderSearch },
  methods: {
    logo () {
      return this.darkmodeStatus ? '/images/logo_white.svg' : '/images/logo_white.svg'
    }
  },
  computed: {
    userIcon () {
      switch (this.authUser.roles[0]) {
        case 'ROLE_ADMIN':
          return 'user-shield'
        case 'ROLE_CHIEF':
          return 'user-cog'
        case 'ROLE_MANAGER':
          return 'user-check'
        case 'ROLE_USER':
          return 'user-alt'
        case 'ROLE_TRAINEE':
          return 'user-graduate'
        default:
          return ''
      }
    },
    picture () {
      return process.env.VUE_APP_API_URL + '/files/users/' + this.user.id + '/' + this.user.pictureName
    },
    user () {
      return this.userById(this.authUser.id)
    },
    ...mapGetters({
      darkmodeStatus: 'app/darkmodeStatus',
      authUser: 'auth/authUser',
      userById: 'users/byId',
      hasBetaRole: 'auth/hasBetaRole'
    })
  }
}
</script>

<style lang="scss">

    header {
        position: sticky;
        top: 0;
        background-color: $primary-color;
        height: 50px;
        z-index: 100;
    }

    header .wrapper {
        max-width: 1680px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 20px 10px 10px 10px;
        height: 50px;
    }

    header .wrapper .left {
        display: flex;
        align-items: center;
    }

    header .wrapper .left .logo {
        padding: 10px;
        text-align: center;
        z-index: 1;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
    }

    header .wrapper .left .logo img {
        width: 20px;
        opacity: 1.0;
       //margin-right: 10px;
    }

    header .wrapper .left .logo span {
        display: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.875rem;
        color: $white;
    }

    /* Nav */
    header .wrapper nav.menu {
        display: flex;
    }

    header .wrapper nav a {
        font-weight: 500;
        font-size: 1rem;
        padding: 10px 12px;
        margin: 10px 0;
        letter-spacing: 0.1px;
        display: flex;
        align-items: center;
        position: relative;
        color: rgba($white, 0.3);
    }

    header .wrapper nav a svg {
        transition: 0.3s all ease;
        margin-right: 5px;
    }

    header .wrapper .left nav a span {
        transition: 0.3s all ease;
    }

    header .wrapper nav a:hover svg,
    header .wrapper nav a:hover span {
        color: $white;
    }

    header .wrapper nav a:active svg,
    header .wrapper nav a:active span {
        color: $white;
    }

    header .wrapper nav a.router-link-active,
    header .wrapper nav a.router-link-active:hover {
        color: $white;
    }

    header .wrapper nav a.router-link-exact-active,
    header .wrapper nav a.router-link-exact-active:hover {
        color: $white;
    }

    header .wrapper nav a.router-link-exact-active svg {
        color: $white;
    }

    /* First Level */
    header .wrapper nav.submenu {
        position: absolute;
        display: none;
        background-color: $white;
        color: $black;
        padding: 10px 0;
        border-radius: 10px;
        box-shadow: 0 0 5px $text-color-4;
        //z-index: 9000;
    }

    header .wrapper nav a:hover nav.submenu,
    header .wrapper nav a:active nav.submenu {
        display: block;
        top: 35px;
        left: 0;
    }

    header .wrapper nav.submenu a {
        font-size: 0.875rem;
        font-weight: 500;
        white-space: nowrap;
        margin: 0 10px;
        padding: 10px 5px;
        border-radius: 20px;
    }

    header .wrapper nav.submenu a span {
        color: $text-color-3;
    }

    header .wrapper nav.submenu a svg {
        color: $text-color-3;
        margin-right: 5px;
        display: block;
    }

    header .wrapper nav.submenu a:hover span,
    header .wrapper nav.submenu a:hover svg {
        color: $text-color-2
    }

    header .wrapper nav.submenu a.router-link-active span,
    header .wrapper nav.submenu a.router-link-active svg {
        color: $text-color;
    }

    header .wrapper nav.submenu a.router-link-exact-active span,
    header .wrapper nav.submenu a.router-link-exact-active svg {
        color: $text-color;
    }

    /* Right */
    header .wrapper .right {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0 10px;
    }

    header .wrapper .right img {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    header .wrapper .right nav a {
        color: $white;
      transition: 0.3s all ease;
    }

    header .wrapper .right nav a svg {
        margin-right: 5px;
    }

    header .wrapper .right nav a span {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.875rem;
    }

    header .wrapper .right nav a .submenu a,
    header .wrapper .right nav a .submenu a svg {
        color: $text-color-2;
    }

    header .wrapper .right nav a .submenu a.router-link-active,
    header .wrapper .right nav a .submenu a.router-link-active svg {
        color: $text-color;
    }

    header .wrapper .right nav a .submenu a span {
        font-weight: 500;
        text-transform: none;
    }

    header .wrapper .right nav a .submenu a:hover,
    header .wrapper .right nav a .submenu a:hover svg {
        color: $text-color;
    }

    header .wrapper .right nav a:hover .submenu {
        top: 35px;
        right: 0;
        left: auto;
    }

    @media (max-width: 1024px) {

        header .wrapper .logo span {
            display: none;
        }

        header .wrapper nav a span {
            display: none;
        }

        header .wrapper .left nav a svg {
            display: block;
        }

        header .wrapper nav a .submenu a svg {
            display: none;
        }

        header .wrapper nav a .submenu a span {
            display: block;
        }

        header .wrapper .right .user:hover .submenu a svg {
            display: none;
        }

        header .wrapper .right .user:hover .submenu a span {
            display: block;
        }
    }

    #app.dark {

        header {
            background-color: $black-2;
            border-bottom-color: $gray-800 !important;
            box-shadow: none;
        }

        header .wrapper nav a svg,
        header .wrapper nav a span {
            color: $gray-600;
        }

        header .wrapper nav a:hover svg,
        header .wrapper nav a:hover span {
            color: $gray-300;
        }

        header .wrapper nav a:active svg,
        header .wrapper nav a:active span {
            color: $white;
        }

        header .wrapper nav a.router-link-active span,
        header .wrapper nav a.router-link-active svg {
                color: $gray-300;
        }

        header .wrapper nav a.router-link-exact-active span,
        header .wrapper nav a.router-link-exact-active svg {
            color: $white;
        }

        /* First Level */
        header .wrapper .submenu {
            background-color: $black-3;
            color: $gray-500;
        }

        header .wrapper nav.submenu a svg,
        header .wrapper nav.submenu a span {
            color: $gray-500;
        }

        header .wrapper nav.submenu a:hover svg,
        header .wrapper nav.submenu a:hover span {
            color: $white;
        }

        header .wrapper nav.submenu a.router-link-active span,
        header .wrapper nav.submenu a.router-link-active svg {
            color: $white;
        }

        /* Right */
        header .wrapper .right nav a span,
        header .wrapper .right nav a svg {
            color: $white;
        }

        header .wrapper .right nav a nav.submenu a span,
        header .wrapper .right nav a nav.submenu a svg {
            color: $gray-500;
        }

        header .wrapper .right nav a nav.submenu a:hover span,
        header .wrapper .right nav a nav.submenu a:hover svg  {
            color: $white;
        }

        header .wrapper .right nav a nav.submenu a.router-link-active span,
        header .wrapper .right nav a nav.submenu a.router-link-active svg {
            color: $white
        }

    }

</style>
