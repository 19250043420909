<template>

    <div class="group">
        <div class="top">
            <div class="subtitle">
                <font-awesome-icon icon="user-clock"/>
                Abwesenheit
                <span>{{ events.length }}</span>
            </div>
        </div>

        <div class="wrapper">
            <dashboard-calendar-item v-for="item in events" :key="item.event.id" :item="item"></dashboard-calendar-item>
            <skeleton-row :show="loading"></skeleton-row>
            <not-found-row entity="Abwesenheiten" :show="!loading && !events.length"></not-found-row>
        </div>
    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DashboardCalendarItem from './DashboardCalendarItem.vue'
import SkeletonRow from '@/components/SkeletonRow.vue'
import NotFoundRow from '@/components/NotFoundRow.vue'
import { orderBy } from 'lodash'

export default {
  name: 'DashboardCalendarAbsenceList',
  components: { DashboardCalendarItem, NotFoundRow, SkeletonRow },
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData: async function () {
      this.calendar.busy || await this.loadCalendar()
      this.loading = false
    },
    ...mapActions({
      loadCalendar: 'app/loadCalendar'
    })
  },
  computed: {
    events () {
      const events = []
      if (!this.loading) {
        this.calendar.busy.value.map(event => events.push({ category: 'busy', event }))
      }
      return orderBy(events, 'event.start.dateTime', 'asc')
    },
    ...mapGetters({
      calendar: 'app/calendar',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole'
    })
  }

}
</script>
