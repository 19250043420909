<template>

  <form v-on:submit.prevent="">

    <div class="middle">
      <div class="group">
        <div class="title">Name</div>

        <BaseInputFloatinglabel v-model="form.firstName"
                                icon="user-edit"
                                placeholder="Vorname"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.middleName"
                                icon="user-edit"
                                placeholder="Zweiter Vorname" type="text"/>

        <BaseInputFloatinglabel v-model="form.lastName"
                                icon="user-edit"
                                placeholder="Nachname"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.jobTitle"
                                icon="sitemap"
                                placeholder="Position"
                                type="text"/>

        <div class="title">Kontakt</div>

        <BaseInputFloatinglabel v-model="form.email"
                                icon="envelope"
                                placeholder="E-Mail"
                                type="email"/>

        <BaseInputFloatinglabel v-model="form.phone"
                                icon="phone-alt"
                                placeholder="Telefon"
                                type="tel"/>

        <BaseInputFloatinglabel v-model="form.fax"
                                icon="fax"
                                placeholder="Fax"
                                type="tel"/>

        <BaseInputFloatinglabel v-model="form.mobile"
                                icon="mobile-alt"
                                placeholder="Mobil"
                                type="tel"/>

      </div>

      <div class="group">
        <div class="title">Anschrift</div>

        <BaseInputFloatinglabel v-model="form.street"
                                icon="home"
                                placeholder="Straße"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.zip"
                                icon="map-marker-alt"
                                placeholder="PLZ"
                                type="number"/>

        <BaseInputFloatinglabel v-model="form.city"
                                icon="city"
                                placeholder="Stadt"
                                type="text"/>

        <BaseInputFloatinglabel v-model="form.country"
                                icon="flag"
                                placeholder="Land"
                                type="text"/>
      </div>

      <div class="group">
        <div class="title">Kategorien</div>

        <multiselect v-model="form.productTypes"
                     :hide-selected="true"
                     :multiple="true"
                     :options="mediacontactProductTypes"
                     :show-labels="false"
                     label="name"
                     placeholder="Produktarten"
                     track-by="id"
                     :loading="loading">
          <template #placeholder>
            <font-awesome-icon icon="tags"/>
            <span>Produktarten</span>
          </template>
        </multiselect>

        <multiselect v-model="form.departments"
                     :multiple="true"
                     :options="['Online', 'Print', 'Radio', 'Podcast', 'TV']"
                     :show-labels="false"
                     placeholder="Bereiche">
          <template #placeholder>
            <font-awesome-icon icon="tags"/>
            <span>Bereiche</span>
          </template>
        </multiselect>

        <multiselect v-model="form.partnerCategories"
                     :multiple="true"
                     :options="mediacontactPartnerCategories"
                     :show-labels="false"
                     label="name"
                     placeholder="Media Kategorien"
                     track-by="id"
                     :loading="loading">
          <template #placeholder>
            <font-awesome-icon icon="th"/>
            <span>Media Kategorien</span>
          </template>
        </multiselect>

        <multiselect v-model="form.partners"
                     :multiple="true"
                     :options="mediacontactPartners"
                     :show-labels="false"
                     label="name"
                     placeholder="Medienpartner"
                     track-by="id"
                     :loading="loading">
          <template #placeholder>
            <font-awesome-icon icon="layer-group"/>
            <span>Medienpartner</span>
          </template>
        </multiselect>

        <multiselect v-model="form.regions"
                     :multiple="true"
                     :options="mediacontactRegions"
                     :show-labels="false"
                     label="name"
                     placeholder="Regionen"
                     track-by="id"
                     :loading="loading">
          <template #placeholder>
            <font-awesome-icon icon="map-marker-alt"/>
            <span>Regionen</span>
          </template>
        </multiselect>

        <div class="title">Sonstiges</div>

        <BaseTextarea v-model="form.notes"
                      icon="sticky-note"
                      placeholder="Notizen"
                      :id="form.id"/>
        <BaseCheckbox field="inquiryCase"
                      v-model="form.inquiryCase"
                      placeholder="Einzelfall"
                      :id="form.id"/>
        <BaseCheckbox field="contests"
                      v-model="form.contests"
                      placeholder="Gewinnspiel"
                      :id="form.id"/>
        <BaseCheckbox field="interviews"
                      v-model="form.interviews"
                      placeholder="Interview"
                      :id="form.id"/>
        <BaseCheckbox field="pressPreview"
                      v-model="form.pressPreview"
                      placeholder="Pressevorführung"
                      :id="form.id"/>
        <BaseCheckbox field="isActive"
                      v-model="form.isActive"
                      placeholder="Aktiv"
                      :id="form.id"/>
      </div>
    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="hasManagerRole && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'MediacontactForm',
  props: ['mediacontact', 'type'],
  data () {
    return {
      form: {},
      loading: true,
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.fetchData()
    this.form = cloneDeep(this.mediacontact)
  },
  methods: {
    async fetchData () {
      await Promise.all([
        this.mediacontactProductTypes.length || this.getMediacontactProductTypes(),
        this.mediacontactPartnerCategories.length || this.getMediacontactPartnerCategories(),
        this.mediacontactPartners.length || this.getMediacontactPartners(),
        this.mediacontactRegions.length || this.getMediacontactRegions()
      ])
      this.loading = false
    },
    async create () {
      try {
        this.creating = true
        const response = await this.createMediacontact({ data: this.form })
        this.$vfm.hide('mediacontact-create')
        this.$toasted.success(response.data.firstName + ' ' + response.data.lastName + ' erstellt', { icon: 'fas fa-user-edit' })
        this.creating = false
        await this.getMediacontacts()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateMediacontact({ id: this.form.id, data: this.form })
        this.$vfm.hide('mediacontact-edit')
        this.$toasted.success(response.data.firstName + ' ' + response.data.lastName + ' aktualisiert', { icon: 'fas fa-user-edit' })
        this.updating = false
        await this.getMediacontact({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteMediacontact({ id: this.form.id })
        this.$vfm.hide('mediacontact-edit')
        this.$toasted.success('Medienpartner gelöscht', { icon: 'fas fa-user-edit' })
        this.removing = false
        await this.getMediacontacts()
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      createMediacontact: 'mediacontacts/create',
      updateMediacontact: 'mediacontacts/replace',
      deleteMediacontact: 'mediacontacts/destroy',
      getMediacontact: 'mediacontacts/fetchSingle',
      getMediacontacts: 'mediacontacts/fetchList',
      getMediacontactProductTypes: 'mediacontactProductTypes/fetchList',
      getMediacontactPartnerCategories: 'mediacontactPartnerCategories/fetchList',
      getMediacontactPartners: 'mediacontactPartners/fetchList',
      getMediacontactRegions: 'mediacontactRegions/fetchList'
    })
  },
  computed: {
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.form.email)
    },
    checkForm () {
      // return !(this.form.firstName && this.validEmail);
      return false
    },
    ...mapGetters({
      mediacontactProductTypes: 'mediacontactProductTypes/list',
      mediacontactPartnerCategories: 'mediacontactPartnerCategories/list',
      mediacontactPartners: 'mediacontactPartners/list',
      mediacontactRegions: 'mediacontactRegions/list',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
