import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectMediacontactPartners',
  urlRoot: '/project_mediacontact_partners',
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectMediacontactPartner => projectMediacontactPartner.project === '/projects/' + id)
    },
    byMediacontactPartner: (state) => (id) => {
      return Object.values(state.entities).filter(projectMediacontactPartner => projectMediacontactPartner.mediacontactPartner.id === id)
    },
    groupedByProject: (state, getters) => (id) => {
      const projectMediacontactPartnerByMediacontactPartner = {}
      getters.byProject(id).forEach(projectMediacontactPartner => {
        if (!projectMediacontactPartnerByMediacontactPartner[projectMediacontactPartner.mediacontactPartner.name]) {
          projectMediacontactPartnerByMediacontactPartner[projectMediacontactPartner.mediacontactPartner.name] = {}
          projectMediacontactPartnerByMediacontactPartner[projectMediacontactPartner.mediacontactPartner.name].mediacontactPartner = projectMediacontactPartner.mediacontactPartner
          projectMediacontactPartnerByMediacontactPartner[projectMediacontactPartner.mediacontactPartner.name].projectMediacontactPartners = []
        }
        projectMediacontactPartnerByMediacontactPartner[projectMediacontactPartner.mediacontactPartner.name].projectMediacontactPartners.push(projectMediacontactPartner)
      })
      return projectMediacontactPartnerByMediacontactPartner
    }
  }
})
