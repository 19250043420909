<template>

    <div class="input-wrapper-singleline">
        <input :placeholder="placeholder"
               :type="type"
               :value="value"
               required
               @input="$emit('input', $event.target.value)">
        <font-awesome-icon :icon="icon"/>
    </div>

</template>

<script>
export default {
  name: 'BaseInputSingleline',
  props: {
    type: String,
    placeholder: String,
    icon: String,
    value: String
  }
}
</script>

<style lang="scss">

/* Single-line text field ******/
.input-wrapper-singleline {
    height: 48px;
    padding: 8px 0 16px 0;
    position: relative;
}

.input-wrapper-singleline svg {
    color: $text-color;
}

.input-wrapper-singleline input {
    position: absolute;
    left: 0;
    padding-bottom: 8px;
    padding-left: 24px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: $gray-900;
}

.input-wrapper-singleline svg {
    position: absolute;
    left: 0;
}

.input-wrapper-singleline input:focus {
    border-bottom: 2px solid $gray-900;
}

</style>
