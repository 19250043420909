import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'mediacontacts',
  urlRoot: '/mediacontacts',
  state: {
    filter: {
      search: '',
      mediacontactProductTypes: [],
      mediacontactDepartments: [],
      mediacontactPartnerCategories: [],
      mediacontactRegions: [],
      mediacontactCompanies: [],
      mediacontactPartners: [],
      mediacontactCities: [],
      checkboxes: [],
      inquiryCase: { name: 'Kein Einzelfall', value: false },
      status: { name: 'Aktiv', value: true },
      sortBy: 'lastName',
      sortDirection: 'asc'
    }
  }
})
