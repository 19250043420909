<template>

  <base-modal name="mediacontact-partner-category-edit"
              toptitle="Media Kategorie bearbeiten"
              :title="mediacontactPartnerCategory.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <mediacontact-partner-category-form :mediacontactPartnerCategory="mediacontactPartnerCategory" type="edit"/>
  </base-modal>

</template>

<script>
import MediacontactPartnerCategoryForm from './MediacontactPartnerCategoryForm.vue'

export default {
  name: 'MediacontactPartnerCategoryEdit',
  components: { MediacontactPartnerCategoryForm },
  props: {
    mediacontactPartnerCategory: Object
  }
}
</script>
