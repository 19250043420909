<template>

    <div class="dashboard-col">
        <BaseList title="Profil" icon="user">
            <dashboard-user-info-list-item :app="{ icon: 'user', toptitle: authUser.position, title: `${authUser.firstName} ${authUser.lastName}`, subtitle: authUser.username }"></dashboard-user-info-list-item>
            <dashboard-user-info-list-item :app="{ icon: 'phone-alt', toptitle: 'Telefon', title: `‭040 5247231-${authUser.phone}`}"></dashboard-user-info-list-item>
            <dashboard-user-info-list-item v-if="authUser.anydeskId" :app="{ icon: 'laptop-house', toptitle: 'AnyDesk ID', title: authUser.anydeskId, }"></dashboard-user-info-list-item>
        </BaseList>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
import DashboardUserInfoListItem from './DashboardUserInfoListItem.vue'

export default {
  name: 'DashboardUserInfoList',
  components: { DashboardUserInfoListItem },
  computed: {
    auth () {
      return ''
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasBetaRole: 'auth/hasBetaRole'
    })
  }
}
</script>
