<template>

  <base-modal name="mediacontact-region-create"
              title="Region erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <mediacontact-region-form :mediacontactRegion="mediacontactRegion" type="create"></mediacontact-region-form>
  </base-modal>

</template>

<script>
import MediacontactRegionForm from './MediacontactRegionForm.vue'

export default {
  name: 'MediacontactRegionCreate',
  components: { MediacontactRegionForm },
  data: () => ({
    mediacontactRegion: {
      name: null
    }
  })
}
</script>
