import Vue from 'vue'
import router from './router'
import store from './store'
import App from './components/App.vue'

import './utils/axios'
import './utils/moment'
import './utils/toasted'
import './utils/fontawesome'
import './utils/vue-final-modal'
import './utils/vue-spinner'
import './utils/vue-virtual-scroll-list'
import './utils/multiselect'
import './utils/sentry'
import './utils/vcalendar'
import './utils/modals'

import BaseInputSingleline from './components/base/BaseInputSingleline'
import BaseInputFloatinglabel from './components/base/BaseInputFloatinglabel'
import BaseTextarea from './components/base/BaseTextarea'
import BaseCheckbox from './components/base/BaseCheckbox'
import BaseButton from './components/base/BaseButton'
import BaseModal from './components/base/BaseModal'
import BaseList from './components/base/BaseList'
import BaseListItem from './components/base/BaseListItem'
import BaseTopBar from './components/base/BaseTopBar'
import BaseFilterBar from '@/components/base/BaseFilterBar'
import BaseGrid from '@/components/base/BaseGrid'

import 'reset-css'
import './css/app.scss'

Vue.component('BaseInputSingleline', BaseInputSingleline)
Vue.component('BaseInputFloatinglabel', BaseInputFloatinglabel)
Vue.component('BaseTextarea', BaseTextarea)
Vue.component('BaseCheckbox', BaseCheckbox)
Vue.component('BaseButton', BaseButton)
Vue.component('BaseModal', BaseModal)
Vue.component('BaseList', BaseList)
Vue.component('BaseListItem', BaseListItem)
Vue.component('BaseTopBar', BaseTopBar)
Vue.component('BaseFilterBar', BaseFilterBar)
Vue.component('BaseGrid', BaseGrid)

Vue.config.productionTip = false

// configureCompat({
//   OPTIONS_DESTROYED: false,
//   OPTIONS_BEFORE_DESTROY: false
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
