import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectAdMaterials',
  urlRoot: '/project_ad_materials',
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectAdMaterial => projectAdMaterial.project === '/projects/' + id)
    }
  }
})
