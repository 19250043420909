<template>

  <base-modal name="influencer-audience-create"
              title="Zielgruppe erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <influencer-audience-form
      :influencerAudience="influencerAudience"
      type="create"/>
  </base-modal>

</template>

<script>
import InfluencerAudienceForm from './InfluencerAudienceForm.vue'

export default {
  name: 'InfluencerAudienceCreate',
  components: { InfluencerAudienceForm },
  data: () => ({
    influencerAudience: {
      name: null
    }
  })
}
</script>
