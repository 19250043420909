import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectInfluencers',
  urlRoot: '/project_influencers',
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectInfluencer => projectInfluencer.project.id === parseInt(id))
    }
  }
})
