<template>
  <base-card-item :toptitle="toptitle"
                  :title="project.title"
                  :subtitle="releaseDate"
                  :left-icon="icon"
                  :link="{name: 'project', params: {projectId: project.id}}">
    <template #cover v-if="project.coverName">
      <img :src="cover" alt="cover"/>
    </template>

    <template #top-action>
      <button class="button button-flat" @click="$vfm.show({component: 'ProjectEdit', bind: { project }})">
        <font-awesome-icon icon="pen" size="sm"/>
      </button>
    </template>

    <template #items v-if="projectBriefingByProject(project.id).length">
      <div v-for="projectBriefing in projectBriefingByProject(project.id)" :key="projectBriefing.id"
           class="card-item">
        <div class="card-item-top">
          <font-awesome-icon :icon="projectBriefingIcon(projectBriefing.category)"
                             class="category"/>
          <div>
            <div class="card-item-toptitle">
              {{ projectBriefing.category }}
            </div>
            <div class="card-item-title">
              {{ projectBriefing.name }}
            </div>
          </div>
          <font-awesome-icon :icon="projectBriefingStatusIconType(projectBriefing.status)"
                             :style="projectBriefingStatusIconColor(projectBriefing.status)"
                             class="status"/>
        </div>
        <div class="card-item-content">
          {{ projectBriefing.description }}
          <div v-if="projectBriefing.usersInCharge.length">
            <font-awesome-icon icon="users"/>
            <span class="chip"
                  v-for="user in projectBriefing.usersInCharge"
                  :key="user.id">
                            {{ user.firstName }}
                        </span>
          </div>
        </div>
      </div>
    </template>
    <template #action>
      <button class="button button-flat" @click="$vfm.show({component: 'ProjectBriefingCreate', bind: { project }})">
        <font-awesome-icon icon="plus" size="sm"/>
        <span>Briefing</span>
      </button>
    </template>
  </base-card-item>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import BaseCardItem from '@/components/base/BaseCardItem'

export default {
  name: 'DashboardProjectItem',
  components: { BaseCardItem },
  props: {
    project: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    projectBriefingIcon (category) {
      switch (category) {
        case 'Social Media':
          return 'hashtag'
        case 'Marketing':
          return 'bullhorn'
        case 'Werbemittel':
          return 'images'
        case 'Online PR':
          return 'newspaper'
        case 'Offline PR':
          return 'print'
        case 'Special':
          return 'star'
        default:
          return ''
      }
    },
    projectBriefingStatusIconType (status) {
      switch (status) {
        case 'Gebucht':
          return 'check-circle'
        case 'Idee':
          return 'lightbulb'
        case 'Nicht gebucht':
          return 'times-circle'
        default:
          return ''
      }
    },
    projectBriefingStatusIconColor (status) {
      switch (status) {
        case 'Gebucht':
          return { color: '#4CAF50' }
        case 'Idee':
          return { color: '#FFC107' }
        case 'Nicht gebucht':
          return { color: '#FF5722' }
        default:
          return { color: '#000000' }
      }
    }
  },
  computed: {
    cover () {
      return process.env.VUE_APP_API_URL + '/files/projects/' + this.project.id + '/' + this.project.coverName
    },
    icon () {
      switch (this.project.category) {
        case 'Kino':
          return 'film'
        case 'Home':
          return 'home'
        case 'Serie':
          return 'tv'
        case 'Musik':
          return 'compact-disc'
        case 'Buch':
          return 'book'
        case 'Hörbuch':
          return 'music'
        default:
          return 'stream'
      }
    },
    toptitle () {
      return `${this.project.category} - ${this.project.customer.name}`
    },
    releaseDate () {
      if (this.project.releaseDate) {
        if (moment(this.project.releaseDate).isSame(moment(), 'week')) {
          return moment(this.project.releaseDate).fromNow()
        }
        return moment(this.project.releaseDate).format('D. MMM Y')
      }
      return 'TBC'
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasUserRole: 'auth/hasUserRole',
      projectBriefingByProject: 'projectBriefings/byProject'
    })
  }
}
</script>
