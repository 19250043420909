<template>

    <section class="topbar">
        <div class="wrapper">
            <div class="left">
                <div class="breadcrump" @click="$router.go(-1)" v-if="breadcrump">
                    <font-awesome-icon icon="chevron-left" size="sm"/>
                </div>

                <div class="right">
                    <slot name="cover"/>
                    <font-awesome-icon v-if="icon" :icon="icon" size="sm"/>
                    <div>
                        <div class="subtitle">
                            <slot name="toptitle"/>
                        </div>
                        <div class="title">
                            {{ title }} <span v-if="length">{{ length }}</span>
                        </div>
                        <div class="subtitle">
                            <slot name="subtitle"/>
                        </div>
                    </div>
                </div>

            </div>

            <div class="action">
                <slot name="action"/>
            </div>

        </div>

    </section>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseTopBar',
  props: {
    breadcrump: {
      type: Boolean,
      default: true
    },
    icon: String,
    title: {
      type: String,
      default: ''
    },
    length: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/authUser'
    })
  }
}

</script>

<style lang="scss">

    .topbar {
        display: flex;
        position: sticky;
        top: 50px;
        color: $text-color;
        height: 60px;
        background-color: $primary-color;
        z-index: 80;
    }

    .topbar .wrapper {
        max-width: 1680px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }

    .topbar .wrapper .left {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
    }

    .topbar .wrapper .left .cover img {
        position: absolute;
        width: 100%;
        //height: 100px;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        filter: blur(5px);
        opacity: .3;
        pointer-events: none;
    }

    /* Breadcrumps */
    .topbar .wrapper .left .breadcrump {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s all ease;
        border-radius: 50%;
        margin-right: 5px;
        width: 30px;
        height: 30px;
    }

    .topbar .wrapper .left .breadcrump svg {
        color: $white;
    }

    .topbar .wrapper .left .breadcrump:hover {
        background-color: rgba($white, 0.1);
    }

    .topbar .wrapper .left .breadcrump:active {
        background-color: rgba($white, 0.2);
    }

    /* Title */
    .topbar .wrapper .left .right {
        display: flex;
        align-items: center;
    }

    .topbar .wrapper .left .right .title {
        font-size: 1.5rem;
        font-weight: 700;
        color: $white;
        white-space: nowrap;
    }

    .topbar .wrapper .left .right img {
        height: 36px;
        animation: fade-in 1s;
        margin-right: 10px;
        border-radius: 2px;
    }

    .topbar .wrapper .left .right svg {
        color: $white;
        margin-right: 10px;
        font-size: 1.5rem;
    }

    .topbar .wrapper .left .right .title span {
        margin-left: 5px;
        font-weight: 400;
        color: rgba($white, 0.3);
    }

    /* Subtitle */
    .topbar .wrapper .left .right .subtitle {
        color: rgba($white, 0.3);
        font-size: 0.875rem;
    }

    .topbar .wrapper .left .right .subtitle span {
        color: $text-color
    }

    /* Action */
    .topbar .wrapper .action {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    @media (max-width: 768px) {

        .topbar .wrapper .left .right svg {
            font-size: 1.25rem;
        }

        .topbar .wrapper .left .right .title {
            font-size: 1.25rem
        }

    }

    #app.dark {

        .topbar {
            color: $gray-50;
            background-color: $black-2;
        }

        .topbar .wrapper .left .right .breadcrump {
            color: $gray-500;
        }

        .topbar .wrapper .left .breadcrump:hover {
            background-color: $gray-800;
        }

        .topbar .wrapper .left .breadcrump:active {
            background-color: $gray-700;
        }

        .topbar .wrapper .left .right svg {
            color: $white;
        }

        .topbar .wrapper .left .right .title {
            color: $gray-50;
        }

        .topbar .wrapper .left .right .title span {
            color: $gray-500;
        }

        .topbar .wrapper .left .right .subtitle {
            color: $gray-500;
        }

    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 0.2;
        }
    }

</style>
