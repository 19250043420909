<template>

  <base-modal name="mediacontact-edit"
              toptitle="Medienkontakt bearbeiten"
              :title="`${mediacontact.firstName} ${mediacontact.lastName}`"
              :subtitle="subtitle"
              :width="1200"
              v-on="$listeners"
              v-bind="$attrs">
      <mediacontact-form :mediacontact="mediacontact" type="edit"/>
  </base-modal>

</template>

<script>
import MediacontactForm from './MediacontactForm.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'MediacontactEdit',
  components: { MediacontactForm },
  props: {
    mediacontact: Object
  },
  computed: {
    subtitle () {
      if (this.mediacontact.updatedBy && this.mediacontact.updatedAt) {
        return `Letzte Änderung von ${this.updatedBy} ${this.updatedAt}`
      }
      return null
    },
    updatedBy () {
      return this.mediacontact.updatedBy.id === this.authUser.id ? 'mir' : this.mediacontact.updatedBy.firstName
    },
    updatedAt () {
      return moment(this.mediacontact.updatedAt).fromNow()
    },
    ...mapGetters({
      authUser: 'auth/authUser'
    })
  }
}
</script>
