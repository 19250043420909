<template>

  <base-modal name="influencer-category-edit"
              toptitle="Kategorie bearbeiten"
              :title="influencerCategory.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <influencer-category-form :influencerCategory="influencerCategory"
                              type="edit"/>
  </base-modal>

</template>

<script>
import InfluencerCategoryForm from './InfluencerCategoryForm.vue'

export default {
  name: 'InfluencerCategoryEdit',
  components: { InfluencerCategoryForm },
  props: {
    influencerCategory: Object
  }
}
</script>
