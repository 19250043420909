<template>

  <base-modal title="Einzeldruck"
              subtitle="Änderungen werden nicht gespeichert"
              height="auto"
              name="customer-contact-print"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <form v-on:submit.prevent="">
      <div class="middle">
        <div class="group">
          <div class="title">Unternehmen</div>
          <BaseInputFloatinglabel v-if="form.customer"
                                  v-model="form.customer.fullName"
                                  icon="building"
                                  placeholder="Unternehmen"
                                  type="text"/>

          <div class="title">Ansprechpartner</div>
          <BaseInputFloatinglabel
            v-model="form.firstName"
            icon="user-tie"
            placeholder="Vorname"
            type="text"/>

          <BaseInputFloatinglabel
            v-model="form.lastName"
            icon="user-tie"
            placeholder="Nachname"
            type="text"/>

          <div class="title">Anschrift</div>
          <BaseInputFloatinglabel v-if="form.customer"
                                  v-model="form.customer.street"
                                  icon="home"
                                  placeholder="Straße"
                                  type="text"/>

          <BaseInputFloatinglabel v-if="form.customer"
                                  v-model="form.customer.zip"
                                  icon="map-marker-alt"
                                  placeholder="PLZ"
                                  type="text"/>

          <BaseInputFloatinglabel v-if="form.customer"
                                  v-model="form.customer.city"
                                  icon="city"
                                  placeholder="Stadt"
                                  type="text"/>

          <BaseInputFloatinglabel v-if="form.customer"
                                  v-model="form.customer.country"
                                  icon="flag"
                                  placeholder="Land"
                                  type="text"/>

        </div>
      </div>

      <div class="action">
        <BaseButton :loading="printing"
                     :disabled="checkForm || printing"
                     icon="print"
                     label="Drucken"
                     :submit="true"
                     @click="printCustomerContact"/>
      </div>
    </form>
  </base-modal>

</template>

<script>
import print from 'print-js'
import axios from 'axios'
import { cloneDeep } from 'lodash'

export default {
  name: 'CustomerContactPrint',
  props: {
    customerContact: {}
  },
  data () {
    return {
      form: {},
      printing: false
    }
  },
  created () {
    this.form = cloneDeep(this.customerContact)
  },
  methods: {
    async printCustomerContact () {
      try {
        this.printing = true
        const response = await axios.post(process.env.VUE_APP_API_URL + '/print/customer_contact', { customerContact: this.form }, { responseType: 'blob' })
        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileUrl = window.URL.createObjectURL(file)
        print(fileUrl)
        this.printing = false
      } catch (error) {
        this.printing = false
      }
    }
  },
  computed: {
    checkForm () {
      return !(this.form.firstName && this.form.lastName)
    }
  }
}
</script>
