<template>

  <base-modal name="mediacontact-company-create"
              title="Unternehmen erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <mediacontact-company-form :mediacontactCompany="mediacontactCompany" type="create"/>
  </base-modal>

</template>

<script>
import MediacontactCompanyForm from './MediacontactCompanyForm.vue'

export default {
  name: 'MediacontactCompanyCreate',
  components: { MediacontactCompanyForm },
  data: () => ({
    mediacontactCompany: {
      name: null
    }
  })
}
</script>
