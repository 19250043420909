<template>

  <form v-on:submit.prevent="">

    <div class="middle extended" v-if="type === 'edit'">
      <div class="group">
        <div class="title">Unternehmen</div>
        <multiselect v-model="form.mediacontactPartner.company"
                     :options="mediacontactCompanies"
                     :show-labels="false"
                     label="name"
                     placeholder="Unternehmen"
                     :loading="loading">
          <template #placeholder>
            <font-awesome-icon icon="building"/>
            <span>Unternehmen</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="building"/>
            <span>{{ props.option.name }}</span>
          </template>
        </multiselect>

        <div class="title">Medienpartner</div>
        <BaseInputFloatinglabel v-model="form.mediacontactPartner.name"
                                icon="layer-group"
                                placeholder="Name"
                                type="text"/>

        <multiselect v-model="form.mediacontactPartner.categories"
                     :multiple="true"
                     :options="mediacontactPartnerCategories"
                     :show-labels="false"
                     label="name"
                     placeholder="Media Kategorien"
                     track-by="id"
                     :loading="loading">
          <template #placeholder>
            <font-awesome-icon icon="th"/>
            <span>Media Kategorien</span>
          </template>
        </multiselect>

        <BaseTextarea v-model="form.mediacontactPartner.description"
                      icon="sticky-note"
                      placeholder="Beschreibung"/>

        <div class="title">Mediadaten</div>
        <BaseInputFloatinglabel v-model.number="form.mediacontactPartner.uniqueUser"
                                placeholder="Unique User"
                                icon="user-plus"
                                type="number"/>

        <BaseInputFloatinglabel v-model.number="form.mediacontactPartner.visits"
                                placeholder="Visits"
                                icon="mouse"
                                type="number"/>

        <BaseInputFloatinglabel v-model.number="form.mediacontactPartner.printCount"
                                icon="print"
                                placeholder="Printauflage"
                                type="number"/>

        <div class="title">Kanäle</div>
        <BaseInputFloatinglabel v-model="form.mediacontactPartner.website"
                                icon="link"
                                placeholder="Website"
                                type="url"/>
        <BaseInputFloatinglabel v-model.number="form.mediacontactPartner.facebookPageName"
                                :icon="['fab', 'facebook']"
                                placeholder="Facebook Seitenname"
                                type="text"/>
        <BaseInputFloatinglabel v-model.number="form.mediacontactPartner.facebookPageLikesCount"
                                :icon="['fab', 'facebook']"
                                placeholder="Facebook Likes"
                                type="number"/>
        <BaseInputFloatinglabel v-model.number="form.mediacontactPartner.instagramProfileName"
                                :icon="['fab', 'instagram']"
                                placeholder="Instagram Profilname"
                                type="text"/>
        <BaseInputFloatinglabel v-model.number="form.mediacontactPartner.instagramProfileFollowerCount"
                                :icon="['fab', 'instagram']"
                                placeholder="Instagram Follower"
                                type="number"/>
        <BaseInputFloatinglabel v-model.number="form.mediacontactPartner.youtubeChannelId"
                                :icon="['fab', 'youtube']"
                                placeholder="YouTube Channel ID"
                                type="text"/>
        <BaseInputFloatinglabel v-model.number="form.mediacontactPartner.youtubeChannelSubscriberCount"
                                :icon="['fab', 'youtube']"
                                placeholder="YouTube Abonnenten"
                                type="number"/>

      </div>
      <div class="group">

        <ProjectMediacontactPartnerFeatureList
          :projectMediacontactPartner="projectMediacontactPartner"/>

        <BaseCheckbox v-model="form.rejectedAll"
                      :id="form.id"
                      field="rejectedAll"
                      placeholder="Absage generell"/>

      </div>
    </div>

    <div class="action">
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProjectMediacontactPartnerFeatureList from '@/components/project/projectPublicity/projectMediacontactPartner/projectMediacontactPartnerFeature/ProjectMediacontactPartnerFeatureList'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectMediacontactPartnerForm',
  components: { ProjectMediacontactPartnerFeatureList },
  props: {
    projectMediacontactPartner: Object,
    type: String
  },
  data () {
    return {
      form: {
        mediacontactPartner: {
          company: null
        }
      },
      updating: false,
      removing: false,
      loading: true
    }
  },
  created () {
    this.form = cloneDeep(this.projectMediacontactPartner)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      await Promise.all([
        this.mediacontactPartnerCategories.length || this.getMediacontactPartnerCategories(),
        this.mediacontactCompanies.length || this.getMediacontactCompanies()
      ])
      this.loading = false
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateProjectMediacontactPartner({
          id: this.form.id,
          data: this.form
        })
        this.$vfm.hide('project-mediacontact-partner-edit')
        this.$toasted.success('Medienpartner Kooperation mit ' + this.form.mediacontactPartner.name + ' gespeichert', { icon: 'fas fa-layer-group' })
        this.updating = false
        this.getProjectMediacontactPartner({ id: response.data.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectMediacontactPartner({ id: this.form.id })
        this.$vfm.hide('project-mediacontact-partner-edit')
        this.$toasted.success('Medienpartner Kooperation mit ' + this.form.mediacontactPartner.name + ' gelöscht', { icon: 'fas fa-layer-group' })
        this.removing = false
      } catch (error) {
        this.updating = false
      }
    },
    ...mapActions({
      updateProjectMediacontactPartner: 'projectMediacontactPartners/replace',
      deleteProjectMediacontactPartner: 'projectMediacontactPartners/destroy',
      getProjectMediacontactPartner: 'projectMediacontactPartners/fetchSingle',
      getMediacontactCompanies: 'mediacontactCompanies/fetchList',
      getMediacontactPartnerCategories: 'mediacontactPartnerCategories/fetchList'
    })
  },
  computed: {
    isManager () {
      return (this.project && this.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    checkForm () {
      return false
    },
    ...mapGetters({
      projectById: 'projects/byId',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      mediacontactCompanies: 'mediacontactCompanies/list',
      mediacontactPartnerCategories: 'mediacontactPartnerCategories/list'
    })
  }
}
</script>
