import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectListMediacontactMaterials',
  urlRoot: '/project_list_mediacontact_materials',
  getters: {
    byProjectListMediacontact: (state) => (id) => {
      return Object.values(state.entities).filter(projectListMediacontactMaterial => projectListMediacontactMaterial.projectListMediacontact === '/project_list_mediacontacts/' + id)
    }
  }
})
