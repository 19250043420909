import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'customers',
  urlRoot: '/customers',
  state: {
    filter: {
      search: ''
    }
  }
})
