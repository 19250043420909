<template>

  <base-modal name="project-ad-budget-create"
              title="Budget hinzufügen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <project-ad-budget-form :projectAdBudget="projectAdBudget" type="create"></project-ad-budget-form>
  </base-modal>

</template>

<script>
import ProjectAdBudgetForm from './ProjectAdBudgetForm.vue'

export default {
  name: 'ProjectAdBudgetCreate',
  components: { ProjectAdBudgetForm },
  data: () => ({
    projectAdBudget: {
      project: null,
      adCreation: false
    }
  }),
  created () {
    this.projectAdBudget.project = '/projects/' + this.$route.params.projectId
  }
}
</script>
