import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectListMediacontacts',
  urlRoot: '/project_list_mediacontacts',
  getters: {
    byProjectList: (state) => (id) => {
      return Object.values(state.entities).filter(projectListMediacontact => projectListMediacontact.projectList === '/project_lists/' + id)
    }
  }
})
