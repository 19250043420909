<template>

    <form v-on:submit.prevent="">
        <BaseInputFloatinglabel v-model="form.name"
                                icon="th"
                                placeholder="Name" type="text"/>

        <div class="action">
          <BaseButton v-if="type === 'create'"
                       :loading="creating"
                       :disabled="checkForm || creating"
                       icon="plus"
                       label="Erstellen"
                       :submit="true"
                       @click="create"/>
          <BaseButton v-if="hasManagerRole && type === 'edit'"
                       :loading="removing"
                       :disabled="removing"
                       icon="trash"
                       label="Löschen"
                       :remove="true"
                       @click="remove"/>
          <BaseButton v-if="type === 'edit'"
                       :loading="updating"
                       :disabled="checkForm || updating"
                       icon="save"
                       label="Speichern"
                       :submit="true"
                       @click="update"/>
        </div>

    </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'MediacontactPartnerCategoryForm',
  props: ['mediacontactPartnerCategory', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.mediacontactPartnerCategory)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        const response = await this.createMediacontactPartnerCategory({ data: this.form })
        this.$vfm.hide('mediacontact-partner-category-create')
        this.$toasted.success('Media Kategorie ' + response.data.name + ' erstellt', { icon: 'fas fa-th' })
        this.creating = false
        await this.getMediacontactPartnerCategories()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateMediacontactPartnerCategory({ id: this.form.id, data: this.form })
        this.$vfm.hide('mediacontact-partner-category-edit')
        this.$toasted.success('Media Kategorie ' + response.data.name + ' aktualisiert', { icon: 'fas fa-th' })
        this.updating = false
        await this.getMediacontactPartnerCategory({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteMediacontactPartnerCategory({ id: this.form.id })
        this.$vfm.hide('mediacontact-partner-category-edit')
        this.$toasted.success('Media Kategorie gelöscht', { icon: 'fas fa-th' })
        this.removing = false
        await this.getMediacontactPartnerCategories()
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      getMediacontactPartnerCategory: 'mediacontactPartnerCategories/fetchSingle',
      getMediacontactPartnerCategories: 'mediacontactPartnerCategories/fetchList',
      createMediacontactPartnerCategory: 'mediacontactPartnerCategories/create',
      updateMediacontactPartnerCategory: 'mediacontactPartnerCategories/replace',
      deleteMediacontactPartnerCategory: 'mediacontactPartnerCategories/destroy'
    })
  },
  computed: {
    checkForm () {
      return !(this.form.name)
    },
    ...mapGetters({
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
