<template>

    <div class="input-wrapper-floatinglabel">
        <font-awesome-icon :icon="icon"/>
        <input
            :placeholder="placeholder"
            :type="type"
            :value="value"
            :step="step"
            autocomplete="off"
            required @input="$emit('input', $event.target.value)"/>
        <label>
            {{ placeholder }}
        </label>
    </div>

</template>

<script>
export default {
  name: 'BaseInputFloatinglabel',
  props: {
    type: String,
    step: String,
    placeholder: String,
    icon: [String, Array],
    value: [String, Number]
  }
}
</script>

<style lang="scss">

/* Textfield Floating label */
.input-wrapper-floatinglabel {
    position: relative;
    height: 44px;
    min-width: 200px;
    margin: 0 0 15px 0;
    display: flex;
    flex-flow: row nowrap;
}

.input-wrapper-floatinglabel svg {
    position: absolute;
    left: 12px;
    bottom: 14px;
    color: $text-color-3;
}

/* Textfield Floating label > Input */
.input-wrapper-floatinglabel input {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 36px;
    width: 100%;
    height: 44px;
    color: $text-color;
    border-radius: 5px;
    border: 1px solid $gray-300;
}

.input-wrapper-floatinglabel input + label {
    position: absolute;
    bottom: 16px;
    left: 34px;
    color: $text-color-3;
    cursor: text;
    font-size: 1rem;
    transition: all 0.3s ease;
    pointer-events: none;
    opacity: 0;

    background-color: $white;
    padding: 0 4px;
}

.input-wrapper-floatinglabel input + label svg {
    font-size: 1rem;
}

.input-wrapper-floatinglabel input:focus {
    border: 2px solid $gray-900;
}

.input-wrapper-floatinglabel input:focus + label {
    bottom: 36px;
    font-size: 0.75rem;
    color: $text-color;
    opacity: 1;
    background-color: $white;
}

.input-wrapper-floatinglabel input:focus:valid + label {
    color: $text-color;
}

.input-wrapper-floatinglabel input:valid + label {
    bottom: 36px;
    font-size: 0.75rem;
    color: $text-color-3;
    opacity: 1;
    background-color: $white;
}

.input-wrapper-floatinglabel input:disabled + label {
    bottom: 32px;
    font-size: 0.75rem;
    color: $text-color-3;
    opacity: 1;
}

#app.dark {

    .input-wrapper-floatinglabel input {
        border-color: $gray-800;
    }

    .input-wrapper-floatinglabel svg {
        color: $gray-500;
    }

    .input-wrapper-floatinglabel input + label {
        color: $gray-700;
        background-color: $black-2;
    }

    .input-wrapper-floatinglabel input:focus + label {
        color: $white;
    }

    .input-wrapper-floatinglabel input:focus {
        border-color: $white;
    }

}

</style>
