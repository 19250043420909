<template>

  <base-modal name="project-material-edit"
              :title="projectMaterial.material.name"
              toptitle="Material bearbeiten"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <project-material-form :projectMaterial="projectMaterial" type="edit"/>
  </base-modal>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProjectMaterialForm from './ProjectMaterialForm.vue'

export default {
  name: 'ProjectMaterialEdit',
  components: { ProjectMaterialForm },
  props: {
    projectMaterial: Object
  },
  data: () => ({
    loading: true
  }),
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      await Promise.all([
        // this.project || this.getProject({ id: this.$route.params.projectId })
      ])
      this.loading = false
    },
    ...mapActions({
      getProject: 'projects/fetchSingle'
    })
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    ...mapGetters({
      projectById: 'projects/byId'
    })
  }
}
</script>
