<template>

  <base-modal name="customer-contact-edit"
              toptitle="Ansprechpartner bearbeiten"
              :title="`${customerContact.firstName} ${customerContact.lastName}`"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <customer-contact-form :customerContact="customerContact" type="edit"></customer-contact-form>
  </base-modal>

</template>

<script>
import CustomerContactForm from './CustomerContactForm.vue'

export default {
  name: 'CustomerContactEdit',
  components: { CustomerContactForm },
  props: {
    customerContact: {
      type: Object,
      default: () => ({
        firstName: null,
        lastName: null,
        customer: {}
      })
    }
  }
}
</script>
