<template>

    <base-list-item>

        <div class="content">
            <div class="icon">
                <img v-if="user.pictureName" :src="picture" class="picture"/>
                <font-awesome-icon v-else :icon="userIcon(user)" size="lg" />
            </div>
            <div class="middle">

                <div class="col user-name">
                    {{ user.position }}
                    <div class="title">{{ user.firstName }} {{ user.lastName }}</div>
                    <a v-if="user.email" :href="'mailto: ' + user.email">{{ user.email }}</a>
                </div>

                <div class="col user-teams">
                    <div class="subtitle">{{ user.teams.join(', ') }}</div>
                </div>

                <div class="col user-phone">
                    <div class="subtitle" v-if="user.phone">{{ user.phone }}</div>
                    <div v-else>-</div>
                </div>

                <div class="col user-mobile">
                    <div class="subtitle" v-if="user.mobile">{{ user.mobile }}</div>
                    <div v-else>-</div>
                </div>

                <div class="col user-roles">
                    <div class="subtitle">{{ user.roles.map(role => customRoleLabel(role)).join(', ') }}</div>
                </div>

                <div class="col user-status" v-if="!user.isActive">
                    Deaktiviert
                </div>

            </div>

        </div>

        <div class="action">
            <button v-if="hasChiefRole || isSelf(user)" class="button button-flat" @click="$vfm.show({component: 'UserEdit', bind: { user }})">
                <font-awesome-icon icon="pen" size="sm" />
            </button>
        </div>

    </base-list-item>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserRow',
  props: ['user'],
  methods: {
    userIcon (user) {
      switch (user.roles[0]) {
        case 'ROLE_ADMIN':
          return 'user-shield'
        case 'ROLE_CHIEF':
          return 'user-cog'
        case 'ROLE_MANAGER':
          return 'user-check'
        case 'ROLE_USER':
          return 'user-alt'
        case 'ROLE_TRAINEE':
          return 'user-graduate'
      }
    },
    customRoleLabel (role) {
      switch (role) {
        case 'ROLE_ADMIN':
          return 'Administrator'
        case 'ROLE_CHIEF':
          return 'Geschäftsführer'
        case 'ROLE_MANAGER':
          return 'Manager'
        case 'ROLE_USER':
          return 'Mitarbeiter'
        case 'ROLE_TRAINEE':
          return 'Azubi'
        case 'ROLE_BETA':
          return 'Beta Features'
        case 'ROLE_NEARBY':
          return 'In der Nähe'
      }
    },
    isSelf (user) {
      return user.id === this.authUser.id || this.authUser.roles.indexOf('ROLE_ADMIN') > -1
    }
  },
  computed: {
    picture () {
      return process.env.VUE_APP_API_URL + '/files/users/' + this.user.id + '/' + this.user.pictureName
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasChiefRole: 'auth/hasChiefRole'
    })
  }
}
</script>
