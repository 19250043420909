<template>

  <base-modal name="business-contact-company-edit"
              toptitle="Unternehmen bearbeiten"
              :title="businessContactCompany.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <business-contact-company-form :businessContactCompany="businessContactCompany" type="edit"/>
  </base-modal>

</template>

<script>
import BusinessContactCompanyForm from './BusinessContactCompanyForm.vue'

export default {
  name: 'BusinessContactCompanyEdit',
  components: { BusinessContactCompanyForm },
  props: {
    businessContactCompany: Object
  }
}
</script>
