import createCrudModule from '@/utils/createCrudModule'
import moment from 'moment'
import { orderBy } from 'lodash'

export default createCrudModule({
  resource: 'projects',
  urlRoot: '/projects',
  state: {
    filter: {
      search: '',
      departments: [],
      categories: [],
      customers: [],
      users: [],
      status: [{ name: 'Aktiv', value: true }],
      sortBy: { name: 'Datum', value: 'releaseDate' },
      sortDirection: { name: 'Aufsteigend', value: 'asc' },
      group: true
    }
  },
  getters: {
    filtered: state => {
      return orderBy(Object.values(state.entities), state.filter.sortBy.value, state.filter.sortDirection.value)
        .filter(project => project.title.toLowerCase().indexOf(state.filter.search.toLowerCase()) > -1)
        .filter(project => !state.filter.departments.length || state.filter.departments.some(filterDepartment => project.departments && project.departments.some(projectDepartment => projectDepartment === filterDepartment)))
        .filter(project => !state.filter.categories.length || state.filter.categories.some(category => category === project.category))
        .filter(project => !state.filter.customers.length || state.filter.customers.some(customer => customer.id === project.customer.id))
        .filter(project => !state.filter.users.length || state.filter.users.some(user => project.managers.some(manager => manager.id === user.id)))
        .filter(project => !state.filter.status.length || state.filter.status.some(status => status.value === project.isActive))
    },
    grouped: (state, getters) => {
      const projectsByMonth = {}
      getters.filtered.forEach(project => {
        let group
        switch (state.filter.sortBy.name) {
          case 'Datum':
            if (project.releaseDate) {
              group = moment(project.releaseDate).format('MMM YYYY')
            } else {
              group = 'TBC'
            }
            if (!projectsByMonth[group]) {
              projectsByMonth[group] = []
            }
            projectsByMonth[group].push(project)
            break
          case 'Kunde':
            group = project.customer.name
            if (!projectsByMonth[group]) {
              projectsByMonth[group] = []
            }
            projectsByMonth[group].push(project)
            break
          case 'Manager':
            group = project.managers.name
            // iterate through all project managers and create or add to existing groups
            project.managers.forEach(manager => {
              const group = manager.firstName
              if (!projectsByMonth[group]) {
                projectsByMonth[group] = []
              }
              projectsByMonth[group].push(project)
            })
            break
        }
      })
      return projectsByMonth
    },
    active: state => {
      return Object.values(state.entities).filter(project => project.isActive)
    },
    activeByUser: state => id => {
      return orderBy(Object.values(state.entities).filter(project => project.isActive && project.managers.some(user => user.id === id)), ['estDate', 'releaseDate'], 'asc')
    },
    groupedActiveByUser: (state, getters) => id => {
      const projectsByMonth = {}
      getters.activeByUser(id).forEach(project => {
        let group
        if (project.releaseDate) {
          group = moment(project.releaseDate).format('MMM YYYY')
        } else {
          group = 'TBC'
        }
        if (!projectsByMonth[group]) {
          projectsByMonth[group] = []
        }
        projectsByMonth[group].push(project)
      })
      return projectsByMonth
    }
  }
})
