<template>

  <base-modal name="business-contact-view"
              toptitle="B2B-Kontakt"
              :title="`${businessContact.firstName} ${businessContact.lastName}`"
              :subtitle="subtitle"
              :width="1200"
              v-on="$listeners"
              v-bind="$attrs">

    <div class="middle">
      <div class="group">
        <div class="title">Name</div>
        <div class="info-item" v-if="businessContact.firstName">
          <div class="label">Vorname</div>
          <font-awesome-icon icon="user"/>
          {{ businessContact.firstName }}
        </div>
        <div class="info-item" v-if="businessContact.lastName">
          <div class="label">Nachname</div>
          <font-awesome-icon icon="user"/>
          {{ businessContact.lastName }}
        </div>
        <div class="info-item" v-if="businessContact.position">
          <div class="label">Position</div>
          <font-awesome-icon icon="sitemap"/>
          {{ businessContact.position }}
        </div>
      </div>

      <div class="group">
        <div class="title">Kontakt</div>
        <div class="info-item" v-if="businessContact.email">
          <div class="label">E-Mail</div>
          <font-awesome-icon icon="envelope"/>
          <a v-if="businessContact.email" :href="'mailto:'+businessContact.email">{{ businessContact.email }}</a>
        </div>
        <div class="info-item" v-if="businessContact.phone">
          <div class="label">Telefon</div>
          <font-awesome-icon icon="phone-alt"/>
          {{ businessContact.phone }}
        </div>
        <div class="info-item" v-if="businessContact.fax">
          <div class="label">Fax</div>
          <font-awesome-icon icon="fax"/>
          {{ businessContact.fax }}
        </div>
        <div class="info-item" v-if="businessContact.mobile">
          <div class="label">Mobil</div>
          <font-awesome-icon icon="mobile-alt"/>
          {{ businessContact.mobile }}
        </div>
      </div>

      <div class="group">
        <div class="title">Unternehmen & Kategorien</div>
        <div class="info-item" v-if="businessContact.company">
          <div class="label">Unternehmen</div>
          <font-awesome-icon icon="building"/>
          <div>{{ businessContact.company.name }}</div>
        </div>
        <div class="info-item" v-if="businessContact.category">
          <div class="label">Kategorie</div>
          <font-awesome-icon icon="tags"/>
          <div>{{ businessContact.category.name }}</div>
        </div>
        <div class="info-item" v-if="businessContact.topics && businessContact.topics.length">
          <div class="label">Schwerpunkte</div>
          <font-awesome-icon icon="th"/>
          <span class="chip" v-for="topic in businessContact.topics" :key="topic.id">{{ topic.name }}</span>
        </div>
        <div class="info-item" v-if="businessContact.notes">
          <div class="label">Notizen</div>
          <font-awesome-icon icon="sticky-note"/>
          <div class="text">{{ businessContact.notes }}</div>
        </div>
      </div>
    </div>

    <div class="action">
      <button class="button button-flat" @click="edit">
        <font-awesome-icon icon="pen" size="sm"/>
        <span>Bearbeiten</span>
      </button>
    </div>

  </base-modal>

</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'BusinessContactView',
  props: {
    businessContact: Object
  },
  methods: {
    edit () {
      this.$vfm.hide('business-contact-view')
      this.$vfm.show({ component: 'BusinessContactEdit', bind: { businessContact: this.businessContact } })
    }
  },
  computed: {
    subtitle () {
      if (!this.businessContact.updatedBy || this.businessContact.updatedAt) return 'Noch nie geändert'

      return `Letzte Änderung von ${this.updatedBy} ${this.updatedAt}`
    },
    updatedBy () {
      return this.businessContact.updatedBy.id === this.authUser.id ? 'mir' : this.businessContact.updatedBy.firstName
    },
    updatedAt () {
      return moment(this.businessContact.updatedAt).fromNow()
    },
    ...mapGetters({
      authUser: 'auth/authUser'
    })
  }
}
</script>
