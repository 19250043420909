<template>

  <form v-on:submit.prevent="">
    <div class="middle">

      <div class="group">
        <div class="title">Unternehmen</div>
        <multiselect v-model="form.company"
                     :options="mediacontactCompanies"
                     :show-labels="false"
                     label="name"
                     placeholder="Unternehmen"
                     :loading="loading">
          <template #placeholder>
            <font-awesome-icon icon="building"/>
            <span>Unternehmen</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="building"/>
            <span>{{ props.option.name }}</span>
          </template>
        </multiselect>

        <div class="title">Medienpartner</div>
        <BaseInputFloatinglabel v-model="form.name"
                                icon="layer-group"
                                placeholder="Name"
                                type="text"/>

        <BaseTextarea v-model="form.description"
                      icon="sticky-note"
                      placeholder="Beschreibung"/>

        <div class="title">Mediadaten</div>

        <BaseInputFloatinglabel v-model.number="form.uniqueUser"
                                placeholder="Unique User"
                                icon="user-plus"
                                type="number"/>

        <BaseInputFloatinglabel v-model.number="form.visits"
                                placeholder="Visits"
                                icon="mouse"
                                type="number"/>

        <BaseInputFloatinglabel v-model.number="form.printCount"
                                icon="print"
                                placeholder="Printauflage"
                                type="number"/>
      </div>

      <div class="group">
        <div class="title">Kanäle</div>
        <BaseInputFloatinglabel v-model="form.website"
                                icon="link"
                                placeholder="Website"
                                type="url"/>

        <BaseInputFloatinglabel v-model.number="form.facebookPageName"
                                :icon="['fab', 'facebook']"
                                placeholder="Facebook Seitenname"
                                type="text"/>
        <BaseInputFloatinglabel v-model.number="form.facebookPageLikesCount"
                                :icon="['fab', 'facebook']"
                                placeholder="Facebook Likes Anzahl"
                                type="number"/>
        <BaseInputFloatinglabel v-model.number="form.instagramProfileName"
                                :icon="['fab', 'instagram']"
                                placeholder="Instagram Profilname"
                                type="text"/>
        <BaseInputFloatinglabel v-model.number="form.instagramProfileFollowerCount"
                                :icon="['fab', 'instagram']"
                                placeholder="Instagram Follower Anzahl"
                                type="number"/>
        <BaseInputFloatinglabel v-model.number="form.youtubeChannelId"
                                :icon="['fab', 'youtube']"
                                placeholder="YouTube Channel ID"
                                type="text"/>
        <BaseInputFloatinglabel v-model.number="form.youtubeChannelSubscriberCount"
                                :icon="['fab', 'youtube']"
                                placeholder="YouTube Abonnenten Anzahl"
                                type="number"/>
      </div>

    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="hasManagerRole && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { cloneDeep } from 'lodash'

export default {
  name: 'MediacontactPartnerForm',
  props: ['mediacontactPartner', 'type'],
  data () {
    return {
      form: {},
      loading: true,
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.mediacontactPartner)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      await Promise.all([
        this.mediacontactCompanies.length || this.getMediacontactCompanies()
      ])
      this.loading = false
    },
    async create () {
      try {
        this.creating = true
        this.form.updatedBy = 'users/' + this.authUser.id
        this.form.updatedAt = moment().format()
        const response = await this.createMediacontactPartner({ data: this.form })
        this.$vfm.hide('mediacontact-partner-create')
        this.$toasted.success('Medienpartner ' + response.data.name + ' erstellt', { icon: 'fas fa-layer-group' })
        this.creating = false
        await this.getMediacontactPartners()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        this.form.updatedBy = 'users/' + this.authUser.id
        this.form.updatedAt = moment().format()
        const response = await this.updateMediacontactPartner({ id: this.form.id, data: this.form })
        this.$vfm.hide('mediacontact-partner-edit')
        this.$toasted.success('Medienpartner ' + response.data.name + ' aktualisiert', { icon: 'fas fa-layer-group' })
        this.updating = false
        await this.getMediacontactPartner({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteMediacontactPartner({ id: this.form.id })
        this.$vfm.hide('mediacontact-partner-edit')
        this.$toasted.success('Medienpartner gelöscht', { icon: 'fas fa-layer-group' })
        this.removing = false
        await this.getMediacontactPartners()
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      getMediacontactCompanies: 'mediacontactCompanies/fetchList',
      getMediacontactPartner: 'mediacontactPartners/fetchSingle',
      getMediacontactPartners: 'mediacontactPartners/fetchList',
      createMediacontactPartner: 'mediacontactPartners/create',
      updateMediacontactPartner: 'mediacontactPartners/replace',
      deleteMediacontactPartner: 'mediacontactPartners/destroy'
    })
  },
  computed: {
    checkForm () {
      return !this.form.name
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      mediacontactCompanies: 'mediacontactCompanies/list'
    })
  }
}
</script>
