<template>

  <base-modal name="mediacontact-partner-category-create"
              title="Media Kategorie erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <mediacontact-partner-category-form :mediacontactPartnerCategory="mediacontactPartnerCategory" type="create"/>
  </base-modal>

</template>

<script>
import MediacontactPartnerCategoryForm from './MediacontactPartnerCategoryForm.vue'

export default {
  name: 'MediacontactPartnerCategoryCreate',
  components: { MediacontactPartnerCategoryForm },
  data: () => ({
    mediacontactPartnerCategory: {
      name: null
    }
  })
}
</script>
