<template>

  <base-modal toptitle="Medienkontakt"
              :title="`${mediacontact.firstName} ${mediacontact.middleName} ${mediacontact.lastName}`"
              :subtitle="lastUpdate"
              name="mediacontact-view"
              :width="1200"
              v-on="$listeners"
              v-bind="$attrs">

      <div class="middle">

        <div class="group">
          <div class="title">Name</div>
          <div v-if="mediacontact.firstName" class="info-item">
            <div class="label">Vorname</div>
            <font-awesome-icon icon="user-edit" size="lg"/>
            {{ mediacontact.firstName }}
          </div>

          <div v-if="mediacontact.middleName" class="info-item">
            <div class="label">Zweiter Vorname</div>
            <font-awesome-icon icon="user-edit" size="lg"/>
            {{ mediacontact.middleName }}
          </div>

          <div v-if="mediacontact.lastName" class="info-item">
            <div class="label">Nachname</div>
            <font-awesome-icon icon="user-edit" size="lg"/>
            {{ mediacontact.lastName }}
          </div>

          <div v-if="mediacontact.jobTitle" class="info-item">
            <div class="label">Jobtitel</div>
            <font-awesome-icon icon="sitemap" size="lg"/>
            {{ mediacontact.jobTitle }}
          </div>

          <div class="title">Kontakt</div>

          <div v-if="mediacontact.email" class="info-item">
            <div class="label">E-Mail</div>
            <font-awesome-icon icon="envelope" size="lg"/>
            <a :href="'mailto:'+mediacontact.email">{{ mediacontact.email }}</a>
          </div>

          <div v-if="mediacontact.phone" class="info-item">
            <div class="label">Telefon</div>
            <font-awesome-icon icon="phone-alt" size="lg"/>
            {{ mediacontact.phone }}
          </div>

          <div v-if="mediacontact.fax" class="info-item">
            <div class="label">Fax</div>
            <font-awesome-icon icon="fax" size="lg"/>
            {{ mediacontact.fax }}
          </div>

          <div v-if="mediacontact.mobile" class="info-item">
            <div class="label">Mobil</div>
            <font-awesome-icon icon="mobile-alt" size="lg"/>
            {{ mediacontact.mobile }}
          </div>

        </div>

        <div class="group">

          <div class="title">Anschrift</div>
          <div v-if="mediacontact.street" class="info-item">
            <div class="label">Straße</div>
            <font-awesome-icon icon="home" size="lg"/>
            {{ mediacontact.street }}
          </div>

          <div v-if="mediacontact.zip" class="info-item">
            <div class="label">PLZ</div>
            <font-awesome-icon icon="map-marker-alt" size="lg"/>
            {{ mediacontact.zip }}
          </div>

          <div v-if="mediacontact.city" class="info-item">
            <div class="label">Stadt</div>
            <font-awesome-icon icon="city" size="lg"/>
            {{ mediacontact.city }}
          </div>

          <div v-if="mediacontact.country" class="info-item">
            <div class="label">Land</div>
            <font-awesome-icon icon="flag" size="lg"/>
            {{ mediacontact.country }}
          </div>

        </div>

        <div class="group">
          <div class="title">Kategorien</div>

          <div v-if="mediacontact.productTypes && mediacontact.productTypes.length" class="info-item">
            <div class="label">Produktarten</div>
            <font-awesome-icon icon="tags" size="lg"/>
            <span v-for="productType in mediacontact.productTypes" :key="productType.id"
                  class="chip">
                        {{ productType.name }}
                    </span>
          </div>

          <div v-if="mediacontact.departments && mediacontact.departments.length" class="info-item">
            <div class="label">Bereiche</div>
            <font-awesome-icon icon="tags" size="lg"/>
            <span v-for="mediacontactDepartment in mediacontact.departments" :key="mediacontactDepartment"
                  class="chip">
                        {{ mediacontactDepartment }}
                    </span>
          </div>

          <div v-if="mediacontact.partnerCategories && mediacontact.partnerCategories.length" class="info-item">
            <div class="label">Media Kategorien</div>
            <font-awesome-icon icon="th" size="lg"/>
            <span v-for="mediacontactPartnerCategory in mediacontact.partnerCategories"
                  :key="mediacontactPartnerCategory.id"
                  class="chip">
                        {{ mediacontactPartnerCategory.name }}
                    </span>
          </div>

          <div v-if="mediacontact.partners && mediacontact.partners.length" class="info-item">
            <div class="label">Medienpartner</div>
            <font-awesome-icon icon="layer-group" size="lg"/>
            <span v-for="partner in mediacontact.partners"
                  :key="partner.id"
                  class="chip">
                        {{ partner.name }}
                    </span>
          </div>

          <div v-if="mediacontact.regions && mediacontact.regions.length" class="info-item">
            <div class="label">Regionen</div>
            <font-awesome-icon icon="map-marker-alt" size="lg"/>
            <span v-for="mediacontactRegion in mediacontact.regions" :key="mediacontactRegion.id" class="chip">
                        {{ mediacontactRegion.name }}
                    </span>
          </div>

          <div
            v-if="mediacontact.inquiryCase || mediacontact.contests || mediacontact.interviews || mediacontact.pressPreview"
            class="info-item">
            <div class="label">Sonstiges</div>
            <font-awesome-icon icon="check-square" size="lg"/>
            <span v-if="mediacontact.inquiryCase" class="chip">Einzelfall</span>
            <span v-if="mediacontact.contests" class="chip">Gewinnspiel</span>
            <span v-if="mediacontact.interviews" class="chip">Interview</span>
            <span v-if="mediacontact.pressPreview" class="chip">Pressevorführung</span>
          </div>

          <div v-if="mediacontact.notes" class="info-item">
            <div class="label">Notizen</div>
            <font-awesome-icon icon="sticky-note" size="lg"/>
            <div class="text">{{ mediacontact.notes }}</div>
          </div>
        </div>

      </div>

      <div class="action">
        <button class="button button-flat" @click="edit">
          <font-awesome-icon icon="pen" size="sm"/>
          <span>Bearbeiten</span>
        </button>
      </div>

  </base-modal>

</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'MediacontactView',
  props: {
    mediacontact: Object
  },
  methods: {
    edit () {
      this.$vfm.hide('mediacontact-view')
      this.$vfm.show({ component: 'MediacontactEdit', bind: { mediacontact: this.mediacontact } })
    }
  },
  computed: {
    lastUpdate () {
      if (!this.mediacontact.updatedBy || !this.mediacontact.updatedAt) return 'Noch nie geändert'

      return `Letzte Änderung von ${this.updatedBy} ${this.updatedAt}`
    },
    updatedBy () {
      return this.mediacontact.updatedBy.id === this.authUser.id ? 'mir' : this.mediacontact.updatedBy.firstName
    },
    updatedAt () {
      return moment(this.mediacontact.updatedAt).fromNow()
    },
    ...mapGetters({
      authUser: 'auth/authUser'
    })
  }
}
</script>
