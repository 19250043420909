<template>

    <base-modal name="mediacontact-company-edit"
                toptitle="Unternehmen bearbeiten"
                :title="mediacontactCompany.name"
                :width="400"
                v-on="$listeners"
                v-bind="$attrs">
            <mediacontact-company-form :mediacontactCompany="mediacontactCompany" type="edit"></mediacontact-company-form>
    </base-modal>

</template>

<script>
import MediacontactCompanyForm from './MediacontactCompanyForm.vue'

export default {
  name: 'MediacontactCompanyEdit',
  components: { MediacontactCompanyForm },
  props: {
    mediacontactCompany: Object
  }
}
</script>
