<template>

  <base-modal name="influencer-create"
              title="Influencer erstellen"
              :width="1200"
              v-on="$listeners"
              v-bind="$attrs">
      <influencer-form :influencer="influencer" type="create"/>
  </base-modal>

</template>

<script>
import InfluencerForm from './InfluencerForm.vue'

export default {
  name: 'InfluencerCreate',
  components: { InfluencerForm },
  data: () => ({
    influencer: {}
  })
}
</script>
