<template>

    <base-modal name="mediacontact-partner-create"
                title="Medienpartner erstellen"
                :width="800"
                v-on="$listeners"
                v-bind="$attrs">
            <mediacontact-partner-form :mediacontactPartner="mediacontactPartner" type="create"/>
    </base-modal>

</template>

<script>
import MediacontactPartnerForm from './MediacontactPartnerForm.vue'

export default {
  name: 'MediacontactPartnerCreate',
  components: { MediacontactPartnerForm },
  data: () => ({
    mediacontactPartner: {}
  })
}
</script>
