<template>

  <BaseModal name="project-list-edit"
             :width="400"
             toptitle="Verteiler bearbeiten"
             :title="projectList.name"
             v-on="$listeners"
             v-bind="$attrs">
    <project-list-form :projectList="projectList" type="edit"/>
  </BaseModal>

</template>

<script>
import ProjectListForm from './ProjectListForm.vue'

export default {
  name: 'ProjectListEdit',
  components: { ProjectListForm },
  props: {
    projectList: Object
  }
}
</script>
