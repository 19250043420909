<template>

  <base-modal name="influencer-network-create"
              :width="400"
              title="Netzwerk erstellen"
              v-on="$listeners"
              v-bind="$attrs">
    <influencer-network-form
      :influencerNetwork="influencerNetwork"
      type="create"/>
  </base-modal>

</template>

<script>
import InfluencerNetworkForm from './InfluencerNetworkForm.vue'

export default {
  name: 'InfluencerNetworkCreate',
  components: { InfluencerNetworkForm },
  data: () => ({
    influencerNetwork: {
      name: null
    }
  })
}
</script>
