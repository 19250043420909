import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'mediacontactCompanies',
  urlRoot: '/mediacontact_companies',
  state: {
    filter: {
      search: ''
    }
  }
})
