import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'mediacontactPartner',
  urlRoot: '/mediacontact_partners',
  state: {
    filter: {
      search: '',
      sortBy: 'name',
      sortDirection: 'asc'
    }
  }
})
