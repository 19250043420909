<template>
  <base-list-item>
    <div class="content">
      <div class="middle">
        <div class="col project-mediacontact-partner-feature-type">
          <multiselect v-model="form.type"
                       :options="['News', 'Trailer', 'Review', 'Gewinnspiel', 'Interview', 'Exklusives Video']"
                       placeholder="Feature"
                       :show-labels="false"
                       :hide-selected="true"
                       @input="updateFeature">
            <template #singleLabel="props">
              <font-awesome-icon :icon="projectMediacontactPartnerFeatureIcon(props.option)"/>
              <span>{{ props.option }}</span>
            </template>
          </multiselect>
        </div>
        <div class="col project-mediacontact-partner-feature-status">
          <multiselect v-model="form.status"
                       :options="['Zusage', 'Absage', 'Veröffentlicht', 'Anfrage', 'Angebot', 'TBC']"
                       placeholder="Status"
                       :show-labels="false"
                       :hide-selected="true"
                       @input="updateFeature">
            <template #singleLabel="props">
              <font-awesome-icon :icon="projectMediacontactPartnerFeatureStatusIcon(props.option)"/>
              <span>{{ props.option }}</span>
            </template>
          </multiselect>
        </div>

        <div class="col project-mediacontact-partner-feature-date">
          <v-date-picker
            v-model="form.date"
            :popover="{ visibility: 'click' }"
            :is-dark="darkmodeStatus"
            color="blue"
            is24hr
            mode="date"
            title-position="left"
            @input="updateFeature"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="input-wrapper-floatinglabel">
                <font-awesome-icon icon="calendar-day"/>
                <input
                  placeholder="Datum"
                  type="text"
                  :value="inputValue"
                  required
                  v-on="inputEvents" />
                <label>
                  Datum
                </label>
              </div>
            </template>
          </v-date-picker>
        </div>

        <div class="col project-mediacontact-partner-feature-link">
          <BaseInputFloatinglabel v-model="form.link"
                                  icon="link"
                                  placeholder="Link"
                                  type="text"
                                  @input="updateFeature"/>
        </div>
        <div class="col project-mediacontact-partner-feature-text">
          <BaseInputFloatinglabel v-model="form.text"
                                  icon="comment-alt"
                                  placeholder="Anmerkungen"
                                  type="text"
                                  @input="updateFeature"/>
        </div>
      </div>
    </div>
    <div class="action">
      <button type="button" class="button button-flat button-edit"
              @click="edit"
              :disabled="removing">
        <font-awesome-icon v-if="!updating" icon="pen" size="sm"/>
        <clip-loader :loading="updating" color="#9E9E9E" size="20px"/>
        <span>Bearbeiten</span>
      </button>
    </div>
  </base-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'

export default {
  name: 'ProjectMediacontactFeatureListItem',
  props: {
    projectMediacontactPartner: Object,
    projectMediacontactPartnerFeature: Object
  },
  data () {
    return {
      form: null,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = this.projectMediacontactPartnerFeature
  },
  methods: {
    updateFeature: debounce(async function () {
      try {
        this.updating = true
        await this.updateProjectMediacontactPartnerFeature({ id: this.form.id, data: this.form })
        this.$toasted.success('Beitrag auf ' + this.projectMediacontactPartner.mediacontactPartner.name + ' gespeichert', { icon: 'fas fa-newspaper' })
        this.updating = false
      } catch (error) {
        this.updating = false
      }
    }, 250),
    clippingUrl (url) {
      return `${process.env.VUE_APP_API_URL}${url}`
    },
    edit () {
      this.$vfm.hide('project-mediacontact-partner-edit')
      this.$vfm.show({ component: 'ProjectMediacontactPartnerFeatureEdit', bind: { projectMediacontactPartnerFeature: this.projectMediacontactPartnerFeature } })
    },
    projectMediacontactPartnerFeatureIcon (type) {
      switch (type) {
        case 'News':
          return 'newspaper'
        case 'Trailer':
          return 'video'
        case 'Review':
          return 'images'
        case 'Gewinnspiel':
          return 'gift'
        case 'Interview':
          return 'comment-alt'
        case 'Exklusives Video':
          return 'star'
        default:
          return ''
      }
    },
    projectMediacontactPartnerFeatureStatusIcon (status) {
      switch (status) {
        case 'Zusage':
          return 'check-circle'
        case 'Absage':
          return 'times-circle'
        case 'Veröffentlicht':
          return 'desktop'
        case 'Anfrage':
          return 'bell'
        case 'Angebot':
          return 'paper-plane'
        case 'TBC':
          return 'pause'
        default:
          return ''
      }
    },
    ...mapActions({
      updateProjectMediacontactPartnerFeature: 'projectMediacontactPartnerFeatures/replace',
      deleteProjectMediacontactPartnerFeature: 'projectMediacontactPartnerFeatures/destroy'
    })
  },
  computed: {
    featureDate () {
      return this.projectMediacontactPartnerFeature.date ? moment(this.projectMediacontactPartnerFeature.date).format('D.MM.Y') : '-'
    },
    featureText () {
      return this.projectMediacontactPartnerFeature.text ? this.projectMediacontactPartnerFeature.text.substr(0, 20) + '...' : '-'
    },
    ...mapGetters({
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>
