<template>

  <base-modal name="influencer-add"
              title="Influencer zu Projekt hinzufügen"
              :subtitle="`${selected.length} ausgewählt`"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <form v-on:submit.prevent="">
      <div>
        <label>Projekt</label>
        <multiselect v-model="project"
                     :options="projects"
                     track-by="id"
                     label="title"
                     placeholder="Auswählen"
                     selectLabel="Auswählen"
                     deselectLabel="Entfernen"
                     selectedLabel="Ausgewählt"
                     :loading="loading">
        </multiselect>
      </div>

      <div class="action">
        <BaseButton :loading="creating"
                     :disabled="checkForm || creating"
                     icon="plus"
                     label="Hinzufügen"
                     :submit="true"
                     @click="proceed"/>
      </div>
    </form>
  </base-modal>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InfluencerAdd',
  props: {
    selected: Array
  },
  data () {
    return {
      project: null,
      loading: true,
      creating: false
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.projects.length || await this.getProjects()
      this.loading = false
    },
    async proceed () {
      this.creating = true
      await Promise.all(this.selected.map(influencer => {
        return this.createProjectInfluencer({
          data: {
            project: '/projects/' + this.project.id,
            influencer: '/influencers/' + influencer.id
          }
        })
      }))
      await this.getProjectInfluencers({ customUrl: '/projects/' + this.project.id + '/project_influencers' })
      await this.getProject({ id: this.project.id })
      this.$vfm.hide('influencer-add')
      this.$router.push({ name: 'projectSocialMedia', params: { projectId: this.project.id } })
      this.creating = false
    },
    ...mapActions({
      getProjects: 'projects/fetchList',
      getProject: 'projects/fetchSingle',
      createProjectInfluencer: 'projectInfluencers/create',
      getProjectInfluencers: 'projectInfluencers/fetchList'
    })
  },
  computed: {
    projects () {
      return this.activeProjectsByUser(this.authUser.id)
    },
    checkForm () {
      return !(this.project && this.project.id)
    },
    ...mapGetters({
      activeProjectsByUser: 'projects/activeByUser',
      authUser: 'auth/authUser'
    })
  }
}
</script>
