<template>

  <base-modal name="customer-contact-view"
              toptitle="Ansprechpartner"
              :title="`${customerContact.firstName} ${customerContact.lastName}`"
              :subtitle="customerContact.position"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <div class="middle">
      <div class="group">
        <div class="info-item">
          <div class="label">E-Mail</div>
          <font-awesome-icon icon="envelope"/>
          <a :href="`mailto:${customerContact.email}`">{{ customerContact.email }}</a>
        </div>

        <div class="info-item" v-if="customerContact.phone">
          <div class="label">Telefon</div>
          <font-awesome-icon icon="phone-alt"/>
          {{ customerContact.phone }}
        </div>

        <div class="info-item" v-if="customerContact.mobile">
          <div class="label">Mobil</div>
          <font-awesome-icon icon="mobile-alt"/>
          {{ customerContact.mobile }}
        </div>
      </div>
    </div>

    <div class="action">
      <base-button icon="pen"
                   label="Bearbeiten"
                   :submit="true"
                   @click="edit"/>
    </div>
  </base-modal>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CustomerContactView',
  props: {
    customerContact: Object
  },
  methods: {
    edit () {
      this.$vfm.hide('customer-contact-view')
      this.$vfm.show({ component: 'CustomerContactEdit', bind: { customerContact: this.customerContact } })
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'auth/authUser'
    })
  }
}
</script>
