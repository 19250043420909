import { render, staticRenderFns } from "./ProjectMediacontactPartnerFeatureCreate.vue?vue&type=template&id=5be51aba"
import script from "./ProjectMediacontactPartnerFeatureCreate.vue?vue&type=script&lang=js"
export * from "./ProjectMediacontactPartnerFeatureCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports