<template>

  <base-modal name="project-ad-material-create"
         title="Werbemittel hinzufügen"
         :width="800"
              v-on="$listeners"
              v-bind="$attrs">

      <form v-if="!showForm">
        <div>
          <label>Vorlage</label>
          <multiselect v-model="presets"
                       :options="adTypePresets"
                       placeholder="Auswählen"
                       :multiple="true"
                       group-values="options"
                       group-label="platform"
                       :group-select="true"
                       track-by="name"
                       :custom-label="customAdTypeLabel"
                       :show-labels="false"
                       :close-on-select="false">
          </multiselect>
        </div>
        <div class="action">
          <button v-if="!presets.length"
                  type="submit"
                  class="button button-flat"
                  @click="showForm = true">
            <span>Weiter ohne Vorlage</span>
          </button>
          <button v-if="presets.length"
                  type="submit"
                  class="button button-flat"
                  @click="add"
                  :disabled="creating">
            <font-awesome-icon icon="plus" size="sm" v-if="!creating"/>
            <span v-if="!creating">{{ presets.length }} hinzufügen</span>
            <clip-loader :loading="creating" color="#9E9E9E" size="20px"></clip-loader>
          </button>
        </div>
      </form>

      <project-ad-material-form v-if="showForm"
                                :projectAdMaterial="projectAdMaterial"
                                type="create">
      </project-ad-material-form>

  </base-modal>

</template>

<script>
import { mapActions } from 'vuex'
import ProjectAdMaterialForm from './ProjectAdMaterialForm.vue'
import adTypePresets from './adMaterialPresets.json'

export default {
  name: 'ProjectAdMaterialCreate',
  components: { ProjectAdMaterialForm },
  data: () => ({
    showForm: false,
    presets: [],
    adTypePresets: adTypePresets,
    creating: false,
    projectAdMaterial: {
      project: null,
      isHtml5: false,
      isAnimated: false,
      status: 'Offen'
    }
  }),
  created () {
    this.projectAdMaterial.project = '/projects/' + this.$route.params.projectId
  },
  methods: {
    async add () {
      try {
        this.creating = true
        await Promise.all(this.presets.map(async preset => {
          const projectAdMaterial = {
            project: '/projects/' + this.$route.params.projectId,
            isHtml5: false,
            isAnimated: false
          }
          Object.assign(projectAdMaterial, preset)
          await this.createProjectAdCampaign({ data: projectAdMaterial })
        }))
        await this.getProject({ id: this.$route.params.projectId })
        this.$vfm.hide('project-ad-material-create')
        this.$toasted.success(this.presets.length + ' Werbemittel hinzugefügt')
        this.creating = false
      } catch (error) {
        this.creating = false
      }
    },
    customAdTypeLabel (value) {
      return value.specs + ' ' + value.name
    },
    ...mapActions({
      createProjectAdCampaign: 'projectAdMaterials/create',
      getProject: 'projects/fetchSingle'
    })
  }
}
</script>
