import Vue from 'vue'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faFacebook,
  faInstagram,
  faSlack,
  faSpotify,
  faTiktok,
  faTwitch,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

import {
  faAd,
  faArchive,
  faArrowDown,
  faArrowRight,
  faArrowUp,
  faBars, faBell,
  faBiking,
  faBolt,
  faBook,
  faBug,
  faBuilding,
  faBullhorn,
  faBullseye,
  faBusinessTime,
  faCalculator,
  faCalendarAlt,
  faCalendarDay, faCamera,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCircleNotch,
  faCity,
  faClipboardCheck,
  faClock,
  faCloud,
  faCloudDownloadAlt,
  faCloudMeatball,
  faCloudRain,
  faCloudSun,
  faCloudSunRain,
  faCloudUploadAlt,
  faCoffee,
  faCog,
  faCoins,
  faComment, faCommentAlt,
  faCommentDots,
  faCompactDisc,
  faCompass,
  faDesktop,
  faDollarSign,
  faDotCircle,
  faEnvelope,
  faEnvelopeOpenText,
  faEquals,
  faEuroSign,
  faFax,
  faFileAlt,
  faFileContract,
  faFileExport,
  faFileImage,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFileVideo,
  faFilm,
  faFilter,
  faFireAlt,
  faFlag,
  faGift,
  faGrinStars,
  faHandshake,
  faHashtag,
  faHome,
  faImages,
  faKey,
  faLaptopHouse,
  faLayerGroup,
  faLightbulb,
  faLink,
  faListUl,
  faMapMarkerAlt,
  faMobileAlt,
  faMoneyCheckAlt,
  faMoon,
  faMouse,
  faMusic,
  faNewspaper, faPaperPlane, faPause,
  faPauseCircle,
  faPen,
  faPhoneAlt,
  faPlus,
  faPodcast,
  faPrint,
  faProjectDiagram, faQuoteRight,
  faRandom,
  faReceipt,
  faRuler,
  faSave,
  faSearch,
  faServer,
  faShapes,
  faShareAlt, faShoppingBag,
  faSignInAlt,
  faSignOutAlt,
  faSitemap,
  faSnowflake,
  faSort,
  faSortAmountDown,
  faSortDown,
  faStar,
  faStickyNote,
  faStream,
  faSun,
  faTachometerAlt,
  faTags,
  faTasks,
  faTh,
  faTimes,
  faTimesCircle, faToggleOn,
  faTrash,
  faTv,
  faUser,
  faUserAlt,
  faUserCheck,
  faUserCircle,
  faUserClock,
  faUserCog,
  faUserEdit,
  faUserFriends,
  faUserGraduate,
  faUserPlus,
  faUsers,
  faUserShield,
  faUserTag,
  faUserTie,
  faVideo,
  faVirus,
  faWallet,
  faWater
} from '@fortawesome/free-solid-svg-icons'

import { faSquare } from '@fortawesome/free-regular-svg-icons'

library.add(faBell, faSignInAlt, faPaperPlane, faPause, faQuoteRight, faSignInAlt, faCommentAlt, faCamera, faToggleOn, faVirus, faHashtag, faPodcast, faChevronDown, faChevronUp, faShareAlt, faSort, faArchive, faRuler, faUserPlus, faMouse, faLink, faFax, faSlack, faMobileAlt, faKey, faLaptopHouse, faFacebook, faDesktop, faTwitch, faTiktok, faInstagram, faYoutube, faTwitter, faCommentDots, faFilm, faAd, faVideo, faBuilding, faTachometerAlt, faTasks, faUsers, faUserFriends, faUserCircle, faCog, faSignOutAlt, faGift, faUserEdit, faGrinStars, faUserTie, faMapMarkerAlt, faTags, faUserTag, faBullseye, faPlus, faChevronLeft, faPen, faFileInvoiceDollar, faImages, faListUl, faCloudUploadAlt, faCheckCircle, faLightbulb, faTimesCircle, faBullhorn, faComment, faPrint, faStar, faSearch, faEnvelope, faTrash, faFileExport, faSave, faFireAlt, faNewspaper, faUserShield, faShapes, faMoon, faSun, faCloudSun, faCloud, faCloudSunRain, faSnowflake, faBolt, faCloudRain, faWater, faCloudMeatball, faArrowUp, faArrowDown, faUserAlt, faUserCheck, faUserCog, faUserGraduate, faFilter, faCompactDisc, faMusic, faBook, faBug, faCloudDownloadAlt, faFilePdf, faFileVideo, faFileImage, faSortDown, faDollarSign, faClock, faStream, faRandom, faServer, faCompass, faCalendarDay, faUserClock, faCalendarAlt, faFileInvoice, faCalculator, faCircleNotch, faDotCircle, faPauseCircle, faHome, faLayerGroup, faMoneyCheckAlt, faFileAlt, faFileContract, faEquals, faArrowRight, faBiking, faTimes, faTh, faSquare, faCheckDouble, faHandshake, faEuroSign, faReceipt, faWallet, faClipboardCheck, faEnvelopeOpenText, faCoins, faSortAmountDown, faBars, faPhoneAlt, faSitemap, faCoffee, faUser, faBusinessTime, faPodcast, faSpotify, faFlag, faCity, faProjectDiagram, faStickyNote, faCheckSquare, faMusic, faTv, faShoppingBag)

dom.watch()

Vue.component('font-awesome-icon', FontAwesomeIcon)
