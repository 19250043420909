<template>

    <form v-on:submit.prevent="">
        <BaseInputFloatinglabel v-model="form.name"
                                icon="map-marker-alt"
                                placeholder="Name" type="text"/>

        <div class="action">
          <BaseButton v-if="type === 'create'"
                       :loading="creating"
                       :disabled="checkForm || creating"
                       icon="plus"
                       label="Erstellen"
                       :submit="true"
                       @click="create"/>
          <BaseButton v-if="hasManagerRole && type === 'edit'"
                       :loading="removing"
                       :disabled="removing"
                       icon="trash"
                       label="Löschen"
                       :remove="true"
                       @click="remove"/>
          <BaseButton v-if="type === 'edit'"
                       :loading="updating"
                       :disabled="checkForm || updating"
                       icon="save"
                       label="Speichern"
                       :submit="true"
                       @click="update"/>
        </div>

    </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'MediacontactRegionForm',
  props: ['mediacontactRegion', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.mediacontactRegion)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        const response = await this.createMediacontactRegion({ data: this.form })
        this.$vfm.hide('mediacontact-region-create')
        this.$toasted.success('Region ' + response.data.name + ' erstellt', { icon: 'fas fa-map-marker-alt' })
        this.creating = false
        await this.getMediacontactRegions()
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateMediacontactRegion({ id: this.form.id, data: this.form })
        this.$vfm.hide('mediacontact-region-edit')
        this.$toasted.success('Region ' + response.data.name + ' aktualisiert', { icon: 'fas fa-map-marker-alt' })
        this.updating = false
        await this.getMediacontactRegion({ id: this.form.id })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteMediacontactRegion({ id: this.form.id })
        this.$vfm.hide('mediacontact-region-edit')
        this.$toasted.success('Region gelöscht', { icon: 'fas fa-map-marker-alt' })
        this.removing = false
        await this.getMediacontactRegions()
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      getMediacontactRegion: 'mediacontactRegions/fetchSingle',
      getMediacontactRegions: 'mediacontactRegions/fetchList',
      createMediacontactRegion: 'mediacontactRegions/create',
      updateMediacontactRegion: 'mediacontactRegions/replace',
      deleteMediacontactRegion: 'mediacontactRegions/destroy'

    })
  },
  computed: {
    checkForm () {
      return !(this.form.name)
    },
    ...mapGetters({
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
