<template>

  <form v-on:submit.prevent="">

    <multiselect v-model="form.costCenter"
                 :custom-label="costCenterLabel"
                 :options="[1, 2, 3]"
                 :show-labels="false"
                 placeholder="Kostenstelle">
      <template #placeholder>
        <font-awesome-icon icon="file-contract"/>
        <span>Kostenstelle</span>
      </template>
      <template #singleLabel="props">
        <font-awesome-icon icon="file-contract"/>
        <span>{{ costCenterLabel(props.option) }}</span>
      </template>
    </multiselect>

    <BaseInputFloatinglabel v-model="form.fee"
                            icon="euro-sign"
                            placeholder="Honorar"
                            step="0.01"
                            type="number"/>

    <BaseTextarea v-model="form.notes"
                  icon="sticky-note"
                  placeholder="Anmerkungen"/>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectAccountingDealForm',
  props: ['projectAccountingDeal', 'type'],
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.projectAccountingDeal)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        await this.createProjectAccountingDeal({ data: this.form })
        this.$vfm.hide('project-accounting-deal-create')
        this.$toasted.success('Deal erstellt', { icon: 'fas fa-file-contract' })
        this.creating = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        await this.updateProjectAccountingDeal({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-accounting-deal-edit')
        this.$toasted.success('Deal gespeichert', { icon: 'fas fa-file-contract' })
        this.updating = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectAccountingDeal({ id: this.form.id })
        this.$vfm.hide('project-accounting-deal-edit')
        this.$toasted.success('Deal gelöscht', { icon: 'fas fa-file-contract' })
        this.removing = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.removing = false
      }
    },
    costCenterLabel (costCenter) {
      switch (costCenter) {
        case 1:
          return 'PR'
        case 2:
          return 'Social Media'
        case 3:
          return 'Marketing'
      }
    },
    ...mapActions({
      createProjectAccountingDeal: 'projectAccountingDeals/create',
      updateProjectAccountingDeal: 'projectAccountingDeals/replace',
      deleteProjectAccountingDeal: 'projectAccountingDeals/destroy',
      getProjectAccountingDeals: 'projectAccountingDeals/fetchList',
      getProject: 'projects/fetchSingle'
    })
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    isManager () {
      return (this.project && this.project.managers && this.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    checkForm () {
      return false
    },
    ...mapGetters({
      projectById: 'projects/byId',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      hasChiefRole: 'auth/hasChiefRole'
    })
  }
}
</script>
