<template>
    <div>
        <BaseList title="Projekte" icon="stream" :count="filteredActiveProjectsByUser.length">
            <template #action>
                <button v-if="hasManagerRole" class="button button-flat button-dark"
                        @click="$vfm.show({component: 'ProjectCreate'})">
                    <font-awesome-icon icon="plus" size="sm"/>
                    <span>Erstellen</span>
                </button>
            </template>
            <template #groups v-if="!loading">
                <div v-for="(items, date, index) in groupedActiveProjectsByUser" :key="index" class="group">
                    <div class="top">
                        <div class="subtitle">
                            <font-awesome-icon icon="calendar-day" size="sm"/>
                            {{ date }}
                            <span>{{ items.length }}</span>
                        </div>
                    </div>
                    <div class="cards">
                        <DashboardProjectListItem v-for="project in items"
                                                  :key="project.id"
                                                  :project="project"/>
                    </div>
                </div>
            </template>
            <skeleton-row :show="loading"></skeleton-row>
            <not-found-row entity="Projekte" :show="!loading && !filteredActiveProjectsByUser.length"></not-found-row>
        </BaseList>

    </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SkeletonRow from '@/components/SkeletonRow.vue'
import NotFoundRow from '@/components/NotFoundRow.vue'
import DashboardProjectListItem from '@/components/dashboard/dashboardProject/DashboardProjectListItem'

export default {
  name: 'DashboardProjectList',
  components: {
    DashboardProjectListItem,
    NotFoundRow,
    SkeletonRow
  },
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      await Promise.all([
        this.projects.length || await this.getProjects(),
        this.projectBriefings.length || await this.getProjectBriefings()
      ])
      this.loading = false
    },
    ...mapActions({
      getProjects: 'projects/fetchList',
      getProjectBriefings: 'projectBriefings/fetchList'
    })
  },
  computed: {
    filteredActiveProjectsByUser () {
      return this.authUser.roles.some(role => role === 'ROLE_CHIEF') ? this.projects : this.activeProjectsByUser(this.authUser.id)
    },
    groupedActiveProjectsByUser () {
      return this.authUser.roles.some(role => role === 'ROLE_CHIEF') ? this.groupedProjects : this.groupedActiveByUser(this.authUser.id)
    },
    ...mapGetters({
      projects: 'projects/active',
      groupedProjects: 'projects/grouped',
      projectBriefings: 'projectBriefings/list',
      activeProjectsByUser: 'projects/activeByUser',
      groupedActiveByUser: 'projects/groupedActiveByUser',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
