import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectAccountingItems',
  urlRoot: '/project_accounting_items',
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectAccountingItem => projectAccountingItem.project === '/projects/' + id)
    },
    customerNetByProject: (state, getters) => id => {
      return getters.byProject(id)
        .filter(projectAdCampaign => projectAdCampaign.revenueAmount)
        .map(projectAdCampaign => parseFloat(projectAdCampaign.revenueAmount))
        .reduce((cur, acc) => cur + acc, 0)
    },
    agencyNetByProject: (state, getters) => id => {
      return getters.byProject(id)
        .filter(projectAccountingItem => projectAccountingItem.spendingAmount)
        .map(projectAccountingItem => parseFloat(projectAccountingItem.spendingAmount))
        .reduce((cur, acc) => cur + acc, 0)
    },
    amountByProject: (state, getters) => id => {
      return getters.customerNetByProject(id) - getters.agencyNetByProject(id)
    }
  }
})
