import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'businessContacts',
  urlRoot: '/business_contacts',
  state: {
    filter: {
      search: '',
      categories: [],
      topics: [],
      companies: [],
      status: [{ name: 'Aktiv', value: true }],
      sortBy: 'name',
      sortDirection: 'asc'
    }
  }
})
