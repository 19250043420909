<template>

    <base-modal :toptitle="`${projectBriefing.project.title} - Briefing`"
                :title="projectBriefing.name"
                :subtitle="`von ${updatedBy} ${updatedAt}`"
                name="project-briefing-view"
                :width="400"
                v-on="$listeners"
                v-bind="$attrs">

        <section v-if="projectBriefing" class="modal">

            <div class="top">
                <div class="toptitle">{{ projectBriefing.project.title }} - Briefing</div>
                <div class="title">
                    {{ projectBriefing.name }}
                </div>
                <div class="subtitle">von {{ updatedBy }} {{ updatedAt }}</div>
                <div class="close" @click="$vfm.hide('project-briefing-view')">
                    <font-awesome-icon icon="times" size="lg"/>
                </div>
            </div>

            <div class="middle">
                <div class="group">
                    <div v-if="projectBriefing.category" class="info-item">
                        <div class="label">Kategorie</div>
                        <font-awesome-icon icon="clipboard-check"/>
                        <div>{{ projectBriefing.category }}</div>
                    </div>
                    <div v-if="projectBriefing.description" class="info-item">
                        <div class="label">Inhalt</div>
                        <font-awesome-icon icon="sticky-note"/>
                        <div class="text">{{ projectBriefing.description }}</div>
                    </div>
                    <div v-if="projectBriefing.usersInCharge.length" class="info-item">
                        <div class="label">Verantwortliche</div>
                        <font-awesome-icon icon="users"/>
                        <div>
                            <span v-for="user in projectBriefing.usersInCharge" :key="user.id" class="chip">
                                {{ user.firstName }}
                            </span>
                        </div>
                    </div>
                    <div v-if="projectBriefing.status" class="info-item">
                        <div class="label">Status</div>
                        <font-awesome-icon :icon="statusIconType" :style="statusIconColor" size="sm"/>
                        {{ projectBriefing.status }}
                    </div>
                </div>
            </div>

            <div class="action">
                <button v-if="isManager" class="button button-flat" @click="edit">
                    <font-awesome-icon icon="pen" size="sm"/>
                    <span>Bearbeiten</span>
                </button>
            </div>

        </section>

    </base-modal>

</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ProjectBriefingView',
  props: {
    projectBriefing: Object
  },
  methods: {
    edit () {
      this.$vfm.hide('project-briefing-view')
      this.$vfm.show({ component: 'ProjectBriefingEdit', bind: { projectBriefing: this.projectBriefing } })
    }
    // adMaterial
    // addAdMaterial() {
    //     this.projectBriefing.fields.adMaterials.push({
    //         name: "",
    //         specs: "",
    //         size: null,
    //         ratio: "",
    //         html: false,
    //         animation: false,
    //         framerate: null,
    //         duration: null,
    //         bitrate: null,
    //         fileType: null,
    //         deadline: null,
    //         status: "Noch erledigen",
    //         fileTypes: ["JPG", "PNG", "GIF", "HTML-Zip", "CSS", "JS", "SVG", "H.264", "QuickTime"]
    //     })
    // },
    // adPlatform
    // addAdPlatform() {
    //     this.projectBriefing.fields.adPlatforms.push({
    //         "publisher": null,
    //         "placement": null,
    //         "format": null,
    //         "customerNet": null,
    //         "agencyNet": null,
    //         "adCreation": false,
    //         "period": null,
    //         "contact": null,
    //         "campaignStatus": "In Absprache",
    //         "invoiceStatus": "Offen",
    //     })
    // }
  },
  computed: {
    statusIconType () {
      switch (this.projectBriefing.status) {
        case 'Gebucht':
          return 'check-circle'
        case 'Idee':
          return 'lightbulb'
        case 'Nicht gebucht':
          return 'times-circle'
      }
      return ''
    },
    statusIconColor () {
      switch (this.projectBriefing.status) {
        case 'Gebucht':
          return { color: '#4CAF50' }
        case 'Idee':
          return { color: '#FFC107' }
        case 'Nicht gebucht':
          return { color: '#FF5722' }
        default:
          return { color: '#000000' }
      }
    },
    updatedBy () {
      return this.projectBriefing.updatedBy.id === this.authUser.id ? 'mir' : this.projectBriefing.updatedBy.firstName
    },
    updatedAt () {
      return moment(this.projectBriefing.updatedAt).fromNow()
    },
    isManager () {
      return this.projectBriefing.project.managers.some(user => user.id === this.authUser.id) || this.hasManagerRole
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole'
    })
  }
}
</script>
