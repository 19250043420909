<template>

    <base-modal name="user-edit"
                toptitle="Mitarbeiter bearbeiten"
                :title="`${user.firstName} ${user.lastName}`"
                :width="800"
                v-on="$listeners"
                v-bind="$attrs">
            <user-form :user="user" type="edit"></user-form>
    </base-modal>

</template>

<script>
import UserForm from './UserForm.vue'

export default {
  name: 'UserEdit',
  components: { UserForm },
  props: {
    user: Object
  }
}
</script>
