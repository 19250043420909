import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'projectAccountingInvoices',
  urlRoot: '/project_accounting_invoices',
  getters: {
    byProject: (state) => (id) => {
      return Object.values(state.entities).filter(projectAccountingInvoice => projectAccountingInvoice.project === '/projects/' + id)
    },
    amountByProject: (state, getters) => id => {
      return getters.byProject(id)
        .filter(projectAccountingInvoice => projectAccountingInvoice.amount)
        .map(projectAccountingInvoice => parseFloat(projectAccountingInvoice.amount))
        .reduce((cur, acc) => cur + acc, 0)
    },
    adsAmountByProject: (state, getters) => id => {
      return getters.byProject(id)
        .filter(projectAccountingInvoice => projectAccountingInvoice.amount && projectAccountingInvoice.costCenter === 3 && projectAccountingInvoice.isPublic)
        .map(projectAccountingInvoice => parseFloat(projectAccountingInvoice.amount))
        .reduce((cur, acc) => cur + acc, 0)
    }
  }
})
