<template>

  <base-modal name="project-accounting-item-create"
              title="Buchung erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <project-accounting-item-form :projectAccountingItem="projectAccountingItem" type="create"/>
  </base-modal>

</template>

<script>
import ProjectAccountingItemForm from './ProjectAccountingItemForm.vue'

export default {
  name: 'ProjectAccountingItemCreate',
  components: { ProjectAccountingItemForm },
  data: () => ({
    projectAccountingItem: {
      project: null,
      revenueInvoice: false,
      spendingInvoice: false,
      isPublic: true
    }
  }),
  created () {
    this.projectAccountingItem.project = '/projects/' + this.$route.params.projectId
  }
}
</script>
