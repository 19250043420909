<template>

  <form v-on:submit.prevent="">

    <multiselect v-if="type === 'create'"
                 v-model="influencer"
                 :options="filteredInfluencers"
                 label="name"
                 track-by="id"
                 placeholder="Influencer"
                 :loading="loading"
                 :show-labels="false">
      <template #placeholder>
        <font-awesome-icon icon="grin-stars"/>
        <span>Influencer</span>
      </template>
      <template #singleLabel="props">
        <font-awesome-icon icon="grin-stars"/>
        <span>{{ props.option.name }}</span>
      </template>
    </multiselect>

    <BaseInputFloatinglabel v-model="form.feature"
                            icon="envelope-open-text"
                            placeholder="Feature"
                            type="text"/>

    <BaseInputFloatinglabel v-model="form.link"
                            icon="link"
                            placeholder="Link"
                            type="text"/>

    <BaseInputFloatinglabel v-model.number="form.fee"
                            icon="euro-sign"
                            placeholder="Kosten"
                            type="text"/>

    <BaseTextarea v-model="form.cooperation"
                  icon="handshake"
                  placeholder="Kooperation"/>
    <div>
      <label>Status</label>
      <multiselect v-model="form.status"
                   :options="['Vorgeschlagen', 'Angefragt', 'In Absprache', 'Gebucht']"
                   placeholder="Status"
                   :show-labels="false">
      </multiselect>
    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Hinzufügen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep, orderBy } from 'lodash'

export default {
  name: 'InfluencerForm',
  props: {
    projectInfluencer: Object,
    type: String
  },
  data () {
    return {
      influencer: null,
      form: {},
      loading: true,
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.projectInfluencer)
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.influencers.length || await this.getInfluencers()
      this.loading = false
    },
    async create () {
      try {
        this.creating = true
        const response = await this.createProjectInfluencer({
          data: {
            ...this.form,
            project: '/projects/' + this.$route.params.projectId,
            influencer: '/influencers/' + this.influencer.id
          }
        })
        this.$vfm.hide('project-influencer-create')
        this.$toasted.success('Influencer Kooperation mit ' + response.data.influencer.name + ' erstellt', { icon: 'fa fa-grin-stars' })
        this.creating = false
        await this.getProjectInfluencers()
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        const response = await this.updateProjectInfluencer({ id: this.form.id, data: this.form })
        this.$vfm.hide('project-influencer-edit')
        this.$toasted.success('Influencer Kooperation mit ' + response.data.influencer.name + ' gespeichert', { icon: 'fa fa-grin-stars' })
        this.updating = false
        await this.getProjectInfluencers()
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectInfluencer({ id: this.form.id })
        this.$vfm.hide('project-influencer-edit')
        this.$toasted.success('Influencer Kooperation gelöscht', { icon: 'fa fa-grin-stars' })
        this.removing = false
        await this.getProjectInfluencers()
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      getInfluencers: 'influencers/fetchList',
      createProjectInfluencer: 'projectInfluencers/create',
      updateProjectInfluencer: 'projectInfluencers/replace',
      deleteProjectInfluencer: 'projectInfluencers/destroy',
      getProjectInfluencers: 'projectInfluencers/fetchList',
      getProject: 'projects/fetchSingle'
    })
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    isManager () {
      return (this.form.project && this.form.project.managers && this.form.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    filteredInfluencers () {
      return orderBy(this.influencers, 'name', 'ASC')
    },
    checkForm () {
      return false
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      influencers: 'influencers/list',
      projectById: 'projects/byId'
    })
  }
}
</script>
