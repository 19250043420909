import Vue from 'vue'
import router from '../../router'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refresh_token') || '',
    pending: false,
    error: null
  },
  mutations: {
    login: (state) => {
      state.pending = true
    },
    success: (state, payload) => {
      state.token = payload.token
      state.refreshToken = payload.refresh_token
      state.pending = false
      localStorage.setItem('token', payload.token)
      localStorage.setItem('refresh_token', payload.refresh_token)
      axios.defaults.headers.common.Authorization = 'Bearer ' + payload.token
    },
    error: (state, payload) => {
      state.error = payload
      state.pending = false
    },
    logout: (state) => {
      state.token = ''
      state.refreshToken = ''
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      delete axios.defaults.headers.common.Authorization
    }
  },
  actions: {
    async login ({ commit }, creds) {
      commit('login')
      try {
        const response = await axios.post('/authentication_token', {
          email: creds.email,
          password: creds.password
        })
        commit('success', response.data)
      } catch (error) {
        commit('error', error)
        Vue.toasted.error('E-Mail oder Passwort falsch!', { icon: 'fas fa-user' })
      }
    },
    async refreshToken ({ commit, dispatch }) {
      try {
        const response = await axios.post('/token/refresh', {
          refresh_token: localStorage.getItem('refresh_token')
        })
        commit('success', response.data)
        return response
      } catch (error) {
        commit('error', error)
        dispatch('logout')
        router.push({ name: 'login' })
        Vue.toasted.error('Sitzung abgelaufen!')
        return Promise.reject(error)
      }
    },
    logout: ({ commit }) => {
      commit('logout')
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authUser: state => state.token ? jwtDecode(state.token) : '',
    hasUserRole: (state, getters) => getters.authUser && getters.authUser.roles.some(role => role === 'ROLE_USER' || role === 'ROLE_MANAGER' || role === 'ROLE_CHIEF' || role === 'ROLE_ADMIN'),
    hasManagerRole: (state, getters) => getters.authUser && getters.authUser.roles && getters.authUser.roles.some(role => role === 'ROLE_MANAGER' || role === 'ROLE_CHIEF' || role === 'ROLE_ADMIN'),
    hasChiefRole: (state, getters) => getters.authUser && getters.authUser.roles.some(role => role === 'ROLE_CHIEF' || role === 'ROLE_ADMIN'),
    hasAdminRole: (state, getters) => getters.authUser && getters.authUser.roles.some(role => role === 'ROLE_ADMIN'),
    hasBetaRole: (state, getters) => getters.authUser && getters.authUser.roles.some(role => role === 'ROLE_BETA'),
    hasNearbyRole: (state, getters) => getters.authUser && getters.authUser.roles.some(role => role === 'ROLE_NEARBY')
  }
}
