import createCrudModule from '@/utils/createCrudModule'

export default createCrudModule({
  resource: 'influencers',
  urlRoot: '/influencers',
  state: {
    filter: {
      search: '',
      category: null,
      subCategories: [],
      audiences: [],
      platforms: [],
      regions: [],
      network: null,
      sortBy: 'name',
      sortDirection: 'asc'
    }
  }
})
