<template>

  <base-modal name="project-mediacontact-partner-edit"
              :width="1400"
              toptitle="Medienpartner Kooperation bearbeiten"
              :title="projectMediacontactPartner.mediacontactPartner ? projectMediacontactPartner.mediacontactPartner.name : 'Medienpartner'"
              v-on="$listeners"
              v-bind="$attrs">
    <project-mediacontact-partner-form :projectMediacontactPartner="projectMediacontactPartner" type="edit"/>
  </base-modal>

</template>

<script>
import { mapGetters } from 'vuex'
import ProjectMediacontactPartnerForm from '@/components/project/projectPublicity/projectMediacontactPartner/ProjectMediacontactPartnerForm'

export default {
  name: 'ProjectMediacontactPartnerEdit',
  components: { ProjectMediacontactPartnerForm },
  props: {
    projectMediacontactPartner: Object
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    ...mapGetters({
      projectById: 'projects/byId',
      projectMediacontactPartnerById: 'projectMediacontactPartners/byId'
    })
  }
}
</script>
