<template>

    <BaseInfoBar>
        <BaseInfoBarItem icon="clock" :toptitle="dayOfWeek" :title="time"/>
        <BaseInfoBarItem :icon="weather.name ? weatherIcon : 'compass'"
                         :toptitle="weather.name ? weather.name : 'Wetter'"
                         :title="weather.name ? `${Math.floor(weather.main.temp)}°C ${weather.weather[0].description}` : 'Temperatur'"
                         :subtitle="weather.name ? `Wind ${weather.wind.speed} km/h` : 'Wind'"/>
        <BaseInfoBarItem icon="shapes" toptitle="Apps">
            <template #links>
                <span class="chip">
                <a href="https://konnektor.app" target="_blank">
                    Konnektor
                </a>
                </span>
                <span class="chip">
                <a href="https://outlook.com/vollkontakt.com" target="_blank">
                    Outlook
                </a>
                </span>
                <span class="chip">
                    <a href="https://wiki.vollkontakt.com" target="_blank">
                        Wiki
                    </a>
                </span>
            </template>
        </BaseInfoBarItem>
        <BaseInfoBarItem icon="moon" title="Dunkles Design">
            <template #action>
                <multiselect v-model="darkmodeModel"
                             :custom-label="label"
                             :options="[1, 0]"
                             :showLabels="false"
                             placeholder="Automatisch"/>
            </template>
        </BaseInfoBarItem>
    </BaseInfoBar>

</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
import BaseInfoBar from '../../base/BaseInfoBar'
import BaseInfoBarItem from '../../base/BaseInfoBarItem'

export default {
  name: 'DashboardInfo',
  components: { BaseInfoBarItem, BaseInfoBar },
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.weather.name || await this.loadWeather()
      this.loading = false
    },
    label (option) {
      switch (option) {
        case 1:
          return 'Ein'
        case 0:
          return 'Aus'
      }
    },
    ...mapMutations({
      darkmodeAppSettingSystem: 'app/darkmodeAppSettingSystem',
      darkmodeAppSettingEnabled: 'app/darkmodeAppSettingEnabled',
      darkmodeAppSettingDisabled: 'app/darkmodeAppSettingDisabled'
    }),
    ...mapActions({
      loadWeather: 'app/loadWeather',
      toggleDarkmode: 'app/toggleDarkmode'
    })
  },
  computed: {
    userIcon () {
      switch (this.authUser.roles[0]) {
        case 'ROLE_ADMIN':
          return 'user-shield'
        case 'ROLE_CHIEF':
          return 'user-cog'
        case 'ROLE_MANAGER':
          return 'user-check'
        case 'ROLE_USER':
          return 'user-alt'
        case 'ROLE_TRAINEE':
          return 'user-graduate'
        default:
          return ''
      }
    },
    dayOfWeek () {
      return moment().format('dddd, DD. MMM')
    },
    time () {
      return moment().format('HH:mm') + ' Uhr'
    },
    darkmodeModel: {
      get () {
        return this.darkmodeAppSetting
      },
      set (option) {
        switch (option) {
          case null:
            this.darkmodeAppSettingSystem()
            break
          case 1:
            this.darkmodeAppSettingEnabled()
            break
          case 0:
            this.darkmodeAppSettingDisabled()
            break
        }
      }
    },
    // darkModeInfo () {
    //   const sunset = moment.unix(this.weather.sys.sunset)
    //   const sunrise = moment.unix(this.weather.sys.sunrise).add(1, 'days')
    //   const beforeSunset = moment().isBefore(sunset)
    //   const afterSunset = moment().isAfter(sunset)
    //   // const beforeSunrise = moment().isBefore(sunrise);
    //   const afterSunrise = moment().isAfter(sunrise)
    //
    //   // auto mode on
    //   if (this.darkmodeAppSetting === null) {
    //     if (beforeSunset && !this.darkmodeStatus) {
    //       return 'Wird in ' + sunset.fromNow(true) + ' aktiviert'
    //     }
    //     if (afterSunset && !this.darkmodeStatus) {
    //       return 'Es ist bereits dunkel, jetzt ausprobieren!'
    //     }
    //
    //     if (afterSunrise && this.darkmodeStatus) {
    //       return 'Es ist bereits hell, jetzt deaktivieren!'
    //     }
    //
    //     if (this.darkmodeStatus) {
    //       return 'Wird deaktivert, wenn es hell wird'
    //       // return 'In ' + sunrise.fromNow(true) + ' wird es hell.'
    //     }
    //   }
    //   return ''
    // },
    weatherIcon () {
      const weatherIconInt = this.weather.weather[0].icon.substr(0, 2)
      switch (weatherIconInt) {
        case '01':
          return 'sun'
        case '02':
          return 'cloud-sun'
        case '03':
          return 'cloud'
        case '04':
          return 'cloud-meatball'
        case '09':
          return 'cloud-rain'
        case '10':
          return 'cloud-sun-rain'
        case '11':
          return 'bolt'
        case '13':
          return 'snowflake'
        case '50':
          return 'water'
        default:
          return ''
      }
    },
    weekend () {
      return moment().to(moment().endOf('week').subtract(2, 'days').subtract(6, 'hours'))
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      weather: 'app/weather',
      darkmodeStatus: 'app/darkmodeStatus',
      darkmodeAppSetting: 'app/darkmodeAppSetting',
      hasBetaRole: 'auth/hasBetaRole'
    })
  }
}
</script>
