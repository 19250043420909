<template>

  <base-modal name="customer-contact-create"
              title="Ansprechpartner erstellen"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <customer-contact-form :customerContact="customerContact" type="create"/>
  </base-modal>

</template>

<script>
import CustomerContactForm from './CustomerContactForm.vue'

export default {
  name: 'CustomerContactCreate',
  components: { CustomerContactForm },
  data: () => ({
    customerContact: {}
  }),
  props: {
    customer: Object
  },
  created () {
    this.customerContact = {
      customer: this.customer,
      isActive: true
    }
  }
}
</script>
