<template>

  <form v-on:submit.prevent="">

    <div class="middle">
      <div class="group">
        <div class="title">Plattform</div>
        <BaseInputFloatinglabel v-model="form.publisher"
                                icon="bullhorn"
                                placeholder="Publisher"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.placement"
                                icon="desktop"
                                placeholder="Placement"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.format"
                                icon="ruler"
                                placeholder="Format"
                                type="text"/>
        <BaseInputFloatinglabel v-model="form.contact"
                                icon="user-tie"
                                placeholder="Ansprechpartner"
                                type="text"/>

        <div class="title">Budget</div>
        <BaseInputFloatinglabel v-model="form.customerNet"
                                icon="euro-sign"
                                placeholder="Kunden-Netto"
                                step="0.01"
                                type="number"/>
        <BaseInputFloatinglabel v-model="form.agencyNet"
                                icon="euro-sign"
                                placeholder="Agentur-Netto"
                                step="0.01"
                                type="number"/>
      </div>

      <div class="group">
        <div class="title">Laufzeit</div>

          <v-date-picker
            v-model="form.campaignStart"
            :popover="{ visibility: 'click' }"
            :is-dark="darkmodeStatus"
            color="blue"
            is24hr
            mode="date"
            title-position="left">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="input-wrapper-floatinglabel">
                <font-awesome-icon icon="calendar-day"/>
                <input
                  placeholder="Start"
                  type="text"
                  :value="inputValue"
                  required
                  v-on="inputEvents" />
                <label>
                  Start
                </label>
              </div>
            </template>
          </v-date-picker>

          <v-date-picker
            v-model="form.campaignEnd"
            :popover="{ visibility: 'click' }"
            :is-dark="darkmodeStatus"
            color="blue"
            is24hr
            mode="date"
            title-position="left"
            locale="de">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="input-wrapper-floatinglabel">
                <font-awesome-icon icon="calendar-day"/>
                <input
                  placeholder="Ende"
                  type="text"
                  :value="inputValue"
                  required
                  v-on="inputEvents" />
                <label>
                  Ende
                </label>
              </div>
            </template>
          </v-date-picker>

        <div class="title">Status</div>
        <multiselect v-model="form.campaignStatus"
                     :options="['In Absprache', 'Eingeplant']"
                     :show-labels="false"
                     placeholder="Kampagnen-Status">
          <template #placeholder>
            <font-awesome-icon icon="bullhorn"/>
            <span>Kampagnenstatus</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="bullhorn"/>
            <span>{{ props.option }}</span>
          </template>
        </multiselect>
        <multiselect v-model="form.invoiceStatus"
                     :options="['Offen', 'Erhalten', 'Bezahlt']"
                     :show-labels="false"
                     placeholder="Rechnung">
          <template #placeholder>
            <font-awesome-icon icon="file-invoice-dollar"/>
            <span>Rechnungsstatus</span>
          </template>
          <template #singleLabel="props">
            <font-awesome-icon icon="file-invoice-dollar"/>
            <span>{{ props.option }}</span>
          </template>
        </multiselect>

        <BaseCheckbox v-model="form.adCreation"
                      placeholder="Werbeanzeigen erstellen"
                      :id="form.id"
                      field="adCreation"/>
      </div>

    </div>

    <div class="action">
      <BaseButton v-if="type === 'create'"
                   :loading="creating"
                   :disabled="checkForm || creating"
                   icon="plus"
                   label="Erstellen"
                   :submit="true"
                   @click="create"/>
      <BaseButton v-if="isManager && type === 'edit'"
                   :loading="removing"
                   :disabled="removing"
                   icon="trash"
                   label="Löschen"
                   :remove="true"
                   @click="remove"/>
      <BaseButton v-if="type === 'edit'"
                   :loading="updating"
                   :disabled="checkForm || updating"
                   icon="save"
                   label="Speichern"
                   :submit="true"
                   @click="update"/>
    </div>

  </form>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { cloneDeep } from 'lodash'

export default {
  name: 'ProjectAdCampaignForm',
  props: {
    projectAdCampaign: Object,
    type: String
  },
  data () {
    return {
      form: {},
      creating: false,
      updating: false,
      removing: false
    }
  },
  created () {
    this.form = cloneDeep(this.projectAdCampaign)
  },
  methods: {
    async create () {
      try {
        this.creating = true
        this.form.updatedBy = 'users/' + this.authUser.id
        this.form.updatedAt = moment().format()
        const response = await this.createProjectAdCampaign({ data: this.form })
        this.$vfm.hide('project-ad-campaign-create')
        this.$toasted.success('Kampagne ' + response.data.publisher + ' erstellt', { icon: 'fas fa-bullhorn' })
        this.creating = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.creating = false
      }
    },
    async update () {
      try {
        this.updating = true
        this.form.updatedBy = 'users/' + this.authUser.id
        this.form.updatedAt = moment().format()
        const response = await this.updateProjectAdCampaign({
          id: this.form.id,
          data: this.form
        })
        this.$vfm.hide('project-ad-campaign-edit')
        this.$toasted.success('Kampagne ' + response.data.publisher + ' gespeichert', { icon: 'fas fa-bullhorn' })
        this.updating = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.updating = false
      }
    },
    async remove () {
      try {
        this.removing = true
        await this.deleteProjectAdCampaign({ id: this.form.id })
        this.$toasted.success('Kampagne gelöscht', { icon: 'fas fa-bullhorn' })
        this.$vfm.hide('project-ad-campaign-edit')
        this.removing = false
        await this.getProject({ id: this.$route.params.projectId })
      } catch (error) {
        this.removing = false
      }
    },
    ...mapActions({
      createProjectAdCampaign: 'projectAdCampaigns/create',
      updateProjectAdCampaign: 'projectAdCampaigns/replace',
      deleteProjectAdCampaign: 'projectAdCampaigns/destroy',
      getProject: 'projects/fetchSingle'
    })
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    isManager () {
      return (this.project && this.project.managers && this.project.managers.some(user => user.id === this.authUser.id)) || this.hasManagerRole
    },
    checkForm () {
      return false
      // return !(this.form.publisher && this.form.campaignStatus);
    },
    ...mapGetters({
      projectById: 'projects/byId',
      authUser: 'auth/authUser',
      hasManagerRole: 'auth/hasManagerRole',
      darkmodeStatus: 'app/darkmodeStatus'
    })
  }
}
</script>
