<template>

  <base-modal name="project-influencer-edit"
              toptitle="Influencer Kooperation bearbeiten"
              :title="projectInfluencer.influencer.name"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
    <project-influencer-form :projectInfluencer="projectInfluencer" type="edit"/>
  </base-modal>

</template>

<script>
import { mapGetters } from 'vuex'
import ProjectInfluencerForm from './ProjectInfluencerForm.vue'

export default {
  name: 'ProjectInfluencerEdit',
  components: { ProjectInfluencerForm },
  props: {
    projectInfluencer: Object
  },
  computed: {
    ...mapGetters({
      projectInfluencerById: 'projectInfluencers/byId'
    })
  }
}
</script>
