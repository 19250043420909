<template>

    <main>

        <BaseTopBar icon="cog" title="Einstellungen"></BaseTopBar>

        <BaseList>

            <div class="row">
                <div class="content">
                    <div class="icon">
                        <font-awesome-icon :icon="userIcon" size="lg"/>
                    </div>

                    <div class="middle">
                        <div class="col">
                            <div class="title">Profil</div>
                        </div>
                    </div>
                </div>
                <div class="action">
                    <button class="button button-flat" @click="$vfm.show({component: 'UserEdit', bind: { user: authUser }})">
                        Bearbeiten
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="content">
                    <div class="icon">
                        <font-awesome-icon icon="moon" size="lg"/>
                    </div>
                    <div class="middle">
                        <div class="col">
                            <div class="title">Dunkles Design</div>
                        </div>
                    </div>
                </div>
                <div class="action">
                    <multiselect v-model="darkmodeModel"
                                 :custom-label="label"
                                 :options="[1, 0]"
                                 :showLabels="false"
                                 placeholder="Automatisch"/>
                </div>
            </div>

        </BaseList>

    </main>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Settings',
  methods: {
    label (option) {
      switch (option) {
        case 1:
          return 'Ein'
        case 0:
          return 'Aus'
      }
    },
    ...mapMutations({
      darkmodeAppSettingSystem: 'app/darkmodeAppSettingSystem',
      darkmodeAppSettingEnabled: 'app/darkmodeAppSettingEnabled',
      darkmodeAppSettingDisabled: 'app/darkmodeAppSettingDisabled'
    }),
    ...mapActions({
      toggleDarkmode: 'app/toggleDarkmode'
    })
  },
  computed: {
    userIcon () {
      switch (this.authUser.roles[0]) {
        case 'ROLE_ADMIN':
          return 'user-shield'
        case 'ROLE_CHIEF':
          return 'user-cog'
        case 'ROLE_MANAGER':
          return 'user-check'
        case 'ROLE_USER':
          return 'user-alt'
        case 'ROLE_TRAINEE':
          return 'user-graduate'
        default:
          return ''
      }
    },
    darkmodeModel: {
      get () {
        return this.darkmodeAppSetting
      },
      set (option) {
        switch (option) {
          case null:
            this.darkmodeAppSettingSystem()
            break
          case 1:
            this.darkmodeAppSettingEnabled()
            break
          case 0:
            this.darkmodeAppSettingDisabled()
            break
        }
      }
    },
    ...mapGetters({
      authUser: 'auth/authUser',
      darkmodeStatus: 'app/darkmodeStatus',
      darkmodeAppSetting: 'app/darkmodeAppSetting'
    })
  }
}
</script>
