<template>

  <base-modal toptitle="Deal bearbeiten"
              :title="costCenterLabel"
              name="project-accounting-deal-edit"
              :width="400"
              v-on="$listeners"
              v-bind="$attrs">
      <project-accounting-deal-form :projectAccountingDeal="projectAccountingDeal" type="edit"/>
  </base-modal>

</template>

<script>
import ProjectAccountingDealForm from './ProjectAccountingDealForm.vue'

export default {
  name: 'ProjectAccountingDealEdit',
  components: { ProjectAccountingDealForm },
  props: {
    projectAccountingDeal: Object
  },
  computed: {
    costCenterLabel () {
      switch (this.projectAccountingDeal.costCenter) {
        case 1:
          return 'PR'
        case 2:
          return 'Social Media'
        case 3:
          return 'Marketing'
        default:
          return ''
      }
    }
  }
}
</script>
