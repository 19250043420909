<template>

  <base-modal name="business-contact-edit"
              toptitle="B2B Kontakt bearbeiten"
              :title="`${businessContact.firstName} ${businessContact.lastName}`"
              :width="800"
              v-on="$listeners"
              v-bind="$attrs">
    <business-contact-form :businessContact="businessContact" type="edit"></business-contact-form>
  </base-modal>

</template>

<script>
import BusinessContactForm from './BusinessContactForm.vue'

export default {
  name: 'BusinessContactEdit',
  components: { BusinessContactForm },
  props: {
    businessContact: Object
  }
}
</script>
