
<template>

  <base-modal
    name="project-mediacontact-partner-feature-edit"
    :width="800"
    toptitle="Beitrag bei Medienpartner bearbeiten"
    :title="projectMediacontactPartnerFeature.type"
    :subtitle="lastUpdated"
    v-on="$listeners"
    v-bind="$attrs">
    <project-mediacontact-partner-feature-form
      :projectMediacontactPartnerFeature="projectMediacontactPartnerFeature"
      type="edit"/>
  </base-modal>

</template>

<script>
import { mapGetters } from 'vuex'
import ProjectMediacontactPartnerFeatureForm from '@/components/project/projectPublicity/projectMediacontactPartner/projectMediacontactPartnerFeature/ProjectMediacontactPartnerFeatureForm'
import moment from 'moment'

export default {
  name: 'ProjectMediacontactPartnerFeatureEdit',
  components: { ProjectMediacontactPartnerFeatureForm },
  props: {
    projectMediacontactPartner: Object,
    projectMediacontactPartnerFeature: Object
  },
  computed: {
    project () {
      return this.projectById(this.$route.params.projectId)
    },
    lastUpdated () {
      return `Letzte Änderung von ${this.projectMediacontactPartnerFeature.updatedBy.firstName} ${moment(this.projectMediacontactPartnerFeature.updatedAt).fromNow()}`
    },
    ...mapGetters({
      projectById: 'projects/byId',
      projectMediacontactPartnerById: 'projectMediacontactPartners/byId'
    })
  }
}
</script>
